import type { Route } from '@angular/router';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

import { userPreferenceQueryParamRedirect } from '@stlc/angular/guards';

import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewsBattingComponent } from './components/reviews-batting/reviews-batting.component';
import { ReviewsBattingAtBatsComponent } from './components/reviews-batting-at-bats/reviews-batting-at-bats.component';
import { ReviewsBattingPitchesComponent } from './components/reviews-batting-pitches/reviews-batting-pitches.component';
import { ReviewsBattingSummaryComponent } from './components/reviews-batting-summary/reviews-batting-summary.component';
import { reviewsGameYearsResolver } from './resolves/review-game-years.resolver';
import { reviewsBattingLoadDataResolver } from './resolves/reviews-batting-load-data.resolver';

export const battingRoutes: Route[] = [
    {
        path: '',
        component: ReviewsComponent,
        data: {
            i18nextNamespaces: [
                'chart',
                'common',
                'filters',
                'glossary',
                'game',
                'pitchType',
                'player',
                'playerCharts',
                'reviews',
                'stat',
                'video',
            ],
            reviewType: 'batting',
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
            gameYears: reviewsGameYearsResolver,
        },
        children: [
            {
                path: '',
                component: ReviewsBattingComponent,
                resolve: {
                    data: reviewsBattingLoadDataResolver,
                },
                children: [
                    {
                        path: 'at-bats',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsBattingAtBatsComponent,
                    },
                    {
                        path: 'summary',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsBattingSummaryComponent,
                    },
                    {
                        path: 'pitches',
                        data: {
                            reuseRoute: true,
                            userPreferenceQueryParamKey: 'player-charts:games:batting',
                        },
                        component: ReviewsBattingPitchesComponent,
                        canActivate: [userPreferenceQueryParamRedirect],
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'summary',
                    },
                ],
            },
        ],
    },
];
