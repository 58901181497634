<div class="flex flex-col max-w-screen-md mx-auto lg:max-w-screen-lg">
    <ng-container>
        <div class="flex justify-center">
            <button
                mat-button
                (click)="showSwingDecisionPercentiles = !showSwingDecisionPercentiles; updatePitchLocationSpecSignals()"
            >
                <mat-icon class="text-lg w-5 h-6">{{
                    showSwingDecisionPercentiles ? 'check_box' : 'check_box_outline_blank'
                }}</mat-icon>
                {{ 'chart:toggleSwingPercentiles' | i18next }}
            </button>
        </div>
        <div
            class="flex flex-col items-center w-full md:items-stretch md:justify-center md:px-0 md:flex-row md:flex-wrap"
        >
            <ng-container *ngFor="let atBat of reviewsBattingAtBatsService.atBats$ | async">
                <div class="p-2 w-4/5 sm:w-3/5 md:w-1/2 lg:w-1/3">
                    <mat-card class="h-full">
                        <mat-card-content class="flex flex-col items-center">
                            <!-- Going to need to look into how to translate these -->
                            <span *ngFor="let title of atBat.titles">{{ title }}</span>
                            <ui-vega-chart
                                [spec]="strikeZoneSpec"
                                [data]="{ data: atBat.pitches, ellipsesData: atBat.ellipses }"
                                [signals]="strikeZoneSignals"
                                [signalListeners]="strikeZoneSignalListeners"
                                [tooltipTemplate]="tooltipTemplate"
                            ></ui-vega-chart>
                            <button
                                mat-stroked-button
                                color="primary"
                                class="mb-8"
                                *ngIf="atBat.isVideoAvailable"
                                (click)="openVideoDialogForAtBat(atBat)"
                            >
                                {{ 'common:watchVideo' | i18next }}
                            </button>
                            <table
                                class="w-full max-w-xs"
                                [ngClass]="{ 'border-b border-gray-50 mb-2 pb-2': !!atBat.battedBallResult }"
                            >
                                <tbody>
                                    <tr *ngFor="let pitch of atBat.pitches">
                                        <td class="text-center">{{ pitch.atbatPitchNumber }}</td>
                                        <td class="text-center">{{ pitch.preBalls }}-{{ pitch.preStrikes }}</td>
                                        <td class="text-center">{{ pitch.pitchType | stlcPitchType: 'abbrev' }}</td>
                                        <td class="text-center">
                                            {{
                                                pitch.releaseSpeed | stlcNumberToFixed: { digits: 0, defaultText: '-' }
                                            }}
                                        </td>
                                        <td class="text-left">
                                            {{ { pitchResult: pitch.pitchResult } | stlcI18nGameEvent }}
                                        </td>
                                        <td
                                            *ngIf="pitch.takes === 1"
                                            [ngClass]="{ good: pitch.goodTakes === 1, bad: pitch.goodTakes === 0 }"
                                        >
                                            {{ 'common:description.take' | i18next }}
                                        </td>
                                        <ng-container *ngIf="pitch.swings === 1">
                                            <td
                                                [ngClass]="{
                                                    good: pitch.goodSwings === 1,
                                                    bad: pitch.goodSwings === 0
                                                }"
                                                [class.tooltip]="!!pitch.tooltip"
                                                matTooltipClass="stlc-table-cell-tooltip"
                                                [matTooltip]="pitch.tooltip"
                                                [matTooltipDisabled]="!pitch.tooltip"
                                                matTooltipPosition="above"
                                            >
                                                {{ 'common:description.swing' | i18next }}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            <span class="stlc-text-muted text-center" [innerHTML]="atBat.battedBallResult"></span>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<div *ngIf="reviewsBattingAtBatsService.isVideoAvailable$ | async" class="stlc-fab-bottom my-4 sticky">
    <button mat-fab extended color="primary" (click)="openVideoDialog()" type="button">
        <mat-icon>play_arrow</mat-icon>
        {{ 'common:watchVideo' | i18next }}
    </button>
</div>

<ng-template #tooltipTemplate let-item="item">
    <player-charts-ui-batting-tooltip [pitch]="item"></player-charts-ui-batting-tooltip>
</ng-template>
