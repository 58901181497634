import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { combineLatest } from 'rxjs';

import { PlayerStatsHandedness } from '@stlc/game/reviews/data-access';
import { chain, findIndex } from '@stlc/lodash';
import { getStatColumn } from '@stlc/lookup/stat';
import { getPitchTypeOrder } from '@stlc/lookup/statcast';
import { StlcStat, UiTableColumn, UiTableDisplayedColumn } from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

import { ReviewsCatchingService } from '../../services/reviews-catching.service';
import { ReviewsCatchingFramingSummaryMetricsComponent } from '../reviews-catching-framing-summary-metrics/reviews-catching-framing-summary-metrics.component';

@Component({
    selector: 'stlc-reviews-catching-summary',
    templateUrl: './reviews-catching-summary.component.html',
    styleUrls: ['./reviews-catching-summary.component.scss'],
    standalone: true,
    imports: [CommonModule, MatCardModule, UiTableModule, ReviewsCatchingFramingSummaryMetricsComponent],
})
export class ReviewsCatchingSummaryComponent implements OnInit {
    pitcherTableColumns: UiTableColumn[] = [];
    pitcherTableDisplayedColumns: UiTableDisplayedColumn[] = [];
    pitcherData: {
        dataSource: UiTableDataSource<{ [key: string]: any }>;
        name: string;
    }[] = [];
    pitcherTableStickyColumns = ['pitchAbbrev'];

    framingTableColumns: UiTableColumn[] = [];
    framingTableDisplayedColumns: UiTableDisplayedColumn[] = [];
    framingDataSource = new UiTableDataSource<
        {
            pitchType: string;
            numberOfPitches: number;
            strikesGained: number;
            strikesLost: number;
            netStrikesGained: number;
        }[]
    >();
    framingTableStickyColumns = ['pitchAbbrev'];

    constructor(public reviewsCatchingService: ReviewsCatchingService) {}

    ngOnInit() {
        combineLatest([this.reviewsCatchingService.pitchTypeSummary$, this.reviewsCatchingService.pitchers$]).subscribe(
            ([summary, pitchers]) => {
                this.pitcherTableColumns = [
                    {
                        ...getStatColumn(StlcStat.PitchName),
                        format: {
                            type: 'i18nPitchType',
                            source: 'legacy',
                            defaultText: '',
                        },
                    },
                    { ...getStatColumn(StlcStat.NumberOfPitches), header: '#' },
                    getStatColumn(StlcStat.Usage),
                    { ...getStatColumn(StlcStat.NumberOfPitches), header: '#', id: 'numberOfPitchesRight' },
                    { ...getStatColumn(StlcStat.Usage), id: 'usageRight' },
                    { ...getStatColumn(StlcStat.NumberOfPitches), header: '#', id: 'numberOfPitchesLeft' },
                    { ...getStatColumn(StlcStat.Usage), id: 'usageLeft' },
                ];

                this.pitcherTableDisplayedColumns = [
                    StlcStat.PitchName,
                    StlcStat.NumberOfPitches,
                    StlcStat.Usage,
                    {
                        id: 'usageRightColumns',
                        header: {
                            label: 'vs. RHB',
                            labelKey: 'filters:batterHandednessRight_label',
                        },
                        class: 'text-center',
                        displayedColumns: ['numberOfPitchesRight', 'usageRight'],
                    },
                    {
                        id: 'usageLeftColumns',
                        header: {
                            label: 'vs. LHB',
                            labelKey: 'filters:batterHandednessLeft_label',
                        },
                        class: 'text-center',
                        displayedColumns: ['numberOfPitchesLeft', 'usageLeft'],
                    },
                ];

                this.pitcherData = chain(summary)
                    .uniqBy((datum) => datum.pitcher.id)
                    .map((d) => {
                        const dataSource = new UiTableDataSource<{ [key: string]: any }>();
                        let totalPitchesLeft = 0;
                        let totalPitchesRight = 0;
                        dataSource.data = chain(summary)
                            .filter(({ pitcher }) => pitcher.id === d.pitcher.id)
                            .transform((result, datum) => {
                                let index = findIndex(result, (d) => d.pitchType === datum.pitchType);
                                if (index === -1) {
                                    result.push({ pitchType: datum.pitchType });
                                    index = result.length - 1;
                                }
                                result[index][`numberOfPitches${datum.batterBats}`] = datum.numberOfPitches;
                                result[index][`usage${datum.batterBats}`] = datum.usage;

                                if (datum.batterBats === PlayerStatsHandedness.Right) {
                                    totalPitchesRight += datum.numberOfPitches;
                                } else if (datum.batterBats === PlayerStatsHandedness.Left) {
                                    totalPitchesLeft += datum.numberOfPitches;
                                }
                            }, [])
                            .map((datum) => ({
                                ...datum,
                                numberOfPitches: (datum.numberOfPitchesRight ?? 0) + (datum.numberOfPitchesLeft ?? 0),
                                usage:
                                    (totalPitchesRight ?? 0 + totalPitchesLeft ?? 0) > 0
                                        ? (100 *
                                              ((datum.numberOfPitchesRight ?? 0) + (datum.numberOfPitchesLeft ?? 0))) /
                                          ((totalPitchesRight ?? 0) + (totalPitchesLeft ?? 0))
                                        : undefined,
                                usageRight:
                                    totalPitchesRight > 0
                                        ? (100 * datum.numberOfPitchesRight) / totalPitchesRight
                                        : undefined,
                                usageLeft:
                                    totalPitchesLeft > 0
                                        ? (100 * datum.numberOfPitchesLeft) / totalPitchesLeft
                                        : undefined,
                            }))
                            .valueOf();

                        return {
                            dataSource,
                            name: `${d.pitcher.firstName} ${d.pitcher.lastName}${
                                d.pitcher.throws === 'L' ? ' (L)' : ''
                            }`,
                            id: d.pitcher.id,
                        };
                    })
                    .orderBy((datum) => findIndex(pitchers, ({ id }) => datum.id === id))
                    .valueOf();
            }
        );

        this.reviewsCatchingService.pitches$.subscribe((pitches) => {
            this.framingTableDisplayedColumns = [
                StlcStat.PitchName,
                StlcStat.NumberOfPitches,
                StlcStat.StrikesAdded,
                StlcStat.StrikesLost,
                StlcStat.StrikesGainedNet,
            ];

            this.framingDataSource.data = chain(pitches)
                .transform((result, datum) => {
                    const index = findIndex(result, (r) => r.pitchType === datum.pitchType);
                    if (index === -1) {
                        result.push({
                            pitchType: datum.pitchType,
                            strikesAdded: datum.pitchResult === 'SC' ? datum.strikesAdded : 0,
                            strikesLost: datum.pitchResult === 'B' ? Math.abs(datum.strikesAdded) : 0,
                            numberOfPitches: 1,
                        });
                    } else {
                        result[index].strikesAdded += datum.pitchResult === 'SC' ? datum.strikesAdded : 0;
                        result[index].strikesLost += datum.pitchResult === 'B' ? Math.abs(datum.strikesAdded) : 0;
                        result[index].numberOfPitches += 1;
                    }
                }, [])
                .map((datum) => ({ ...datum, strikesGainedNet: datum.strikesAdded - datum.strikesLost }))
                .orderBy(({ pitchType }) => getPitchTypeOrder(pitchType))
                .valueOf();

            this.framingTableColumns = [
                {
                    ...getStatColumn(StlcStat.PitchName),
                    footer: {
                        label: 'Totals',
                        class: 'text-left font-bold',
                    },
                },
                {
                    ...getStatColumn(StlcStat.NumberOfPitches),
                    header: '#',
                    footer: {
                        label: `${pitches.length}`,
                        class: 'text-right font-bold',
                    },
                },
                {
                    ...getStatColumn(StlcStat.StrikesAdded),
                    class: 'whitespace-nowrap text-right',
                    footer: {
                        label: `${chain(this.framingDataSource.data).map('strikesAdded').sum().valueOf()}`,
                        class: 'text-right font-bold',
                    },
                },
                {
                    ...getStatColumn(StlcStat.StrikesLost),
                    class: 'whitespace-nowrap text-right',
                    footer: {
                        label: `${chain(this.framingDataSource.data).map('strikesLost').sum().valueOf()}`,
                        class: 'text-right font-bold',
                    },
                },
                {
                    ...getStatColumn(StlcStat.StrikesGainedNet),
                    class: 'whitespace-nowrap text-right',
                    footer: {
                        label: `${chain(this.framingDataSource.data).map('strikesGainedNet').sum().valueOf()}`,
                        class: 'text-right font-bold',
                    },
                },
            ];
        });
    }
}
