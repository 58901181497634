import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { isArray, template } from '@stlc/lodash';
import { getStatColumn, getTableDefinition } from '@stlc/lookup/stat';
import { PitchingAggregate } from '@stlc/player-stats/shared';
import {
    StlcStat,
    UiTableColumn,
    UiTableDefinitionColumn,
    UiTableDefinitionColumnType,
    UiTableDisplayedColumn,
} from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

@Component({
    selector: 'stlc-reviews-pitching-game-stats',
    templateUrl: './reviews-pitching-game-stats.component.html',
    styleUrls: ['./reviews-pitching-game-stats.component.scss'],
    standalone: true,
    imports: [CommonModule, UiTableModule],
})
export class ReviewsPitchingGameStatsComponent implements OnInit {
    dataSource: UiTableDataSource = new UiTableDataSource<PitchingAggregate>([]);
    columns: UiTableColumn[] = [];
    displayedColumns: UiTableDisplayedColumn[] = [];

    @Input()
    set summaryStats(value: PitchingAggregate[] | PitchingAggregate) {
        this.dataSource.data = isArray(value) ? value : [value];
    }

    @Input()
    tableClass?: string;

    ngOnInit(): void {
        const { columns, displayedColumns } = getTableDefinition(tableDefinition);
        this.columns = columns;
        this.displayedColumns = displayedColumns;
    }
}

const tableDefinition: UiTableDefinitionColumn[] = [
    StlcStat.BattersFaced,
    StlcStat.InningsPitched,
    StlcStat.PitchesPerInning,
    StlcStat.NumberOfPitches,
    UiTableDefinitionColumnType.Divider,
    StlcStat.Bavg,
    StlcStat.Obp,
    StlcStat.Slg,
    StlcStat.Ops,
    UiTableDefinitionColumnType.Divider,
    {
        ...getStatColumn(StlcStat.BaseOnBallRate),
        tooltip: {
            label: '{{ count }} walks',
            labelKey: 'stat:baseOnBallsTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.BaseOnBalls] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.BaseOnBalls} %> BB`),
        },
    },
    //template(`<%= datum.${StlcStat.BaseOnBalls} %> BB`) },
    {
        ...getStatColumn(StlcStat.StrikeOutRate),
        tooltip: {
            label: '{{ count }} strikeouts',
            labelKey: 'stat:strikeOutsTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.StrikeOuts] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.StrikeOuts} %> SO`),
        },
    },
    {
        ...getStatColumn(StlcStat.GroundBallRate),
        tooltip: {
            label: '{{ count }} ground balls',
            labelKey: 'stat:groundBallsTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.GroundBalls] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.GroundBalls} %> GB`),
        },
    },
    UiTableDefinitionColumnType.Divider,
    {
        ...getStatColumn(StlcStat.StrikeRate),
        tooltip: {
            label: '{{ count }} strikes',
            labelKey: 'stat:strikesTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.Strikes] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.Strikes} %> strikes`),
        },
    },
    {
        ...getStatColumn(StlcStat.FirstPitchStrikeRate),
        tooltip: {
            label: '{{ count }} 0-0 strikes',
            labelKey: 'stat:firstPitchStrikesTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.FirstPitchStrikes] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.FirstPitchStrikes} %> 0-0 strikes`),
        },
    },
    {
        ...getStatColumn(StlcStat.SwingAndMissRate),
        tooltip: {
            label: '{{ count }} swing and misses',
            labelKey: 'stat:swingAndMissesTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.SwingAndMisses] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.SwingAndMisses} %> swing and misses`),
        },
    },
    getStatColumn(StlcStat.PutAwayLimitDamageRatio),
    UiTableDefinitionColumnType.Divider,
    {
        ...getStatColumn(StlcStat.InZoneRate),
        tooltip: {
            label: '{{ count }} in-zone pitches',
            labelKey: 'stat:inZonePitchesTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.InZonePitches] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.InZonePitches} %> in-zone pitches`),
        },
    },
    {
        ...getStatColumn(StlcStat.OutOfZoneSwingRate),
        tooltip: {
            label: '{{ count }} out-of-zone swings',
            labelKey: 'stat:outOfZoneSwingsTooltip_count',
            data: (datum) => ({ count: datum[StlcStat.OutOfZoneSwings] ?? 0 }),
            fallback: template(`<%= datum.${StlcStat.OutOfZoneSwings} %> out-of-zone swings`),
        },
    },
];
