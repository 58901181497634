import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { getStatColumn } from '@stlc/lookup/stat';
import type { GamePitchingReportSummary } from '@stlc/reviews/shared';
import { StlcStat, UiTableColumn, UiTableDisplayedColumn } from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

@Component({
    selector: 'stlc-reviews-pitching-report-summary',
    templateUrl: './reviews-pitching-report-summary.component.html',
    styleUrls: ['./reviews-pitching-report-summary.component.scss'],
    standalone: true,
    imports: [UiTableModule],
})
export class ReviewsPitchingReportSummaryComponent implements OnChanges, OnInit {
    @Input()
    boxscore: GamePitchingReportSummary;

    columns: UiTableColumn[];
    displayedColumns: UiTableDisplayedColumn[];
    dataSource = new UiTableDataSource();

    ngOnInit() {
        this.columns = [
            getStatColumn(StlcStat.FirstPitchStrikes, true),
            getStatColumn(StlcStat.FirstPitchCalledStrikes, true),
            getStatColumn(StlcStat.FirstPitchFoulBalls, true),
            getStatColumn(StlcStat.FirstPitchSwingAndMisses, true),
            getStatColumn(StlcStat.FirstPitchOuts, true),
            getStatColumn(StlcStat.FirstPitchHits, true),
            getStatColumn(StlcStat.PutAwayHits, true),
            getStatColumn(StlcStat.PutAwayPlateAppearances, true),
            getStatColumn(StlcStat.PutAwayPitches, true),
            getStatColumn(StlcStat.PutAwayStrikeouts, true),
            getStatColumn(StlcStat.OneOnePitches, true),
            getStatColumn(StlcStat.OneOneStrikes, true),
            getStatColumn(StlcStat.OneOneBallsInPlay, true),
            getStatColumn(StlcStat.LeadoffOutsPerPlateAppearanceRatio, true),
            getStatColumn(StlcStat.LeadoffBaseOnBallsPerBaseOnBallsScoredRatio, true),
            {
                ...getStatColumn(StlcStat.ThreeBallPlateAppearances, true),
                header: 'PA',
            },
            getStatColumn(StlcStat.BaseOnBalls, true),
            getStatColumn(StlcStat.RunGameStolenBasesPerAttemptRatio, true),
            getStatColumn(StlcStat.PickoffAttempts, true),
        ];

        this.displayedColumns = [
            {
                id: 'firstPitchGroup',
                header: '0-0 Count',
                class: 'text-center',
                borderless: true,
                displayedColumns: [
                    StlcStat.FirstPitchStrikes,
                    StlcStat.FirstPitchCalledStrikes,
                    StlcStat.FirstPitchFoulBalls,
                    StlcStat.FirstPitchSwingAndMisses,
                    StlcStat.FirstPitchOuts,
                    StlcStat.FirstPitchHits,
                ],
            },
            {
                id: 'oneOneGroup',
                header: '1-1 Count',
                class: 'text-center',
                borderless: true,
                displayedColumns: [StlcStat.OneOnePitches, StlcStat.OneOneStrikes, StlcStat.OneOneBallsInPlay],
            },
            {
                id: 'putAwayGroup',
                header: 'Put Away Count',
                class: 'text-center',
                borderless: true,
                displayedColumns: [
                    StlcStat.PutAwayPlateAppearances,
                    StlcStat.PutAwayPitches,
                    StlcStat.PutAwayStrikeouts,
                    StlcStat.PutAwayHits,
                ],
            },
            {
                id: 'threeBallsGroup',
                header: '3-Ball Count',
                class: 'text-center',
                borderless: true,
                displayedColumns: [StlcStat.ThreeBallPlateAppearances, StlcStat.BaseOnBalls],
            },
            {
                id: 'leadoffGroup',
                header: 'Leadoff',
                class: 'text-center',
                borderless: true,
                displayedColumns: [
                    StlcStat.LeadoffOutsPerPlateAppearanceRatio,
                    StlcStat.LeadoffBaseOnBallsPerBaseOnBallsScoredRatio,
                ],
            },
            {
                id: 'runningGameGroup',
                header: 'Holding Runners',
                class: 'text-center',
                borderless: true,
                displayedColumns: [StlcStat.RunGameStolenBasesPerAttemptRatio, StlcStat.PickoffAttempts],
            },
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['boxscore'] && this.boxscore) {
            this.dataSource.data = this.boxscore ? [this.boxscore] : [];
        }
    }
}
