import { Inject, Injectable } from '@angular/core';
import { I18NextService, ITranslationService } from 'angular-i18next';
import dayjs from 'dayjs';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, finalize, first, map, startWith } from 'rxjs/operators';

import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { RbdReviewPitchFragment, ReviewsGetReviewTypesGQL, ReviewTypeFragment } from '@stlc/game/reviews/data-access';
import type { ReviewsPlayer } from '@stlc/game/shared';
import { StlcI18nGameEventPipe } from '@stlc/i18n/core';
import { chain, find, flatten, forEach, get, includes, isNil, map as _map, pick, size, some } from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { UiMenuItem } from '@stlc/shared';
import { UiAppService } from '@stlc/ui/services';

import { isBallInDirt, isSbAttempt, playWithCatcher } from './events';

@Injectable({ providedIn: 'root' })
export class ReviewsService {
    readonly selectedReviewType$: Observable<ReviewTypeFragment | undefined>;
    readonly selectedTab$: Observable<string>;
    readonly reviewTypeOptions$: Observable<ReviewTypeFragment[]>;
    readonly reviewTypeOptionsCount$: Observable<number>;
    readonly menu$: Observable<UiMenuItem | undefined>;

    private selectedReviewTypeSubject = new BehaviorSubject<string | undefined>(undefined);
    private selectedTabSubject = new BehaviorSubject<string>('summary');
    private reviewTypeOptionsSubject = new BehaviorSubject<ReviewTypeFragment[]>([]);

    enableBrushing$: Observable<boolean>;

    private stlcI18nGameEventPipe: StlcI18nGameEventPipe;
    private ordinalPipe: StlcNumberToOrdinalPipe;

    constructor(
        private appService: UiAppService,
        private getReviewTypesGQL: ReviewsGetReviewTypesGQL,
        @Inject(I18NextService) private i18next: ITranslationService
    ) {
        this.ordinalPipe = new StlcNumberToOrdinalPipe();
        this.stlcI18nGameEventPipe = new StlcI18nGameEventPipe(this.i18next);

        this.selectedTab$ = this.selectedTabSubject.asObservable();
        this.reviewTypeOptions$ = this.reviewTypeOptionsSubject.asObservable();
        this.reviewTypeOptionsCount$ = this.reviewTypeOptionsSubject.pipe(
            map((options) => options.length),
            startWith(0)
        );
        this.selectedReviewType$ = combineLatest([
            this.selectedReviewTypeSubject.pipe(map((value) => value)),
            this.reviewTypeOptionsSubject.pipe(map((value) => value)),
        ]).pipe(map(([type, options]) => find(options, { type })));
        this.menu$ = combineLatest([
            this.selectedReviewType$.pipe(map((value) => value)),
            this.reviewTypeOptionsSubject.pipe(map((value) => value)),
        ]).pipe(
            map(([reviewType, options]) => {
                const minDate = dayjs(reviewType?.gameDateMin).format('L');
                const maxDate = dayjs(reviewType?.gameDateMax).format('L');
                const date = `${minDate} ${dayjs(minDate).isBefore(maxDate) ? '-' : ''} ${
                    dayjs(minDate).isBefore(maxDate) ? maxDate : ''
                }`;

                return {
                    displayName: `${reviewType?.player?.lastName} - ${date} - ${this.i18next.t(
                        `common:${reviewType?.type}`
                    )}`,
                    abbrevName: `${reviewType?.player?.lastName} - ${date} - ${this.i18next.t(
                        `common:${reviewType?.type}`
                    )}`,
                    children:
                        size(options) > 1
                            ? _map(options, (datum) => ({
                                  type: datum.type,
                                  displayName: `${datum?.player?.lastName} - ${date} - ${this.i18next.t(
                                      `common:${datum?.type}`
                                  )}`,
                                  abbrevName: `${datum?.player?.lastName} - ${date} - ${this.i18next.t(
                                      `common:${datum?.type}`
                                  )}`,
                                  displayKey: `common:${datum?.type}`,
                                  routerLink: `/game-reviews/player/${datum.player.id}/games/${
                                      datum.type === 'fielding' ? '' : dayjs(date).format('YYYY-MM-DD')
                                  }/${datum.type}`,
                              }))
                            : undefined,
                };
            })
        );

        this.enableBrushing$ = this.appService.isTouchDevice$.pipe(map((value) => !value));
    }

    set selectedTab(value: string) {
        this.selectedTabSubject.next(value);
    }

    set reviewTypeOptions(value: ReviewTypeFragment[]) {
        this.reviewTypeOptionsSubject.next(value);
    }

    set selectedReviewType(value: string) {
        this.selectedReviewTypeSubject.next(value);
    }

    loadReviewTypes(options: {
        player: ReviewsPlayer;
        startDate: string;
        endDate?: string;
    }): Observable<ReviewTypeFragment[]> {
        this.appService.loading = true;
        return this.getReviewTypesGQL
            .fetch({
                playerId: options.player.id,
                startDate: options.startDate,
                endDate: options.endDate,
            })
            .pipe(
                first((response) => !isNil(response?.data)),
                map(({ data }) => {
                    this.reviewTypeOptions = data?.types;
                    return data?.types;
                }),
                catchError(() => of([])),
                finalize(() => {
                    this.appService.loading = false;
                })
            );
    }

    getFilteredIds(pitches: RbdReviewPitchFragment[], filters: { [key: string]: any | any[] }): string[] {
        const ids = chain(pitches)
            .filter((datum) => {
                let flag = true;
                forEach(filters, (values, key) => {
                    values = flatten(values);
                    switch (key) {
                        case 'pitcher':
                            flag = includes(values, get(datum, 'pitcher.id'));
                            return flag;
                        case 'batterHandedness':
                            flag = includes(values, datum.batterBats);
                            return flag;
                        case 'pitcherHandedness':
                            flag = includes(values, datum.pitcherThrows);
                            return flag;
                        case 'countType':
                            flag = !chain(values).find(pick(datum, 'preBalls', 'preStrikes')).isNil().valueOf();
                            return flag;
                        case 'other': {
                            if (includes(values, 'blast')) {
                                flag =
                                    !isNil(datum.batterBatSpeed) ||
                                    !isNil(datum.batterCommitTime) ||
                                    !isNil(datum.batterPlanarEfficiency) ||
                                    !isNil(datum.batterAttackAngle);
                            }
                            if (flag && includes(values, 'edgertronic')) {
                                flag = some(datum.videos, (video) => video.videoAngle?.startsWith('Edger'));
                            }
                            if (flag && includes(values, 'affectedByFraming')) {
                                flag = datum.affectedByFraming;
                            }
                            if (flag && includes(values, 'ballsInDirt')) {
                                flag = isBallInDirt(datum);
                            }
                            if (flag && includes(values, 'sbAttempts')) {
                                flag = isSbAttempt(datum);
                            }
                            if (flag && includes(values, 'wildPitchPassedBalls')) {
                                flag = includes(['passed_ball', 'wild_pitch'], datum.eventType);
                            }
                            if (flag && includes(values, 'defensivePlays')) {
                                flag = playWithCatcher(datum);
                            }
                            return flag;
                        }
                        default:
                            flag = includes(values, get(datum, key));
                            return flag;
                    }
                });
                return flag;
            })
            .map('id')
            .valueOf();

        if (size(ids) === size(pitches)) {
            return null;
        }

        return ids;
    }

    getVideoEvents(pitches: RbdReviewPitchFragment[]) {
        return _map(pitches, (datum) => ({
            id: datum.id,
            eventGroup: `${datum.game?.id}:${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
            title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
            subtitles: [
                datum.inning
                    ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(datum.inning)}`
                    : '',
                `${datum.preBalls}-${datum.preStrikes} ${
                    datum?.preOuts ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 }) : ''
                }`,
                getPitchTypeCode(datum.pitchType)
                    ? this.i18next.t(`pitchType:${getPitchTypeCode(datum.pitchType)}_label`)
                    : 'Unknown',
                datum?.pitchOutcome
                    ? this.stlcI18nGameEventPipe.transform({
                          isFinalPlay: datum.isFinalPlay,
                          isNextPlay: datum.isNextPlay,
                          pitchResult: datum?.pitchResult,
                          eventType: datum?.eventType,
                          battedBallType: datum?.battedBallType,
                      })
                    : '',
            ],
            pitch: {
                ...datum,
                pitchType: getPitchTypeCode(datum.pitchType),
                description: this.stlcI18nGameEventPipe.transform(datum),
            },
            description: this.stlcI18nGameEventPipe.transform(datum),
            playByPlay: datum?.playByPlayDescription || '',
            videos: datum?.videos || [],
            types: ['pitches'],
        }));
    }
}
