<div
    *ngIf="reviewsCatchingService.isUnverified$ | async"
    class="flex flex-col bg-warning-200 text-warning-700 md:mx-auto"
>
    <span class="text-center p-2">{{ 'common:message.unverifiedData' | i18next }}</span>
</div>
<nav
    mat-tab-nav-bar
    mat-align-tabs="center"
    [mat-stretch-tabs]="false"
    [fitInkBarToContent]="true"
    [tabPanel]="tabPanel"
    class="bg-white drop-shadow-sm mb-2"
>
    <a
        mat-tab-link
        *ngFor="let datum of tabs; trackBy: trackByValue"
        [active]="(reviewsService.selectedTab$ | async) === datum.value"
        (click)="onTabChange(datum.value)"
        >{{ datum.textKey | i18next: { defaultValue: datum.text } }}</a
    >
</nav>
<mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
