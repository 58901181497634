import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPitchTypePipe } from '@stlc/angular/pipes/pitch-type';
import { RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, map as _map } from '@stlc/lodash';
import { ReviewsCatchingTooltipComponent } from '@stlc/reviews/ui';
import { StlcSelectOption } from '@stlc/shared';
import { UiChipListComponent } from '@stlc/ui';
import { UiAppService } from '@stlc/ui/services';
import { UiVegaChartComponent } from '@stlc/ui/vega';

import { ReviewsService } from '../../services/reviews.service';
import { ReviewsCatchingService } from '../../services/reviews-catching.service';

@Component({
    selector: 'stlc-reviews-catching-at-bats',
    templateUrl: './reviews-catching-at-bats.component.html',
    styleUrls: ['./reviews-catching-at-bats.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        ReviewsCatchingTooltipComponent,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPitchTypePipe,
        UiChipListComponent,
        UiVegaChartComponent,
    ],
})
export class ReviewsCatchingAtBatsComponent implements OnInit {
    private readonly reviewsService = inject(ReviewsService);
    readonly reviewsCatchingService = inject(ReviewsCatchingService);
    readonly appService = inject(UiAppService);

    signals: {
        perspective: string;
        pitchSize: number;
        pitchNumberField: string;
        showPitchNumbers?: boolean;
        // filteredIds?: string[];
    } = {
        perspective: 'pitcher',
        pitchNumberField: 'atbatPitchNumber',
        pitchSize: 300,
        showPitchNumbers: true,
    };

    signalListeners = {
        selectedId: (name: string, id: RbdReviewPitchFragment['id']) => {
            if (id) {
                this.reviewsCatchingService.openVideoDialog(
                    id,
                    this.reviewsCatchingService.byBatter$.pipe(
                        map((atBats) =>
                            chain(atBats).map('plateAppearances').flatten().map('pitches').flatten().valueOf()
                        )
                    )
                );
            }
        },
    };

    groupByOptions: StlcSelectOption[] = [
        {
            value: 'inning',
            selected: true,
            text: 'Inning',
            textKey: 'common:inning',
        },
        {
            value: 'batter',
            selected: false,
            text: 'Batter',
            textKey: 'common:batter',
        },
    ];

    ngOnInit() {
        this.reviewsService.selectedTab = 'at-bats';
        this.reviewsCatchingService.selectedGroupBy$.subscribe((selected) => {
            this.groupByOptions = _map(this.groupByOptions, (datum) => ({
                ...datum,
                selected: datum.value === selected,
            }));
        });
    }

    handleGroupByChange(option: StlcSelectOption) {
        this.reviewsCatchingService.selectedGroupBy = option.value;
    }
}
