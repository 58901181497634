import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { pitchTypes } from '@stlc/lookup/legacy';
import { rulebookZone } from '@stlc/vega/utils';

export const catchingStrikeZoneSpec: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 240,
    autosize: { type: 'none', resize: false, contains: 'padding' },
    config: {
        signals: [
            { name: 'backgroundColor' },
            { name: 'textColor' },
            { name: 'gridColor' },
            { name: 'labelColor' },
            { name: 'tickColor' },
            { name: 'titleColor' },
        ],
        axis: {
            gridColor: { signal: "gridColor || darkTheme ? '#dddddd' : '#dddddd'" },
            gridOpacity: 1,
            labelColor: {
                signal: "labelColor || darkTheme ? '#cccccc' : '#333333'",
            },
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: { signal: "tickColor || darkTheme ? '#999999' : '#999999'" },
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: {
                signal: "titleColor || darkTheme ? '#cccccc' : '#333333'",
            },
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fill: { signal: "textColor || (darkTheme ? '#cccccc' : '#333333')" },
        },
        events: { defaults: { allow: ['wheel', 'touchstart'] } },
    },
    signals: [
        { name: 'darkTheme', value: false },
        {
            name: 'background',
            update: "backgroundColor ? backgroundColor : darkTheme ? '#333333' : 'transparent'",
        },
        { name: 'defaultWidth', value: 240 },
        { name: 'defaultHeight', value: 300 },
        { name: 'strikeZoneStroke', update: "darkTheme ? '#ffffff' : '#000000'" },
        { name: 'strikeZoneStrokeOpacity', value: 0.3 },
        { name: 'strikeZoneFill', update: "darkTheme ? '#ffffff' : '#000000'" },
        { name: 'insufficientData', update: 'length(data("data")) == 0' },
        { name: 'xAxisDomain', value: [-2, 2] },
        { name: 'yAxisDomain', value: [-0.5, 4.5] },
        { name: 'xAxisField', value: 'plateSide' },
        { name: 'yAxisField', value: 'plateHeight' },
        { name: 'chartType', value: 'pitches' },
        { name: 'height', update: '(width / defaultWidth) * defaultHeight' },
        { name: 'reverseColors', value: false },
        { name: 'foulBallsAreCircles', value: true },
        {
            name: 'visibleCount',
            update: "length(data('visibleData'))",
        },
        { name: 'bandwidth', value: 12 },
        { name: 'perspective', value: 'batter' },
        { name: 'zoneType', value: '9-box' },
        {
            name: 'label',
            value: {
                pitcher: 'Perspective: Pitcher',
                batter: 'Perspective: Batter',
            },
        },
        { name: 'batterBats' },
        { name: 'pitchTypes' },
        { name: 'pitchType' },
        {
            name: 'pitchHover',
            update: '',
            on: [
                { events: 'symbol:mouseover', update: 'group().datum' },
                { events: 'symbol:mouseout', update: "''" },
            ],
        },
        { name: 'filteredIds', value: null },
        { name: 'selectedIds', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        { name: 'showPitchNumbers', value: true },
        { name: 'actualPitchSize', value: true },
        { name: 'minX', update: 'xAxisDomain[0]' },
        { name: 'maxX', update: 'xAxisDomain[1]' },
        {
            name: 'pitchSize',
            update: 'PI * pow((width / (maxX - minX)) * (0.125 / (showPitchNumbers || actualPitchSize ? 1 : 2)), 2)',
        },
        { name: 'colorField', value: 'pitchType' },
        { name: 'hoveredId', value: null },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'selectable', value: true },
        { name: 'brushable', value: false },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                    // force: true,
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart' }],
                    update: '!selectable && brushable ? true : false',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        {
            name: 'brushX',
            update: `brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []`,
        },
        {
            name: 'brushY',
            update: `brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []`,
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
    ],
    data: [
        {
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: ['isValid(datum[yAxisField])', 'isValid(datum[xAxisField])'].join(' && '),
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'homePlateData',
            values: [
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.694167, y: 0.08 },
                { perspective: 'pitcher', x: 0, y: 0.18 },
                { perspective: 'pitcher', x: -0.694167, y: 0.08 },
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.694167, y: 0.1 },
                { perspective: 'batter', x: 0, y: 0 },
                { perspective: 'batter', x: -0.694167, y: 0.1 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
            ],
            transform: [{ type: 'filter', expr: 'perspective === datum.perspective' }],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: [
                        // 'datum.plateSide >= brushX1 && datum.plateSide >= brushX2',
                        // 'datum.plateHeight <= brushY1 && datum.plateHeight >= brushY2',
                        'inrange(datum[xAxisField], brushX)',
                        'inrange(datum[yAxisField], brushY)',
                        // '!inrange(selectedIds, datum.id)',
                    ].join(' && '),
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: { signal: 'xAxisDomain' },
            range: 'width',
            clamp: true,
            reverse: { signal: "perspective !== 'pitcher'" },
        },
        {
            name: 'y',
            type: 'linear',
            domain: { signal: 'yAxisDomain' },
            range: 'height',
            zero: false,
            clamp: true,
        },
        {
            name: 'pitchTypeColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
        {
            name: 'pitchTypeStrokeColor',
            type: 'ordinal',
            domain: map(pitchTypes, 'name'),
            range: map(pitchTypes, 'color'),
        },
    ],
    marks: [
        {
            type: 'line',
            interactive: false,
            from: { data: 'homePlateData' },
            encode: {
                enter: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    strokeWidth: { value: 1 },
                    strokeCap: { value: 'square' },
                    fillOpacity: { value: 0.033 },
                    strokeOpacity: { value: 0.2 },
                },
                update: {
                    fill: { signal: "darkTheme ? '#ffffff' : '#000000'" },
                    stroke: { signal: "darkTheme ? '#ffffff' : '#000000'" },
                },
            },
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                },
                update: {
                    fill: { signal: 'strikeZoneFill' },
                    fillOpacity: {
                        signal: "zoneType != 'percentile-box' ? 0.04901960784313722 : 0",
                    },
                },
            },
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            encode: {
                enter: {
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: { signal: 'xAxisField' } },
                    y: { scale: 'y', field: { signal: 'yAxisField' } },
                    fill: {
                        signal: "chartType == 'contact' && foulBallsAreCircles && datum.pitchResult != 'IP' && (!isValid(datum[yAxisField]) || !isValid(datum[xAxisField])) ? 'transparent' : '#cccccc'",
                    },
                    // fill: {
                    //     scale: { signal: "colorField + 'Color'" },
                    //     field: { signal: 'colorField' },
                    // },
                    size: { signal: 'pitchSize' },
                    // opacity: { signal: '!showHeatmap || hasSelected ? 1 : 0' },
                    // undefined: { signal: 'showHeatmap || hasSelected ? true : false' },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            fill: { value: 'white' },
                            strokeOpacity: { value: 1 },
                        },
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: { signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI' },
                            stroke: { scale: 'pitchTypeColor', signal: 'parent[colorField]' },
                            strokeWidth: { signal: 'min(max(1, pow((sqrt(pitchSize / PI) + 3), 2) * PI / 32), 2)' },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: 'isInteractive && !isHidden' },
                    encode: {
                        update: {
                            x: { scale: 'x', signal: 'parent[xAxisField]' },
                            y: { scale: 'y', signal: 'parent[yAxisField]' },
                            size: {
                                signal: "chartType == 'contact' && foulBallsAreCircles && parent.pitchResult != 'IP' ? pow((sqrt(pitchSize / PI) - 1), 2) * PI : pitchSize",
                            },
                            fill: {
                                signal: "chartType == 'contact' && foulBallsAreCircles && parent.pitchResult != 'IP' ? 'transparent' : isBrushedOrSelected || (!selectedIds && !brushedIds) ? scale('pitchTypeColor', parent[colorField]) : null",
                            },
                            fillOpacity: {
                                signal: 'hoveredId && hoveredId == parent.id ? 1 : 0.5',
                            },
                            stroke: {
                                signal: "isBrushedOrSelected || (!selectedIds && !brushedIds) ? (chartType == 'contact' && foulBallsAreCircles && parent.pitchResult != 'IP' && (!isValid(parent[yAxisField]) || !isValid(parent[xAxisField])) ? 'transparent' : scale('pitchTypeColor', parent[colorField])) : null",
                            },
                            strokeOpacity: { signal: 'isBrushedOrSelected ? 1 : 0.75' },
                            strokeWidth: {
                                signal: "chartType == 'contact' && foulBallsAreCircles && parent.pitchResult != 'IP' ? 2 : isBrushedOrSelected ? 1 : 0",
                            },
                            // opacity: { signal: '!showHeatmap || hasSelected ? 1 : 0' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                        },
                    },
                },
            ],
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -rulebookZone.width / 2, scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType != 'none' ? strikeZoneStrokeOpacity : 0",
                    },
                    stroke: { signal: 'strikeZoneStroke' },
                },
            },
        },
        {
            type: 'path',
            interactive: false,
            encode: {
                enter: { fill: { value: '#939597' }, stroke: { value: '#652c90' } },
                update: {
                    y: { value: rulebookZone.y, scale: 'y' },
                    x: {
                        signal: `(perspective == 'pitcher' ? -1 : 1) * ${rulebookZone.width / 2}`,
                        scale: 'x',
                    },
                    scaleY: { signal: 'height / defaultHeight' },
                    scaleX: { signal: 'width / defaultWidth' },
                    path: {
                        value: 'M0,36 H85 M0,72 H85 M28.3333333333,0 V109 M56.6666666667,0 V109',
                    },
                    strokeWidth: { value: 1 },
                    strokeDash: { value: [5, 5] },
                    stroke: { signal: 'strikeZoneStroke' },
                    opacity: {
                        signal: "zoneType == '9-box' ? strikeZoneStrokeOpacity : 0",
                    },
                },
            },
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
};
