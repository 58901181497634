<div class="text-gray-400 text-xs h-6 leading-6">Pitch Movement</div>
<ui-vega-chart
    renderer="canvas"
    [spec]="spec"
    [signals]="signals"
    [data]="chartData"
    [tooltipTemplate]="tooltipTemplate"
></ui-vega-chart>
<div class="flex w-80">
    <div class="flex-1 flex justify-center">
        <button type="button" mat-button (click)="toggleShowPitches()">
            {{ signals.showPitches ? 'Show Averages' : 'Show Pitches' }}
        </button>
    </div>
    <div class="flex-1 flex justify-center">
        <button type="button" mat-button (click)="toggleShowContext()" [disabled]="!contextPitches?.length">
            {{ signals.showContext ? 'Hide Context' : 'Show Context' }}
        </button>
    </div>
</div>
<div class="text-xs text-gray-400 text-center flex flex-col space-y-2">
    <div
        *ngIf="lgData.length > 0"
        [innerHTML]="
            'chart:mlbArmAngle_label'
                | i18next
                    : {
                          value: armAngleKey,
                          playerThrows: pitcherThrows
                      }
        "
    ></div>
    <div
        [innerHTML]="
            'chart:pitchMovement.yearAverage_label'
                | i18next
                    : {
                          defaultValue: 'Average movement for this year shown with a +'
                      }
        "
    ></div>
    <div
        [innerHTML]="
            'chart:pitchMovement.contextPitches_label'
                | i18next
                    : {
                          defaultValue: 'Context based on all pitches thrown prior to this game in the same year'
                      }
        "
    ></div>
</div>

<ng-template #tooltipTemplate let-item="item">
    <div class="flex flex-col text-xs max-w-lg whitespace-nowrap" *ngIf="item">
        <div class="flex flex-col mb-1">
            <div class="font-bold text-center">Pitch #{{ item.pitcherPitchOfGame }}</div>
            <div class="font-bold text-center">
                {{ item.isTopInning ? 'Top' : 'Bottom' }} {{ item.inning | stlcNumberToOrdinal }}, {{ item.preBalls }} -
                {{ item.preStrikes }}, {{ item.preOuts }} out{{ item.preOuts !== 1 ? 's' : '' }}
            </div>
            <div class="font-bold text-center" *ngIf="item.batter">vs {{ item.batter | stlcPlayerName }}</div>
        </div>
        <div class="text-center">
            {{ item.pitchType | stlcPitchType }}
        </div>
        <div class="flex" *ngIf="item.releaseSpeed || item.releaseExtension">
            <div class="flex-1">
                {{ item.releaseSpeed | stlcNumberToFixed : { digits: 0, defaultText: '-', appendText: ' MPH' } }}
            </div>
            <div class="flex-1 text-right">{{ item.releaseExtension | stlcFeetAndInches : { input: 'feet' } }} Ext</div>
        </div>
        <div class="flex" *ngIf="item.releaseHeight || item.releaseSide">
            <div class="flex-1">{{ item.releaseHeight | stlcFeetAndInches : { input: 'feet' } }} Ht</div>
            <div class="flex-1 text-right">{{ item.releaseSide | stlcFeetAndInches : { input: 'feet' } }} Side</div>
        </div>
        <div class="flex" *ngIf="item.verticalApproachAngle || item.horizontalApproachAngle">
            <div class="flex-1">
                {{ item.releaseVerticalAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} VRA
            </div>
            <div class="flex-1 text-right">
                {{ item.releaseHorizontalAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} HRA
            </div>
        </div>
        <div class="flex" *ngIf="item.releaseSpinRate || item.releaseSpinAxis || item.movementSpinAxis">
            <div class="flex-1">
                {{
                    item.releaseSpinRate
                        | stlcNumberToFixed
                            : {
                                  digits: 0,
                                  roundToNearest: 5,
                                  appendText: ' RPM'
                              }
                }}
            </div>
            <div class="flex-1 text-right">
                {{ item.movementSpinAxis || item.releaseSpinAxis | stlcDegreeToClockFace }} Axis
            </div>
        </div>
        <div class="flex" *ngIf="item.inducedVerticalBreak || item.horizontalBreak">
            <div class="flex-1">
                {{ item.inducedVerticalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} IVB
            </div>
            <div class="flex-1 text-right">
                {{ item.horizontalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} HB
            </div>
        </div>
        <div class="flex" *ngIf="item.verticalApproachAngle || item.horizontalApproachAngle">
            <div class="flex-1">
                {{ item.verticalApproachAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} VAA
            </div>
            <div class="flex-1 text-right">
                {{ item.horizontalApproachAngle | stlcNumberToFixed : { digits: 1, appendText: '˚' } }} HAA
            </div>
        </div>
        <div class="text-center mt-1">{{ item.pitchCall }}</div>
    </div>
</ng-template>
