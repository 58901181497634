<div class="flex flex-col text-xs max-w-lg whitespace-nowrap" *ngIf="pitch">
    <div class="flex flex-col">
        <div class="font-bold text-center">
            PA #{{ pitch.atBatNumber }}, {{ 'common:pitch' | i18next }} #{{ pitch.atbatPitchNumber }}
        </div>
        <div class="font-bold text-center">
            {{ pitch.isTopInning ? 'Top' : 'Bottom' }} {{ pitch.inning | stlcNumberToOrdinal }}, {{ pitch.preBalls }} -
            {{ pitch.preStrikes }}, {{ 'game:out' | i18next : { count: pitch.preOuts } }}
        </div>
        <div class="font-bold text-center" *ngIf="showPitcher && pitch.pitcher">
            {{ pitch.pitcher | stlcPlayerName }}
        </div>
        <div class="font-bold text-center" *ngIf="pitch.batter">vs {{ pitch.batter | stlcPlayerName }}</div>
    </div>
    <div class="text-center mt-1">{{ pitch | stlcI18nGameEvent }}</div>
    <div class="flex justify-between mt-1" *ngIf="pitch.pitchType || pitch.releaseSpeed">
        <div>{{ pitch.pitchType | stlcI18nPitchType : 'legacy' }}</div>
        <div>
            {{ pitch.releaseSpeed | stlcNumberToFixed : { digits: 0, defaultText: '-', appendText: ' MPH' } }}
        </div>
    </div>
    <div class="flex justify-between" *ngIf="pitch.inducedVerticalBreak || pitch.horizontalBreak">
        <div>{{ pitch.inducedVerticalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} IVB</div>
        <div>{{ pitch.horizontalBreak | stlcFeetAndInches : { input: 'inches', output: 'inches' } }} HB</div>
    </div>
    <div class="flex flex-col items-center py-2" *ngIf="pitch.calledStrikeProbability >= 0">
        <div
            [innerHTML]="'stat:calledStrikeProbability_label' | i18next : { defaultValue: 'Called Strike Probability' }"
        ></div>
        <div>
            {{ pitch.calledStrikeProbability | stlcNumberToFixed : { digits: 0, defaultText: '-', appendText: '%' } }}
        </div>
    </div>
</div>
