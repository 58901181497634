import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import type { Spec, View } from 'vega';

import { StlcDegreeToClockFacePipe } from '@stlc/angular/pipes/degree-to-clock-face';
import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPitchTypePipe } from '@stlc/angular/pipes/pitch-type';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { ReviewsPlayerGamePitchFragment, ReviewsPlayerPitchContextFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, isNil } from '@stlc/lodash';
import { LeagueMovementEllipses, PlayerChartsPitchMovement } from '@stlc/player-stats/shared';
import { pitchingPitchMovementSpec } from '@stlc/reviews/shared';
import { UiVegaChartComponent } from '@stlc/ui/vega';

@Component({
    selector: 'stlc-reviews-pitching-report-pitch-movement',
    templateUrl: './reviews-pitching-report-pitch-movement.component.html',
    styleUrls: ['./reviews-pitching-report-pitch-movement.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        StlcDegreeToClockFacePipe,
        StlcFeetAndInchesPipe,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPitchTypePipe,
        StlcPlayerNamePipe,
        UiVegaChartComponent,
        StlcI18nModule,
    ],
})
export class ReviewsPitchingReportPitchMovementComponent implements OnChanges {
    @Input()
    pitches: ReviewsPlayerGamePitchFragment[] = [];

    @Input()
    contextPitches: ReviewsPlayerPitchContextFragment[];

    @Input()
    avgData: PlayerChartsPitchMovement[];

    @Input()
    lgData: LeagueMovementEllipses[];

    @Input()
    armAngleKey: string;

    @Input()
    pitcherThrows: string;

    spec: Spec = pitchingPitchMovementSpec;
    signals: any = { unit: 'inches', showPitches: true, showContext: false };
    chartData: any = {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pitcherThrows']) {
            this.updateSignals('pitcherThrowsLeft', ['L', 'Left'].includes(this.pitcherThrows));
        }

        if (changes['pitches'] || changes['avgData'] || changes['lgData'] || changes['contextPitches']) {
            this.chartData = {
                data: chain(this.pitches)
                    .filter(
                        (datum) =>
                            !isNil(datum.pitchType) &&
                            !isNil(datum.inducedVerticalBreak) &&
                            !isNil(datum.horizontalBreak)
                    )
                    .valueOf(),
                context: chain(this.contextPitches)
                    .filter(
                        (datum) =>
                            !isNil(datum.pitchType) &&
                            !isNil(datum.inducedVerticalBreak) &&
                            !isNil(datum.horizontalBreak)
                    )
                    .valueOf(),
                avgData: this.avgData,
                lgData: this.lgData,
            };
        }
    }

    toggleShowPitches() {
        this.updateSignals('showPitches', !this.signals.showPitches);
    }

    toggleShowContext() {
        this.updateSignals('showContext', !this.signals.showContext);
    }

    private updateSignals(property: 'pitcherThrowsLeft' | 'showPitches' | 'showContext', value) {
        this.signals = {
            ...this.signals,
            [property]: value,
        };
    }
}
