import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { I18NEXT_SERVICE } from 'angular-i18next';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs';
import type { Spec } from 'vega';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPitchTypePipe } from '@stlc/angular/pipes/pitch-type';
import { ReviewsPlayerGamePitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, head, isEmpty, isNil } from '@stlc/lodash';
import { PlayerChartsUiBattingTooltipComponent, videoStrikeZoneSpec } from '@stlc/player-charts/ui';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiVegaBrushingService, UiVegaChartComponent } from '@stlc/ui/vega';
import { UiVideoDialogComponent } from '@stlc/ui/video';

import { ReviewsBattingService } from '../../services/reviews-batting.service';
import { battingAtBatsStrikeZoneSpec } from '../../vega/batting-at-bats-strike-zone-spec';

import { ReviewsAtBat, ReviewsBattingAtBatsService } from './reviews-batting-at-bats.service';

@Component({
    selector: 'stlc-reviews-batting-at-bats',
    templateUrl: './reviews-batting-at-bats.component.html',
    styleUrls: ['./reviews-batting-at-bats.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        PlayerChartsUiBattingTooltipComponent,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPitchTypePipe,
        UiVegaChartComponent,
    ],
    providers: [ReviewsBattingAtBatsService, UiVegaBrushingService],
})
export class ReviewsBattingAtBatsComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly brushingService = inject(UiVegaBrushingService);
    reviewsBattingService = inject(ReviewsBattingService);
    reviewsBattingAtBatsService = inject(ReviewsBattingAtBatsService);

    videoDialog = inject(UiDialogService);
    i18next = inject(I18NEXT_SERVICE);

    strikeZoneSpec: Spec = battingAtBatsStrikeZoneSpec;
    videoStrikeZoneSpec: Spec = videoStrikeZoneSpec;
    strikeZoneSignals = {};
    strikeZoneSignalListeners = {
        // clickedPitch: (name: string, { datum }: { datum: ReviewsPlayerGamePitchFragment }) => {
        //     if (datum) {
        //         this.openVideoDialog(datum);
        //     }
        // },
    };

    showSwingDecisionPercentiles = true;

    ngOnInit() {
        this.updatePitchLocationSpecSignals();

        this.brushingService.selectedId$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((selectedId) => !isNil(selectedId))
            )
            .subscribe((selectedId) => {
                this.openVideoDialog(selectedId);
            });
    }

    updatePitchLocationSpecSignals() {
        this.strikeZoneSignals = {
            ...this.strikeZoneSignals,
            zoneType: this.showSwingDecisionPercentiles ? 'percentile-box' : '9-box',
        };
    }

    openVideoDialogForAtBat(atBat: ReviewsAtBat) {
        this.openVideoDialog(
            chain(atBat.pitches)
                .find((id) => !id)
                .get('id')
                .valueOf()
        );
    }

    openVideoDialog(pitchId: string) {
        if (this.reviewsBattingAtBatsService.videoEvents$) {
            this.reviewsBattingAtBatsService.videoEvents$.pipe(take(1)).subscribe((videoEvents) => {
                if (isEmpty(videoEvents)) {
                    return;
                }
                this.videoDialog.open(UiVideoDialogComponent, {
                    data: {
                        charts: [
                            {
                                name: 'Location',
                                spec: this.videoStrikeZoneSpec,
                            },
                        ],
                        selectedEvent: pitchId,
                        eventGroups: chain(videoEvents)
                            .groupBy('eventGroup')
                            .map((value) => value)
                            .valueOf(),
                        previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                        nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
                        selectedEventType: 'pitches',
                    },
                    panelClass: 'stlc-video-dialog',
                });
            });
        }
    }
}
