import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, take, withLatestFrom } from 'rxjs/operators';
import type { Spec } from 'vega';

import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import {
    PlayerStatsHandedness,
    RbdReviewPitchFragment,
    ReviewsByBatter,
    ReviewsGoalFragment,
    ReviewsInning,
} from '@stlc/game/reviews/data-access';
import { StlcI18nBasesOccupiedPipe, StlcI18nGameEventPipe } from '@stlc/i18n/core';
import {
    assign,
    chain,
    compact,
    filter as _filter,
    find,
    groupBy,
    head,
    includes,
    isArray,
    isEmpty,
    isNil,
    isString,
    last,
    map as _map,
    some,
    transform,
    uniqBy,
} from '@stlc/lodash';
import { getPitchTypeAbbrev, getPitchTypeCode, getPitchTypeDescription, getPitchTypeOrder } from '@stlc/lookup/legacy';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiAppService } from '@stlc/ui/services';
import { UiTableDataSource } from '@stlc/ui/table';
import { UiVideoDialogComponent } from '@stlc/ui/video';
import { UserService } from '@stlc/user';

import {
    ReviewsCatchingGameSummary,
    ReviewsCatchingInfo,
    ReviewsCatchingPitchTypeSummary,
} from '../resolves/reviews-catching-info.resolver';
import { catchingGamePitchesSpec } from '../vega/catching-game-pitches-spec.vega';

import { isBallInDirt, isSbAttempt, playWithCatcher } from './events';
import { ReviewsService } from './reviews.service';

const pitchProperties: Array<keyof RbdReviewPitchFragment> = [
    'baseSituation',
    'battedBallOutcome',
    'battedBallType',
    'batter',
    'battingTeamId',
    'description',
    'eventType',
    'game',
    'inning',
    'inningPa',
    'isFinalPlay',
    'isNextPlay',
    'isTopOfInning',
    'launchVerticalAngle',
    'launchInitialSpeed',
    'pitcher',
    'pitcherId',
    'pitchingTeamId',
    'pitchResult',
    'playByPlayDescription',
    'postAwayTeamScore',
    'postHomeTeamScore',
    'postOuts',
    'preAwayTeamScore',
    'preBalls',
    'preHomeTeamScore',
    'preOuts',
    'preOuts',
    'preStrikes',
];

interface ReviewsAtBat {
    titles: string[];
    pitches: RbdReviewPitchFragment[];
    isVideoAvailable: boolean;
    battedBallResult: string;
}

function isRbdReviewPitchId(value: ReviewsAtBat | RbdReviewPitchFragment['id']): value is RbdReviewPitchFragment['id'] {
    return isString(value);
}

@Injectable()
export class ReviewsCatchingService {
    readonly playerId: string;
    readonly startDate: string;
    readonly endDate: string;

    readonly queryParams$: Observable<Params>;
    readonly hasFilter$: Observable<boolean>;
    readonly filterOptions$: Observable<Array<{ [key: string]: any }>>;

    readonly filteredIds$: Observable<string[]>;
    readonly filteredPitches$: Observable<RbdReviewPitchFragment[]>;
    readonly brushedPitches$: Observable<RbdReviewPitchFragment[]>;
    readonly visiblePitches$: Observable<RbdReviewPitchFragment[]>;
    readonly filteredIdsCount$: Observable<number>;
    readonly filters$: Observable<{ [key: string]: any }>;
    readonly gamePitchesSpec$: Observable<Spec>;
    readonly gameSummary$: Observable<ReviewsCatchingGameSummary[]>;
    readonly innings$: Observable<ReviewsInning[]>;
    readonly byBatter$: Observable<ReviewsByBatter[]>;
    readonly pitches$: Observable<RbdReviewPitchFragment[]>;
    readonly chartPitches$: Observable<RbdReviewPitchFragment[]>;
    readonly pitchTypes$: Observable<string[]>;
    readonly hasEdgertronic$: Observable<boolean>;
    // readonly hasPitchesAffectedByFraming$: Observable<boolean>;
    readonly pitchTypeSummary$: Observable<ReviewsCatchingPitchTypeSummary[]>;
    readonly pitchLocationSpec$: Observable<Spec>;
    readonly allPitchesLocationSpec$: Observable<Spec>;
    readonly goals$: Observable<ReviewsGoalFragment[]>;
    readonly isUnverified$: Observable<boolean | undefined | null>;
    readonly isVideoAvailable$: Observable<boolean | undefined | null>;
    readonly runnersInformationAvailable$: Observable<boolean>;
    readonly pitchers$: Observable<Array<RbdReviewPitchFragment['pitcher']>>;
    readonly selectedGroupBy$: Observable<string>;
    readonly hasBallsInDirt$: Observable<boolean>;
    readonly hasWpPb$: Observable<boolean>;
    readonly hasSbAttempts$: Observable<boolean>;
    readonly hasPlaysWithCatcher$: Observable<boolean>;
    readonly strikeZoneBorderPoints$: Observable<Array<{ x?: number; y?: number }>>;

    private queryParamsSubject = new BehaviorSubject<Params>({});
    private filterCountSubject = new BehaviorSubject<number>(0);
    private filteredIdsSubject = new BehaviorSubject<string[]>([]);
    private brushedPitchesSubject = new BehaviorSubject<RbdReviewPitchFragment[]>([]);
    private filtersSubject = new BehaviorSubject<{ [key: string]: any }>({});
    private gamePitchesSpecSubject = new BehaviorSubject<Spec>({});
    private gameSummarySubject = new BehaviorSubject<ReviewsCatchingGameSummary[]>([]);
    private pitchesSubject = new BehaviorSubject<RbdReviewPitchFragment[]>([]);
    private pitchTypeSummarySubject = new BehaviorSubject<ReviewsCatchingPitchTypeSummary[]>([]);
    private pitchLocationSpecSubject = new BehaviorSubject<Spec>({});
    private allPitchesLocationSpecSubject = new BehaviorSubject<Spec>({});
    private goalsSubject = new BehaviorSubject<ReviewsGoalFragment[]>([]);
    private selectedGroupBySubject = new BehaviorSubject<string>('inning');
    private strikeZoneBorderPointsSubject = new BehaviorSubject<Array<{ x?: number; y?: number }>>([]);

    // private filteredPitches: RbdReviewPitchFragment[] = [];
    videoPitchLocationSpec: Spec = {};
    private stlcI18nGameEventPipe: StlcI18nGameEventPipe;
    private stlcI18nBasesOccupiedPipe: StlcI18nBasesOccupiedPipe;
    private ordinalPipe: StlcNumberToOrdinalPipe;
    private playerNamePipe: StlcPlayerNamePipe;

    enableBrushing$: Observable<boolean>;
    calledStrikeZoneCaption = '';

    groupByPreferenceKey = 'reviews:catching:groupBy';

    private appService = inject(UiAppService);
    private reviewsService = inject(ReviewsService);
    private videoDialog = inject(UiDialogService);
    private userService = inject(UserService);
    private i18next = inject(I18NEXT_SERVICE);
    private locale = inject(LOCALE_ID);

    constructor() {
        const route = inject(ActivatedRoute);
        let ref = route.snapshot;
        let data: ReviewsCatchingInfo;
        while (ref) {
            if (!data) {
                data = ref.data['catchingInfo'];
            }

            if (data) {
                break;
            }
            ref = ref.parent;
        }

        if (!data) {
            console.log('could not find expected catchingInfo');
            return;
        }

        this.playerId = data.playerId;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.gameSummary = data.gameSummary;
        this.pitchTypeSummary = data.pitchTypeSummary;
        this.pitches = data.pitches;
        this.allPitchesLocationSpec;
        this.strikeZoneBorderPoints = data.strikeZoneBorder?.borderPoints ?? [];
        this.goals = data.goals;
        this.pitchLocationSpec = data.strikeZone;
        this.videoPitchLocationSpec = data.videoStrikeZone;
        this.gamePitchesSpec = catchingGamePitchesSpec;
        this.allPitchesLocationSpec = data.allPitchesStrikeZone;

        if (data.strikeZoneBorder) {
            const {
                gameYear,
                classLevel: { levelName },
            } = data.strikeZoneBorder;
            this.calledStrikeZoneCaption = this.i18next.t('reviews:catching.calledStrikeZone', {
                replace: {
                    gameYear,
                    level: levelName,
                },
                defaultValue: '{{ gameYear }} {{ level }} called strike zone shown with gray line',
            });
        } else {
            this.calledStrikeZoneCaption = undefined;
        }

        this.queryParams$ = this.queryParamsSubject.asObservable();
        this.ordinalPipe = new StlcNumberToOrdinalPipe();
        this.playerNamePipe = new StlcPlayerNamePipe();
        this.stlcI18nGameEventPipe = new StlcI18nGameEventPipe(this.i18next);
        this.stlcI18nBasesOccupiedPipe = new StlcI18nBasesOccupiedPipe(this.i18next);
        this.filters$ = this.filtersSubject.asObservable();
        this.gamePitchesSpec$ = this.gamePitchesSpecSubject.asObservable();
        this.gameSummary$ = this.gameSummarySubject.asObservable();
        this.pitches$ = this.pitchesSubject.asObservable();
        this.pitchTypeSummary$ = this.pitchTypeSummarySubject.asObservable();
        this.pitchLocationSpec$ = this.pitchLocationSpecSubject.asObservable();
        this.allPitchesLocationSpec$ = this.allPitchesLocationSpecSubject.asObservable();
        this.goals$ = this.goalsSubject.asObservable();
        this.isUnverified$ = this.gameSummarySubject.pipe(map((datum) => some(datum, 'isUnverified')));
        this.brushedPitches$ = this.brushedPitchesSubject.asObservable();
        this.selectedGroupBy$ = this.selectedGroupBySubject.asObservable();
        this.strikeZoneBorderPoints$ = this.strikeZoneBorderPointsSubject.asObservable();

        this.userService
            .getPreferenceValue$(this.groupByPreferenceKey)
            .pipe(take(1))
            .subscribe((preference?: string) => {
                if (!isNil(preference)) {
                    this.selectedGroupBy = preference;
                }
            });

        this.enableBrushing$ = this.appService.isTouchDevice$.pipe(map((value) => !value));

        this.chartPitches$ = this.pitches$.pipe(map((pitches) => uniqBy(pitches, 'id')));

        this.pitchTypes$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .uniqBy('pitchType')
                    .map('pitchType')
                    .compact()
                    .orderBy((datum) => getPitchTypeOrder(datum))
                    .valueOf()
            ),
            shareReplay(1)
        );

        this.hasBallsInDirt$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (datum) => isBallInDirt(datum))),
            shareReplay(1)
        );

        this.hasWpPb$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (datum) => includes(['passed_ball', 'wild_pitch'], datum.eventType)))
        );

        this.hasSbAttempts$ = this.pitches$.pipe(map((pitches) => some(pitches, (datum) => isSbAttempt(datum))));

        this.hasEdgertronic$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .map('videos')
                    .flatten()
                    .some((video) => video.videoAngle?.startsWith('Edger'))
                    .valueOf()
            ),
            shareReplay(1)
        );

        this.runnersInformationAvailable$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (pitch) => !isNil(pitch.basesOccupied)))
        );

        this.pitchers$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .orderBy((datum) => datum.pitchNumber)
                    .uniqBy('pitcher.id')
                    .map('pitcher')
                    .compact()
                    .valueOf()
            ),
            shareReplay(1)
        );

        this.hasPlaysWithCatcher$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (datum) => playWithCatcher(datum)))
        );

        // this.hasPitchesAffectedByFraming$ = this.pitches$.pipe(
        //     map((pitches) =>
        //         chain(pitches)
        //             .map('affectedByFraming')
        //             .some((datum) => datum === 1)
        //             .valueOf()
        //     )
        // );

        this.filterOptions$ = this.queryParams$.pipe(
            withLatestFrom(
                this.pitchTypes$,
                // this.hasEdgertronic$,
                this.pitchers$,
                // this.hasPitchesAffectedByFraming$,
                this.hasBallsInDirt$,
                this.hasWpPb$,
                this.hasSbAttempts$,
                this.hasPlaysWithCatcher$
            ),
            map(
                ([
                    queryParams,
                    pitchTypes,
                    // hasEdgertronic,
                    pitchers,
                    // hasPitchesAffectedByFraming,
                    hasBallsInDirt,
                    hasWpPb,
                    hasSbAttempts,
                    hasPlaysWithCatcher,
                ]) => {
                    const otherFilters = {
                        id: 'other',
                        label: 'filters:other_header',
                        options: compact([
                            // hasEdgertronic
                            //     ? {
                            //           id: 'edgertronic',
                            //           textKey: 'filters:edgertronic',
                            //           text: 'Edgertronic',
                            //           value: 'edgertronic',
                            //           selected:
                            //               queryParams?.['other'] &&
                            //               this.isFilterSelected(queryParams?.['other'], 'edgertronic'),
                            //       }
                            //     : undefined,
                            // hasPitchesAffectedByFraming
                            //     ? {
                            //           id: 'affectedByFraming',
                            //           textKey: 'filters:affectedByFraming',
                            //           text: 'Affected By Framing',
                            //           value: 'affectedByFraming',
                            //           selected:
                            //               queryParams?.['other'] &&
                            //               this.isFilterSelected(queryParams?.['other'], 'affectedByFraming'),
                            //       }
                            //     : undefined,
                            hasBallsInDirt
                                ? {
                                      id: 'ballsInDirt',
                                      textKey: 'filters:ballsInDirt',
                                      text: 'Balls in Dirt',
                                      value: 'ballsInDirt',
                                      selected:
                                          queryParams?.['other'] &&
                                          this.isFilterSelected(queryParams?.['other'], 'ballsInDirt'),
                                  }
                                : undefined,
                            hasWpPb
                                ? {
                                      id: 'wildPitchPassedBalls',
                                      textKey: 'filters:wildPitchPassedBalls',
                                      text: "WP's & PB's",
                                      value: 'wildPitchPassedBalls',
                                      selected:
                                          queryParams?.['other'] &&
                                          this.isFilterSelected(queryParams?.['other'], 'wildPitchPassedBalls'),
                                  }
                                : undefined,
                            hasSbAttempts
                                ? {
                                      id: 'sbAttempts',
                                      textKey: 'filters:sbAttempts',
                                      text: 'SB Attempts',
                                      value: 'sbAttempts',
                                      selected:
                                          queryParams?.['other'] &&
                                          this.isFilterSelected(queryParams?.['other'], 'sbAttempts'),
                                  }
                                : undefined,
                            hasPlaysWithCatcher
                                ? {
                                      id: 'defensivePlays',
                                      textKey: 'filters:defensivePlays',
                                      text: 'Defensive Plays',
                                      value: 'defensivePlays',
                                      selected:
                                          queryParams?.['other'] &&
                                          this.isFilterSelected(queryParams?.['other'], 'defensivePlays'),
                                  }
                                : undefined,
                        ]),
                    };

                    return compact([
                        {
                            id: 'pitchType',
                            label: 'filters:pitchType_header',
                            options: chain(pitchTypes)
                                .map((pitchType) => {
                                    const code = getPitchTypeCode(pitchType);
                                    const abbreviation = getPitchTypeAbbrev(pitchType);
                                    return {
                                        text: getPitchTypeDescription(pitchType),
                                        textKey: `pitchType:${abbreviation}_label`,
                                        value: pitchType,
                                        pitchType,
                                        // order: getPitchTypeOrder(pitchType),
                                        color: `pitch-${code.toLowerCase()}`,
                                        selected:
                                            queryParams?.['pitchType'] &&
                                            this.isFilterSelected(queryParams['pitchType'], pitchType),
                                        abbreviation,
                                    };
                                })
                                .orderBy('order')
                                .valueOf(),
                        },
                        {
                            id: 'batterHandedness',
                            label: 'filters:batterHandedness_header',
                            options: [
                                {
                                    id: PlayerStatsHandedness.Left,
                                    textKey: 'filters:batterHandednessLeft_label',
                                    value: PlayerStatsHandedness.Left,
                                    selected:
                                        queryParams?.['batterHandedness'] &&
                                        this.isFilterSelected(
                                            queryParams['batterHandedness'],
                                            PlayerStatsHandedness.Left
                                        ),
                                },
                                {
                                    id: PlayerStatsHandedness.Right,
                                    textKey: 'filters:batterHandednessRight_label',
                                    value: PlayerStatsHandedness.Right,
                                    selected:
                                        queryParams?.['batterHandedness'] &&
                                        this.isFilterSelected(
                                            queryParams['batterHandedness'],
                                            PlayerStatsHandedness.Right
                                        ),
                                },
                            ],
                        },
                        {
                            id: 'pitcher',
                            label: 'common:pitcher',
                            options: _map(pitchers, (pitcher) => ({
                                id: `${pitcher.id}`,
                                text:
                                    `${pitcher.firstName} ${pitcher.lastName}` + (pitcher.throws === 'L' ? ' (L)' : ''),
                                value: `${pitcher.id}`,
                                selected:
                                    queryParams?.['pitcher'] &&
                                    this.isFilterSelected(queryParams['pitcher'], `${pitcher.id}`),
                            })),
                        },
                        {
                            id: 'countType',
                            label: 'filters:countType_header',
                            options: [
                                {
                                    id: 'firstPitch',
                                    textKey: 'filters:firstPitch_label',
                                    value: [{ preBalls: 0, preStrikes: 0 }],
                                    selected:
                                        queryParams?.['countType'] &&
                                        this.isFilterSelected(queryParams['countType'], 'firstPitch'),
                                },
                                {
                                    id: 'ahead',
                                    textKey: 'filters:ahead_label',
                                    value: [
                                        { preBalls: 0, preStrikes: 1 },
                                        { preBalls: 0, preStrikes: 2 },
                                        { preBalls: 1, preStrikes: 2 },
                                    ],
                                    selected:
                                        queryParams?.['countType'] &&
                                        this.isFilterSelected(queryParams['countType'], 'ahead'),
                                },
                                {
                                    id: 'behind',
                                    textKey: 'filters:behind_label',
                                    value: [
                                        { preBalls: 1, preStrikes: 0 },
                                        { preBalls: 2, preStrikes: 0 },
                                        { preBalls: 3, preStrikes: 0 },
                                        { preBalls: 2, preStrikes: 1 },
                                        { preBalls: 3, preStrikes: 1 },
                                    ],
                                    selected:
                                        queryParams?.['countType'] &&
                                        this.isFilterSelected(queryParams['countType'], 'behind'),
                                },
                                {
                                    id: 'even',
                                    textKey: 'filters:even_label',
                                    value: [
                                        { preBalls: 1, preStrikes: 1 },
                                        { preBalls: 2, preStrikes: 2 },
                                    ],
                                    selected:
                                        queryParams?.['countType'] &&
                                        this.isFilterSelected(queryParams['countType'], 'even'),
                                },
                                {
                                    id: 'full',
                                    textKey: 'filters:full_label',
                                    value: [{ preBalls: 3, preStrikes: 2 }],
                                    selected:
                                        queryParams?.['countType'] &&
                                        this.isFilterSelected(queryParams['countType'], 'full'),
                                },
                            ],
                        },
                        {
                            id: 'pitchOutcome',
                            label: 'filters:pitchResult_header',
                            options: [
                                {
                                    id: 'ball',
                                    textKey: 'filters:ball_label',
                                    value: 'Ball',
                                    selected:
                                        queryParams?.['pitchOutcome'] &&
                                        this.isFilterSelected(queryParams['pitchOutcome'], 'ball'),
                                },
                                {
                                    id: 'calledStrike',
                                    textKey: 'filters:calledStrike_label',
                                    value: 'Called Strike',
                                    selected:
                                        queryParams?.['pitchOutcome'] &&
                                        this.isFilterSelected(queryParams['pitchOutcome'], 'calledStrike'),
                                },
                                {
                                    id: 'swingingStrike',
                                    textKey: 'filters:swingingStrike_label',
                                    value: 'Swinging Strike',
                                    selected:
                                        queryParams?.['pitchOutcome'] &&
                                        this.isFilterSelected(queryParams['pitchOutcome'], 'swingingStrike'),
                                },
                                {
                                    id: 'foulBall',
                                    textKey: 'filters:foul_label',
                                    value: 'Foul Ball',
                                    selected:
                                        queryParams?.['pitchOutcome'] &&
                                        this.isFilterSelected(queryParams['pitchOutcome'], 'foulBall'),
                                },
                                {
                                    id: 'inPlay',
                                    textKey: 'filters:inPlay_label',
                                    value: 'In Play',
                                    selected:
                                        queryParams?.['pitchOutcome'] &&
                                        this.isFilterSelected(queryParams['pitchOutcome'], 'inPlay'),
                                },
                            ],
                        },
                        {
                            id: 'basesOccupied',
                            label: 'filters:runners_header',
                            options: [
                                {
                                    id: 'empty',
                                    textKey: 'filters:empty_label',
                                    value: null,
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], 'empty'),
                                },
                                {
                                    id: 'risp',
                                    textKey: 'filters:risp_label',
                                    value: ['2', '23', '3', '13', '12', '123'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], 'risp'),
                                },
                                {
                                    id: '1',
                                    textKey: 'filters:runners_first',
                                    value: ['1'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '1'),
                                },
                                {
                                    id: '12',
                                    textKey: 'filters:runners_first_second',
                                    value: ['12'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '12'),
                                },
                                {
                                    id: '13',
                                    textKey: 'filters:runners_first_third',
                                    value: ['13'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '13'),
                                },
                                {
                                    id: '2',
                                    textKey: 'filters:runners_second',
                                    value: ['2'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '2'),
                                },
                                {
                                    id: '23',
                                    textKey: 'filters:runners_second_third',
                                    value: ['23'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '23'),
                                },
                                {
                                    id: '3',
                                    textKey: 'filters:runners_third',
                                    value: ['3'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], '3'),
                                },
                                {
                                    id: 'full',
                                    textKey: 'filters:runners_full',
                                    value: ['123'],
                                    selected:
                                        queryParams?.['basesOccupied'] &&
                                        this.isFilterSelected(queryParams['basesOccupied'], 'full'),
                                },
                            ],
                        },
                        otherFilters.options.length > 0 ? otherFilters : undefined,
                    ]);
                }
            ),
            shareReplay(1)
        );
        this.filters$ = combineLatest([this.queryParams$, this.filterOptions$]).pipe(
            map(([queryParams, filterOptions]) => {
                const filters = transform(
                    queryParams,
                    (result, value, key) => {
                        if (!isArray(value)) {
                            value = [value];
                        }
                        const options = chain(filterOptions).find({ id: key }).get('options').valueOf();
                        const target = _map(value, (datum) => {
                            const item = find(options, (option) => option.id === datum || option.value === datum);
                            return item?.value;
                        });
                        result[key] = target;
                    },
                    {}
                );
                return filters;
            }),
            shareReplay(1)
        );
        this.hasFilter$ = this.filters$.pipe(map((filters) => !isEmpty(filters)));

        this.filteredIds$ = combineLatest([this.pitches$, this.filters$]).pipe(
            map(([pitches, filters]) => this.reviewsService.getFilteredIds(pitches, filters)),
            shareReplay(1)
        );

        this.innings$ = combineLatest([this.pitches$]).pipe(
            map(([pitches]) =>
                chain(pitches)
                    .groupBy('inning')
                    .transform((result: ReviewsInning[], values, inning) => {
                        const inningPas = groupBy(values, 'inningPa');
                        result.push({
                            inning: +inning,
                            plateAppearances: _map(inningPas, (values): ReviewsAtBat => {
                                const dataSource = new UiTableDataSource<RbdReviewPitchFragment>();
                                dataSource.data = values;
                                const lastPitch = last(values);
                                const firstPitch = head(values);
                                const pitches = chain(values).orderBy('pitchOfPa').valueOf();
                                return {
                                    ...chain(values).last().pick(pitchProperties).valueOf(),
                                    pitches,
                                    battedBallResult: compact([
                                        lastPitch ? this.stlcI18nGameEventPipe.transform(lastPitch) : undefined,
                                        lastPitch?.launchInitialSpeed
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchInitialSpeed_units',
                                                  {
                                                      replace: { value: lastPitch?.launchInitialSpeed },
                                                  }
                                              )}</span>`
                                            : undefined,
                                        lastPitch?.launchVerticalAngle
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchVerticalAngle_units_html',
                                                  {
                                                      replace: { value: lastPitch?.launchVerticalAngle },
                                                  }
                                              )}</span>`
                                            : undefined,
                                    ]).join(', '),
                                    isVideoAvailable: some(
                                        pitches,
                                        (datum) => datum?.videos && !isEmpty(datum?.videos)
                                    ),
                                    titles: transform(
                                        [firstPitch],
                                        (result, datum: RbdReviewPitchFragment) => {
                                            const paInningInfo = [];

                                            if (!isNil(firstPitch?.atBatNumber)) {
                                                paInningInfo.push(`PA #${datum.atBatNumber}`);
                                            }
                                            if (!isNil(firstPitch?.preOuts)) {
                                                paInningInfo.push(
                                                    `${this.i18next.t('game:out', {
                                                        count: firstPitch?.preOuts ?? 0,
                                                    })}`
                                                );
                                            }

                                            if (paInningInfo.length > 0) {
                                                result.push(paInningInfo.join(', '));
                                            }

                                            if (!isNil(datum.pitcher)) {
                                                result.push(
                                                    `${this.playerNamePipe.transform(datum.pitcher)}${
                                                        datum.pitcherThrows === PlayerStatsHandedness.Left ? ' (L)' : ''
                                                    }`
                                                );
                                            }

                                            if (!isNil(firstPitch?.batter) && !isEmpty(firstPitch?.batter)) {
                                                result.push(
                                                    `vs ${firstPitch?.batter?.firstName} ${
                                                        firstPitch?.batter?.lastName
                                                    }${
                                                        firstPitch?.batterBats === PlayerStatsHandedness.Left
                                                            ? ' (L)'
                                                            : ''
                                                    }`
                                                );
                                            }
                                            if (!isNil(firstPitch?.basesOccupied)) {
                                                result.push(
                                                    this.stlcI18nBasesOccupiedPipe.transform(firstPitch?.basesOccupied)
                                                );
                                            }
                                        },
                                        []
                                    ),
                                };
                            }) as any[],
                        });
                    }, [])
                    .orderBy('inning')
                    .valueOf()
            )
        );

        this.byBatter$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .groupBy('batter.id')
                    .transform((result: ReviewsByBatter[], values) => {
                        const inningPas = chain(values).orderBy('inning').groupBy('inning').valueOf();
                        const pitch = head(values);
                        result.push({
                            batter: `${pitch?.batter?.firstName} ${pitch?.batter?.lastName}${
                                pitch?.batter.bats === 'L' ? ' (L)' : pitch?.batter.bats === 'B' ? ' (S)' : ''
                            }`,
                            lastName: pitch?.batter?.lastName,
                            firstName: pitch?.batter?.firstName,
                            plateAppearances: _map(inningPas, (values): ReviewsAtBat => {
                                const dataSource = new UiTableDataSource<RbdReviewPitchFragment>();
                                dataSource.data = values;
                                const firstPitch = head(values);
                                const lastPitch = last(values);
                                const pitches = chain(values)
                                    .orderBy('pitchOfPa')
                                    .map((datum) => ({
                                        ...datum,
                                        active: true, // includes(selectedIds, datum.id),
                                    }))
                                    .valueOf();
                                return {
                                    ...chain(values).last().pick(pitchProperties).valueOf(),
                                    pitches,
                                    battedBallResult: compact([
                                        lastPitch ? this.stlcI18nGameEventPipe.transform(lastPitch) : undefined,
                                        lastPitch?.launchInitialSpeed
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchInitialSpeed_units',
                                                  {
                                                      replace: { value: lastPitch?.launchInitialSpeed },
                                                  }
                                              )}</span>`
                                            : undefined,
                                        lastPitch?.launchVerticalAngle
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchVerticalAngle_units_html',
                                                  {
                                                      replace: { value: lastPitch?.launchVerticalAngle },
                                                  }
                                              )}</span>`
                                            : undefined,
                                    ]).join(', '),
                                    isVideoAvailable: some(
                                        pitches,
                                        (datum) => datum?.videos && !isEmpty(datum?.videos)
                                    ),
                                    titles: transform(
                                        [firstPitch],
                                        (result, datum: RbdReviewPitchFragment) => {
                                            result.push(
                                                datum.pitcher
                                                    ? `vs ${this.playerNamePipe.transform(datum.pitcher)}${
                                                          datum.pitcherThrows === PlayerStatsHandedness.Left
                                                              ? ' (L)'
                                                              : ''
                                                      }`
                                                    : ''
                                            );
                                            result.push(
                                                datum.inning
                                                    ? `${
                                                          datum.isTopOfInning ? 'Top' : 'Bot'
                                                      } ${this.ordinalPipe.transform(datum.inning)}, PA #${
                                                          firstPitch.inningPa
                                                      }`
                                                    : ''
                                            );
                                            result.push(
                                                `${this.i18next.t('game:out', { count: firstPitch?.preOuts ?? 0 })}`
                                            );
                                            if (!isNil(firstPitch?.basesOccupied)) {
                                                result.push(
                                                    this.stlcI18nBasesOccupiedPipe.transform(firstPitch?.basesOccupied)
                                                );
                                            }
                                        },
                                        []
                                    ),
                                };
                            }) as any[],
                        });
                    }, [])
                    .orderBy(['lastName', 'firstName'], ['asc', 'asc'])
                    .valueOf()
            ),
            shareReplay(1)
        );

        this.filteredIdsCount$ = this.filteredIds$.pipe(map((datum) => datum.length));
        this.filteredPitches$ = combineLatest([this.pitches$, this.filteredIds$]).pipe(
            map(([pitches, filteredIds]) =>
                !filteredIds ? pitches : _filter(pitches, (pitch) => includes(filteredIds, pitch.id))
            ),
            shareReplay(1)
        );

        // Data source for filtered pitches
        this.visiblePitches$ = combineLatest([this.filteredPitches$, this.brushedPitches$]).pipe(
            startWith([]),
            map(([filteredPitches, brushedPitches]) => {
                const filteredIds = _map(filteredPitches, 'id');
                return isEmpty(brushedPitches)
                    ? filteredPitches
                    : _filter(brushedPitches, (pitch) => includes(filteredIds, pitch.id));
            }),
            shareReplay(1)
        );

        this.isVideoAvailable$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (pitch) => pitch.videos.length > 0))
        );
    }

    set queryParams(value: Params) {
        this.queryParamsSubject.next(value);
    }

    set gameSummary(value: ReviewsCatchingGameSummary[]) {
        this.gameSummarySubject.next(value);
    }

    set goals(value: ReviewsGoalFragment[]) {
        this.goalsSubject.next(value);
    }

    set pitchTypeSummary(value: ReviewsCatchingPitchTypeSummary[]) {
        this.pitchTypeSummarySubject.next(value);
    }

    set pitches(value: RbdReviewPitchFragment[]) {
        this.pitchesSubject.next(_map(value, (pitch, index) => assign(pitch, { pitchNumber: index + 1 })));
    }

    set pitchLocationSpec(value: Spec) {
        this.pitchLocationSpecSubject.next(value);
    }

    set allPitchesLocationSpec(value: Spec) {
        this.allPitchesLocationSpecSubject.next(value);
    }

    set gamePitchesSpec(value: Spec) {
        this.gamePitchesSpecSubject.next(value);
    }

    set filteredIds(value: string[] | null) {
        this.filteredIdsSubject.next(value);
    }

    set filterCount(value: number) {
        this.filterCountSubject.next(value);
    }

    set filters(value: { [key: string]: any }) {
        this.filtersSubject.next(value);
    }

    set selectedGroupBy(value: string) {
        this.userService.updatePreference(this.groupByPreferenceKey, value);
        this.selectedGroupBySubject.next(value);
    }

    get selectedGroupBy() {
        return this.selectedGroupBySubject.getValue();
    }

    set strikeZoneBorderPoints(next: Array<{ x?: number; y?: number }>) {
        this.strikeZoneBorderPointsSubject.next(next);
    }

    openVideoDialog(
        pitchIdOrAtBat: RbdReviewPitchFragment['id'] | ReviewsAtBat,
        pitches$ = this.filteredPitches$
    ): void {
        pitches$.pipe(take(1)).subscribe((data) => {
            const pitch = pitchIdOrAtBat
                ? isRbdReviewPitchId(pitchIdOrAtBat)
                    ? find(data, { id: pitchIdOrAtBat })
                    : head(pitchIdOrAtBat.pitches)
                : undefined;
            const videoEvents = _map(data, (datum) => ({
                id: datum.id,
                eventGroup: `${datum.game?.id}:${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
                title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
                subtitles: [
                    datum.inning
                        ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(datum.inning)}`
                        : '',
                    `${datum.preBalls}-${datum.preStrikes} ${
                        datum?.preOuts ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 }) : ''
                    }`,
                    getPitchTypeCode(datum.pitchType)
                        ? this.i18next.t(`pitchType:${getPitchTypeCode(datum.pitchType)}_label`)
                        : 'Unknown',
                    datum?.pitchOutcome
                        ? this.stlcI18nGameEventPipe.transform({
                              isFinalPlay: datum.isFinalPlay,
                              isNextPlay: datum.isNextPlay,
                              pitchResult: datum?.pitchResult,
                              eventType: datum?.eventType,
                              battedBallType: datum?.battedBallType,
                          })
                        : '',
                ],
                pitch: {
                    ...datum,
                    pitchType: getPitchTypeCode(datum.pitchType),
                    description: this.stlcI18nGameEventPipe.transform(datum),
                },
                description: this.stlcI18nGameEventPipe.transform(datum),
                playByPlay: datum?.playByPlayDescription || '',
                videos: datum?.videos || [],
                types: ['pitches'],
            }));

            this.videoDialog.open(UiVideoDialogComponent, {
                data: {
                    charts: [
                        {
                            name: 'Location',
                            spec: this.videoPitchLocationSpec,
                        },
                    ],
                    date: dayjs(pitch?.game?.gameDate).locale(this.locale).format('LL'),
                    selectedEvent: pitch?.id,
                    eventGroups: chain(videoEvents)
                        .groupBy('eventGroup')
                        .map((value) => value)
                        .valueOf(),
                    previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                    nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
                    eventTypeOptions: [
                        {
                            label: 'All',
                            value: 'pitches',
                        },
                        {
                            label: 'Last Pitch of PA',
                            value: 'plays',
                        },
                        {
                            label: 'Scoring Plays',
                            value: 'scoring-plays',
                        },
                    ],
                    selectedEventType: 'pitches',
                },
                panelClass: 'stlc-video-dialog',
            });
        });
    }

    private isFilterSelected(params: { [key: string]: any }, value: string | string[]): boolean {
        return includes(isArray(params) ? params : [params], value);
    }
}
