<div *ngIf="(service.pitches$ | async).length === 0; else dataExists" class="flex flex-col py-10 items-center">
    <div>
        {{
            'reviews:framing.noDataFound'
                | i18next : { defaultValue: 'No data found. Check back later when the data is verified.' }
        }}
    </div>
</div>

<ng-template #dataExists>
    <div class="flex flex-col space-y-4 max-w-screen-xl w-full my-4 mx-auto">
        <div class="flex flex-col md:flex-row justify-center gap-10">
            <div class="flex flex-row gap-2 relative">
                <div class="absolute top-0 left-0 z-100">
                    <ui-icon-dropdown color="light-gray" icon="settings">
                        <span mat-menu-item class="stlc-menu-item-header" disabled>
                            {{ 'chart:perspective_title' | i18next : { defaultValue: 'Perspective' } }}
                        </span>
                        <button
                            mat-menu-item
                            [class.stlc-mat-menu-item-selected]="perspective === 'batter'"
                            (click)="changePerspective('batter')"
                        >
                            {{ 'common:batter' | i18next : { defaultValue: 'Batter' } }}
                        </button>
                        <button
                            mat-menu-item
                            [class.stlc-mat-menu-item-selected]="perspective === 'pitcher'"
                            (click)="changePerspective('pitcher')"
                        >
                            {{ 'common:pitcher' | i18next : { defaultValue: 'Pitcher' } }}
                        </button>
                    </ui-icon-dropdown>
                </div>
                <div class="flex-1 flex flex-col items-center gap-2">
                    <ui-vega-chart
                        renderer="svg"
                        [spec]="pitchesSpec"
                        [data]="pitchesData$ | async"
                        [signals]="pitchesSignals"
                        [tooltipTemplate]="tooltipTemplate"
                        [enableBrushing]="(reviewsService.enableBrushing$ | async) === true"
                        [signalListeners]="pitchesSignalListeners"
                    ></ui-vega-chart>
                    <button mat-button type="button" (click)="handleChartToggle()">
                        {{
                            'chart:togglePitches'
                                | i18next
                                    : {
                                          value: chartSelected === 'pitches' ? 'hide' : 'show'
                                      }
                        }}
                    </button>
                </div>
            </div>
            <div class="flex flex-col items-center md:pt-8">
                <div class="sm:flex-1">
                    <mat-card>
                        <mat-card-content>
                            <ui-table
                                tableClass="stlc-dense-compact text-sm"
                                [columns]="summaryTableColumns"
                                [displayedColumns]="summaryTableDisplayedColumns"
                                [dataSource]="service.summaryTableDataSource$ | async"
                                [sortable]="false"
                                [showFooter]="false"
                                [rowClassFn]="summaryRowClassFn"
                            ></ui-table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="flex flex-row justify-center items-center gap-y-4 gap-x-8 flex-wrap pt-4">
            <ng-container *ngFor="let filter of service.filters$ | async; trackBy: trackById">
                <div *ngIf="filter.id === 'pitchType'">
                    <ng-container [ngSwitch]="filter.type">
                        <ui-chip-list
                            *ngSwitchCase="'list'"
                            [chipStyle]="filter.chipStyle"
                            [options]="filter.options"
                            [multiple]="filter.multiple"
                            [showCheck]="true"
                            (selectedChange)="handleFilterChange($event, filter.id)"
                        ></ui-chip-list>
                        <ui-chip-select
                            *ngSwitchCase="'select'"
                            [chipColor]="filter.chipColor"
                            [multiple]="filter.multiple"
                            [options]="filter.options"
                            [placeholder]="filter.header"
                            (selectedChange)="handleFilterChange($event, filter.id)"
                        ></ui-chip-select>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="flex flex-row justify-center items-center gap-y-4 gap-x-8 flex-wrap">
            <ng-container *ngFor="let filter of service.filters$ | async; trackBy: trackById">
                <div *ngIf="filter.id !== 'pitchType'">
                    <ng-container [ngSwitch]="filter.type">
                        <ui-chip-list
                            *ngSwitchCase="'list'"
                            [chipStyle]="filter.chipStyle"
                            [options]="filter.options"
                            [multiple]="filter.multiple"
                            [showCheck]="true"
                            (selectedChange)="handleFilterChange($event, filter.id)"
                        ></ui-chip-list>
                        <ui-chip-select
                            *ngSwitchCase="'select'"
                            [chipColor]="filter.chipColor"
                            [multiple]="filter.multiple"
                            [options]="filter.options"
                            [placeholder]="filter.header"
                            (selectedChange)="handleFilterChange($event, filter.id)"
                        ></ui-chip-select>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="flex justify-center">
            <button mat-button type="button" [disabled]="(service.filterCount$ | async) === 0" (click)="resetFilters()">
                {{ 'filters:resetFilters' | i18next }}
            </button>
        </div>
    </div>
    <div *ngIf="service.isVideoAvailable$ | async" class="stlc-fab-bottom sticky my-8">
        <button mat-fab extended color="primary" (click)="service.openVideoDialog()" type="button">
            <mat-icon>play_arrow</mat-icon>
            {{ 'common:watchVideo' | i18next }}
        </button>
    </div>
    <div class="flex flex-col space-y-4 max-w-screen-xl w-full my-8 mx-auto">
        <div class="flex-1 text-sm text-gray-400 text-center" *ngIf="service.showingCounts$ | async as counts">
            {{ 'filters:showing' | i18next : counts }}
        </div>
        <mat-card>
            <mat-card-content>
                <player-stats-pitches-table
                    seasonType="catching"
                    [pitches]="service.visiblePitches$ | async"
                    (videoSelect)="service.openVideoDialog($event)"
                ></player-stats-pitches-table>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>

<ng-template #tooltipTemplate let-item="item">
    <stlc-reviews-catching-tooltip *ngIf="item" [pitch]="item"></stlc-reviews-catching-tooltip>
</ng-template>
