import type { Spec } from 'vega';

import { rulebookZone } from '@stlc/vega/utils';

const binWidth = rulebookZone.width / 3 + 5 / 24;
const binHeight = (rulebookZone.y - rulebookZone.y2) / 3 + 3 / 24;

const rightBoundary = binWidth / 2;
const leftBoundary = -rightBoundary;
const topBoundary = (rulebookZone.y - rulebookZone.y2) / 2 + rulebookZone.y2 + binHeight / 2;
const bottomBoundary = topBoundary - binHeight;

const topY = topBoundary + binHeight / 2;
const middleY = bottomBoundary + (topBoundary - bottomBoundary) / 2;
const bottomY = bottomBoundary - binHeight / 2;
const rightX = rightBoundary + binWidth / 2;
const leftX = leftBoundary - binWidth / 2;
const middleX = 0;

export const catchingStrikeZoneFramingSpec: Spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 300,
    height: 400,
    autosize: { type: 'none', contains: 'padding' },
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainColor: '#aaaaaa',
            domainOpacity: 1,
            gridColor: '#dddddd',
            gridOpacity: 1,
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel'] } },
    },
    signals: [
        { name: 'xAxisDomain', value: [-2.25, 2.25] },
        { name: 'yAxisDomain', value: [-0.75, 5.25] },
        { name: 'xAxisField', value: 'plateSide' },
        { name: 'yAxisField', value: 'plateHeight' },
        { name: 'perspective', value: 'batter' },
        { name: 'zoneType', value: 'box' },
        { name: 'insufficientData', update: "length(data('data')) === 0" },
        { name: 'heatmapType', value: 'strikes-added' },
        { name: 'selectable', value: true },
        { name: 'brushable', value: false },
        { name: 'isInteractive', update: '!brushable && selectable' },
        { name: 'filteredIds', value: [] },
        { name: 'hoveredId', value: null },
        { name: 'selectedIds', value: null },
        { name: 'calledStrikeZoneCaption', value: null },
        { name: 'hasSelected', update: '(selectedIds && length(selectedIds)) || (brushedIds && length(brushedIds))' },
        {
            name: 'showHeatmapData',
            value: false,
            description: 'Show Heatmap data from outside the spec',
        },
        {
            name: 'toggleHeatmapData',
            value: false,
            update: '!heatmapType',
            description: 'Show Heatmap data from inside the spec',
            on: [
                // {
                //     events: [{ type: 'click', markname: 'pitchesText' }],
                //     update: '!showHeatmapData && (!heatmapType || !toggleHeatmapData)',
                // },
                {
                    events: { signal: 'showHeatmapData' },
                    update: 'false',
                },
            ],
        },
        {
            name: 'showData',
            update: 'showHeatmapData || toggleHeatmapData',
        },
        {
            name: 'dataCount',
            update: "length(data('dataAggregate')) == 1 ? data('dataAggregate')[0].count : null",
        },
        {
            name: 'visibleCount',
            update: "length(data('visibleData'))",
        },
        { name: 'showHeatmap', update: '!!heatmapType && !insufficientData && !showData' },
        { name: 'showPitchNumbers', value: true },
        { name: 'actualPitchSize', value: true },
        { name: 'minX', update: 'xAxisDomain[0]' },
        { name: 'maxX', update: 'xAxisDomain[1]' },
        {
            name: 'pitchSize',
            update: 'PI * pow((width / (maxX - minX)) * (0.125 / (showPitchNumbers || actualPitchSize ? 1 : 2)), 2)',
        },

        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && group().datum ? group().datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'group().datum ? group().datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'binSelected',
            init: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'binMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'binMarks',
                                    consume: true,
                                },
                            ],
                        },
                    ],
                    update: 'selectable && group() && group().datum && binSelected != group().datum ? group().datum : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: null,
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart', consume: true }],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                },
            ],
        },
        {
            name: 'brushEnd',
            value: {},
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : {}',
                },
            ],
        },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart', consume: true }],
                    update: '!selectable && brushable && !selectedId ? true : false',
                },
                { events: 'window:mouseup, window:touchend', update: 'false' },
            ],
        },
        {
            name: 'brushX',
            update: "brushStart ? extent(invert('x', [brushStart.x, brushEnd.x])) : []",
        },
        {
            name: 'brushY',
            update: "brushStart ? extent(invert('y', [brushStart.y, brushEnd.y])) : []",
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [{ type: 'mousedown' }, { type: 'touchstart', consume: true }],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null, react: true },
        { name: 'currentId', value: null },
        { name: 'leftX', value: leftX },
        { name: 'middleX', value: middleX },
        { name: 'rightX', value: rightX },
        { name: 'bottomY', value: bottomY },
        { name: 'middleY', value: middleY },
        { name: 'topY', value: topY },
        { name: 'binWidth', value: binWidth },
        { name: 'binHeight', value: binHeight },
        { name: 'textColor', value: 'white' },
        { name: 'fontSize', value: 14 },
        { name: 'fontWeight', value: '500' },
        { name: 'rightBoundary', value: rightBoundary },
        { name: 'leftBoundary', value: leftBoundary },
        { name: 'topBoundary', value: topBoundary },
        { name: 'bottomBoundary', value: bottomBoundary },
    ],
    data: [
        {
            name: 'data',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: 'isValid(datum.strikesAdded) && (!filteredIds || length(filteredIds) == 0 || indexof(filteredIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'aggregatedData',
            source: 'data',
            transform: [
                {
                    type: 'formula',
                    as: 'binId',
                    expr: "(datum[yAxisField] >= topBoundary ? 'top' : datum[yAxisField] <= bottomBoundary ? 'bottom' : 'middle') + (datum[xAxisField] <= leftBoundary ? 'Left' : datum[xAxisField] >= rightBoundary ? 'Right' : 'Middle')",
                },
                {
                    type: 'aggregate',
                    groupby: ['binId'],
                    fields: ['strikesAdded', 'id'],
                    ops: ['sum', 'values'],
                    as: ['sum', 'values'],
                },
            ],
        },
        {
            name: 'binData',
            values: [
                {
                    id: 'topLeft',
                    plateSide2: leftBoundary,
                    plateHeight: topBoundary,
                },
                {
                    id: 'topMiddle',
                    plateSide: leftBoundary,
                    plateSide2: rightBoundary,
                    plateHeight: topBoundary,
                },
                {
                    id: 'topRight',
                    plateSide: rightBoundary,
                    plateHeight: topBoundary,
                },
                {
                    id: 'middleLeft',
                    plateSide2: leftBoundary,
                    plateHeight: bottomBoundary,
                    plateHeight2: topBoundary,
                },
                {
                    id: 'middleMiddle',
                    plateSide: leftBoundary,
                    plateSide2: rightBoundary,
                    plateHeight: bottomBoundary,
                    plateHeight2: topBoundary,
                },
                {
                    id: 'middleRight',
                    plateSide: rightBoundary,
                    plateHeight: bottomBoundary,
                    plateHeight2: topBoundary,
                },
                {
                    id: 'bottomLeft',
                    plateSide2: leftBoundary,
                    plateHeight2: bottomBoundary,
                },
                {
                    id: 'bottomMiddle',
                    plateSide: leftBoundary,
                    plateSide2: rightBoundary,
                    plateHeight2: bottomBoundary,
                },
                {
                    id: 'bottomRight',
                    plateSide: rightBoundary,
                    plateHeight2: bottomBoundary,
                },
            ],
            transform: [
                {
                    type: 'formula',
                    as: 'x',
                    expr: '(isValid(datum.plateSide) ? datum.plateSide : datum.plateSide2 - binWidth) - (indexof(datum.id, "Left") > 0 ? 0 : 0)',
                },
                {
                    type: 'formula',
                    as: 'y',
                    expr: '(isValid(datum.plateHeight) ? datum.plateHeight : datum.plateHeight2 - binHeight) - (indexof(datum.id, "bottom") == 0 ? 1 / 24 : 0)',
                },
                {
                    type: 'formula',
                    as: 'x2',
                    expr: '(isValid(datum.plateSide2) ? datum.plateSide2 : datum.plateSide + binWidth) + (indexof(datum.id, "Right") > 0 ? 0 : 0)',
                },
                {
                    type: 'formula',
                    as: 'y2',
                    expr: '(isValid(datum.plateHeight2) ? datum.plateHeight2 : datum.plateHeight + binHeight) + (indexof(datum.id, "top") == 0 ? 1 / 24 : 0)',
                },
                {
                    type: 'lookup',
                    from: 'aggregatedData',
                    key: 'binId',
                    fields: ['id'],
                    values: ['sum', 'values'],
                    as: ['sum', 'values'],
                    default: {},
                },
                {
                    type: 'filter',
                    expr: "showHeatmap && (datum.id != 'middleMiddle' || round(datum.sum * 10) / 10 != 0)",
                },
            ],
        },
        {
            name: 'selectedBinIds',
            source: 'binData',
            transform: [
                {
                    type: 'flatten',
                    fields: ['values'],
                    as: ['value'],
                },
                {
                    type: 'project',
                    fields: ['id', 'value.id'],
                    as: ['binId', 'id'],
                },
                {
                    type: 'filter',
                    expr: 'binSelected && binSelected.id == datum.binId',
                },
            ],
        },
        {
            name: 'dataAggregate',
            source: 'data',
            transform: [
                {
                    type: 'aggregate',
                    fields: [{ signal: 'yAxisField' }],
                    ops: ['count'],
                    as: ['count'],
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '((binSelected && indata("selectedBinIds", "id", datum.id)) || (!binSelected && !showHeatmap)) && isValid(datum.strikesAdded && (!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0)))',
                    // expr: '(!filteredIds || (filteredIds && length(filteredIds) > 0 && indexof(filteredIds, datum.id) >= 0))',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    // expr: '!showHeatmap && filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1)',
                    expr: '!binSelected && !showHeatmap && (filteredIds && (length(filteredIds) == 0 || indexof(filteredIds, datum.id) == -1))',
                },
            ],
        },
        {
            name: 'homePlateData',
            values: [
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.70833, y: 0 },
                { perspective: 'pitcher', x: 0.694167, y: 0.08 },
                { perspective: 'pitcher', x: 0, y: 0.18 },
                { perspective: 'pitcher', x: -0.694167, y: 0.08 },
                { perspective: 'pitcher', x: -0.70833, y: 0 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.70833, y: 0.18 },
                { perspective: 'batter', x: 0.694167, y: 0.1 },
                { perspective: 'batter', x: 0, y: 0 },
                { perspective: 'batter', x: -0.694167, y: 0.1 },
                { perspective: 'batter', x: -0.70833, y: 0.18 },
            ],
            transform: [{ type: 'filter', expr: 'perspective === datum.perspective' }],
        },
        {
            name: 'strikeZoneBorderPoints',
            values: [],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: 'isDefined(datum[xAxisField]) && isDefined(datum[yAxisField])',
                },
                {
                    type: 'filter',
                    expr: 'inrange(datum[xAxisField], brushX) && inrange(datum[yAxisField], brushY)',
                },
            ],
        },
    ],
    marks: [
        {
            type: 'group',
            from: { data: 'binData' },
            marks: [
                {
                    type: 'rect',
                    name: 'binMarks',
                    interactive: true,
                    encode: {
                        update: {
                            x: { field: { parent: 'x' }, scale: 'x' },
                            x2: { field: { parent: 'x2' }, scale: 'x' },
                            y: { field: { parent: 'y' }, scale: 'y' },
                            y2: { field: { parent: 'y2' }, scale: 'y' },
                            fill: { scale: 'strikesAddedBinColor', field: { parent: 'sum' } },
                            cursor: { signal: "selectable ? 'pointer' : 'default'" },
                            stroke: {
                                signal: "binSelected && binSelected.id == parent.id ? scale('strikesAddedBinStrokeColor', round(parent.sum * 10) / 10) : 'transparent'",
                            },
                            opacity: { signal: 'binSelected && binSelected.id != parent.id ? 0.2 : 1' },
                        },
                    },
                },
                {
                    type: 'text',
                    interactive: false,
                    encode: {
                        enter: {
                            align: { value: 'center' },
                            baseline: { value: 'middle' },
                            fillOpacity: { value: 1 },
                        },
                        update: {
                            x: { signal: 'parent.x + (parent.x2 - parent.x) / 2', scale: 'x' },
                            y: { signal: 'parent.y2 - (parent.y2 - parent.y) / 2', scale: 'y' },
                            fill: { scale: 'strikesAddedBinTextColor', signal: 'round(parent.sum * 10) / 10' },
                            opacity: { signal: 'binSelected && binSelected.id != parent.id ? 0.5 : 1' },
                            text: { signal: 'stlcNumberToFixed(parent.sum, { digits: 1, showPosSign: true })' },
                            fontSize: { signal: 'fontSize' },
                            fontWeight: { signal: 'fontWeight' },
                        },
                    },
                },
            ],
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -(rulebookZone.width / 2 / 3), scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.2 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.2 : 0",
                    },
                    opacity: { signal: 'showHeatmap ? 0.5 : 1' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: rulebookZone.width / 2 / 3, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.2 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.2 : 0",
                    },
                    opacity: { signal: 'showHeatmap ? 0.5 : 1' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -(rulebookZone.width / 2), scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: 2.8, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.2 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.2 : 0",
                    },
                    opacity: { signal: 'showHeatmap ? 0.5 : 1' },
                },
            },
        },
        {
            type: 'rule',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -(rulebookZone.width / 2), scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: 2.2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.2 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType == '9-box' ? 0.2 : 0",
                    },
                    opacity: { signal: 'showHeatmap ? 0.5 : 1' },
                },
            },
        },
        {
            type: 'line',
            interactive: false,
            from: { data: 'homePlateData' },
            encode: {
                enter: {
                    strokeWidth: { value: 1 },
                    strokeCap: { value: 'square' },
                    fillOpacity: { value: 0.033 },
                    strokeOpacity: { value: 0.2 },
                },
                update: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    fill: { value: '#000000' },
                    stroke: { value: '#000000' },
                },
            },
        },
        {
            type: 'text',
            name: 'pitchesText',
            from: { data: 'dataAggregate' },
            interactive: false,
            encode: {
                enter: {
                    y: { signal: 'height', offset: { value: -25 } },
                    x: { signal: 'width / 2' },
                    align: { value: 'center' },
                    baseline: { value: 'middle' },
                    fillOpacity: { value: 0.65 },
                },
                update: {
                    // cursor: { signal: "!!heatmapType ? 'pointer' : 'default'" },
                    fill: {
                        signal: "!showData && !insufficientData && (heatmapType == 'run-value-density') ? 'white' : '#808080'",
                    },
                    text: {
                        signal: "(visibleCount > 0 && visibleCount !== datum.count ? stlcTranslate('playerCharts:selectedXOfY', { defaultValue: 'Selected {{ x }} of {{ y }}', x: visibleCount, y: datum.count }) : stlcTranslate('stat:numberOfPitches_count', { count: datum.count }))",
                    },
                },
            },
        },
        {
            type: 'line',
            from: { data: 'strikeZoneBorderPoints' },
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#000' },
                    strokeWidth: { value: 2 },
                    stroke: { value: 'black' },
                    interpolate: { value: 'natural' },
                },
                update: {
                    x: { scale: 'x', field: 'x' },
                    y: { scale: 'y', field: 'y' },
                    strokeOpacity: { signal: 'showHeatmap ? 0 : 0.2' },
                    fillOpacity: {
                        signal: '!showHeatmap ? 0.05 : 0',
                    },
                },
            },
        },
        {
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    x: { value: -(rulebookZone.width / 2), scale: 'x' },
                    x2: { value: rulebookZone.width / 2, scale: 'x' },
                    y: { value: rulebookZone.y, scale: 'y' },
                    y2: { value: rulebookZone.y2, scale: 'y' },
                    stroke: { value: '#000' },
                    strokeOpacity: { value: 0.2 },
                    strokeWidth: { value: 1.25 },
                    strokeDash: { value: [6, 6] },
                },
                update: {
                    strokeOpacity: {
                        signal: "zoneType != 'none' ? 0.2 : 0",
                    },
                    opacity: { signal: 'showHeatmap ? 0.5 : 1' },
                },
            },
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#cccccc' },
                    fillOpacity: { value: 0.1 },
                },
                update: {
                    x: { scale: 'x', field: 'plateSide' },
                    y: { scale: 'y', field: 'plateHeight' },
                    size: { signal: 'pitchSize' },
                },
            },
        },
        {
            type: 'group',
            from: { data: 'visibleData' },
            encode: {
                update: {
                    zindex: {
                        signal: 'currentId && currentId == datum.id ? 2 : hoveredId && hoveredId == datum.id ? 1 : 0',
                    },
                },
            },
            signals: [
                {
                    name: 'isBrushedOrSelected',
                    update: '(brushedIds && indexof(brushedIds, parent.id) !== -1) || (selectedIds && indexof(selectedIds, parent.id) !== -1)',
                },
                { name: 'isHidden', update: '(brushedIds || selectedIds) && !isBrushedOrSelected' },
                {
                    name: 'isSelectable',
                    update: 'selectable && !isHidden && isArray(parent.videos) && length(parent.videos) > 0',
                },
            ],
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            fill: { value: 'white' },
                            strokeOpacity: { value: 1 },
                        },
                        update: {
                            x: { scale: 'x', field: { parent: 'plateSide' } },
                            y: { scale: 'y', field: { parent: 'plateHeight' } },
                            size: {
                                signal: 'pow((sqrt(pitchSize / PI) + 3), 2) * PI',
                            },
                            stroke: { scale: 'strikesAddedPitchColor', field: { parent: 'strikesAdded' } },
                            strokeWidth: {
                                signal: 'min(max(1, pow((sqrt(pitchSize / PI) + 3), 2) * PI / 32), 2)',
                            },
                            opacity: {
                                signal: '(currentId && parent.id == currentId) || (hoveredId && hoveredId == parent.id) ? 1 : 0',
                            },
                        },
                    },
                },
                {
                    name: 'visibleMarks',
                    type: 'symbol',
                    interactive: { signal: '(!showHeatmap || binSelected) && isInteractive && !isHidden' },
                    encode: {
                        enter: {
                            strokeWidth: { value: 1 },
                        },
                        update: {
                            x: { scale: 'x', field: { parent: 'plateSide' } },
                            y: { scale: 'y', field: { parent: 'plateHeight' } },
                            size: { signal: 'pitchSize' },
                            fill: {
                                signal: "isHidden ? '#737373' : scale('strikesAddedPitchColor', parent.strikesAdded)",
                            },
                            fillOpacity: { signal: 'isHidden ? 0.2 : (hoveredId && hoveredId == parent.id) ? 1 : 0.5' },
                            stroke: { scale: 'strikesAddedPitchStrokeColor', field: { parent: 'strikesAdded' } },
                            strokeOpacity: { signal: 'isBrushedOrSelected ? 1 : 0.75' },
                            strokeWidth: { signal: 'isBrushedOrSelected ? 1 : 0' },
                            tooltip: { signal: 'isInteractive && !isHidden ? parent : null' },
                            cursor: { signal: "isSelectable ? 'pointer' : 'default'" },
                            opacity: {
                                signal: '!showHeatmap || binSelected ? 1 : 0',
                            },
                        },
                    },
                },
            ],
        },
        {
            type: 'text',
            interactive: false,
            encode: {
                enter: {
                    align: { value: 'center' },
                    baseline: { value: 'middle' },
                },
                update: {
                    y: { signal: 'height', offset: { value: -5 } },
                    x: { signal: 'width / 2' },
                    fill: {
                        signal: "!showData && !insufficientData && (heatmapType == 'run-value-density') ? 'white' : '#808080'",
                    },
                    fillOpacity: [{ test: 'showHeatmap', value: 0 }, { value: 0.65 }],
                    text: {
                        signal: 'calledStrikeZoneCaption',
                    },
                },
            },
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart ? brushStart.x : null' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart ? brushStart.y : null' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: { signal: 'xAxisDomain' },
            range: 'width',
            clamp: true,
            reverse: { signal: "perspective === 'batter'" },
        },
        {
            name: 'y',
            type: 'linear',
            domain: { signal: 'yAxisDomain' },
            range: 'height',
            zero: false,
            clamp: true,
        },
        {
            name: 'strikesAddedPitchColor',
            type: 'linear',
            domain: [-0.8, -0.1, 0.1, 0.8],
            range: ['#2166ac', '#d1e5f0', '#fddbc7', '#b2182b'],
        },
        {
            name: 'strikesAddedPitchStrokeColor',
            type: 'linear',
            domain: [-0.6, -0.01, 0.01, 0.6],
            range: ['#2166ac', '#d1e5f0', '#fddbc7', '#b2182b'],
        },
        {
            name: 'strikesAddedBinColor',
            type: 'linear',
            domain: [-2, 2],
            range: { scheme: 'redblue' },
            reverse: true,
            clamp: true,
        },
        {
            name: 'strikesAddedBinTextColor',
            type: 'linear',
            domain: [-0.7, -0.6, 0, 0.6, 0.7],
            range: ['#ffffff', '#d45f52', '#999999', '#4e94c3', '#ffffff'],
            reverse: true,
            clamp: true,
        },
        {
            name: 'strikesAddedBinStrokeColor',
            type: 'linear',
            domain: [-1, -0.7, 0, 0.7, 1],
            range: ['#333333', '#d45f52', '#999999', '#4e94c3', '#333333'],
            reverse: true,
            clamp: true,
        },
    ],
};
