import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import type { Spec } from 'vega';

import { GoogleAnalyticsService } from '@stlc/angular/services';
import { RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import {
    chain,
    filter as _filter,
    find,
    includes,
    isArray,
    isEmpty,
    isEqual,
    isNil,
    map as _map,
    set,
    some,
    transform,
} from '@stlc/lodash';
import { PlayerStatsPitchesTableComponent } from '@stlc/player-stats/ui';
import { ReviewsCatchingTooltipComponent } from '@stlc/reviews/ui';
import { StlcSelectOption } from '@stlc/shared';
import { UiChipListComponent, UiChipSelectComponent } from '@stlc/ui';
import { UiAppService } from '@stlc/ui/services';
import { UiTableDataSource } from '@stlc/ui/table';
import { UiVegaBrushingService, UiVegaChartComponent } from '@stlc/ui/vega';

import { ReviewsService } from '../../services/reviews.service';
import { ReviewsCatchingService } from '../../services/reviews-catching.service';
import { catchingGamePitchesSpec } from '../../vega/catching-game-pitches-spec.vega';
import { catchingStrikeZoneSpec } from '../../vega/catching-strike-zone-spec.vega';

@Component({
    selector: 'stlc-reviews-catching-pitches',
    templateUrl: './reviews-catching-pitches.component.html',
    styleUrls: ['./reviews-catching-pitches.component.scss'],
    providers: [UiVegaBrushingService],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        UiVegaChartComponent,
        UiChipSelectComponent,
        UiChipListComponent,
        StlcI18nModule,
        PlayerStatsPitchesTableComponent,
        ReviewsCatchingTooltipComponent,
    ],
})
export class ReviewsCatchingPitchesComponent implements OnInit, OnDestroy {
    readonly visiblePitches$: Observable<RbdReviewPitchFragment[]>;
    readonly filteredPitchesDataSource$: Observable<UiTableDataSource<RbdReviewPitchFragment>>;
    readonly showingCounts$: Observable<{ value: number; total: number }>;
    readonly filterCount$: Observable<number>;

    gamePitchesSpec: Spec = catchingGamePitchesSpec;
    strikeZoneSpec: Spec = catchingStrikeZoneSpec;

    gamePitchesSignals: { [key: string]: any } = {
        yAxis: {
            field: 'releaseSpeed',
            title: 'Velocity (MPH)',
            suffix: 'MPH',
            label: 'Velocity',
            domainMin: 70,
            domainMax: 100,
            tickMinStep: 5,
            clamp: true,
            zero: false,
            grid: true,
            sizeField: 'pitches',
        },
    };

    // pitch locations chart
    pitchLocationsSignals: { [key: string]: any } = {
        perspective: 'pitcher',
        showPitchNumbers: false,
    };

    filters: Array<{ [key: string]: any }>;
    chartBrushable = false;
    chartSelectable = true;
    loading = false;

    private readonly destroy = new Subject<void>();

    appService = inject(UiAppService);
    reviewsCatchingService = inject(ReviewsCatchingService);
    private reviewsService = inject(ReviewsService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private brushingService = inject(UiVegaBrushingService);
    private readonly gaService = inject(GoogleAnalyticsService);

    constructor() {
        this.visiblePitches$ = combineLatest([
            this.reviewsCatchingService.visiblePitches$,
            this.brushingService.selectedIds$,
        ]).pipe(map(([pitches, ids]) => (isEmpty(ids) ? pitches : _filter(pitches, ({ id }) => includes(ids, id)))));

        this.filteredPitchesDataSource$ = this.visiblePitches$.pipe(
            map((pitches) => new UiTableDataSource<RbdReviewPitchFragment>(pitches))
        );

        const pitchesCount$ = this.reviewsCatchingService.pitches$.pipe(map((pitches) => pitches.length));
        const visiblePitchCount$ = this.filteredPitchesDataSource$.pipe(map((pitches) => pitches.data?.length ?? 0));

        this.showingCounts$ = combineLatest([visiblePitchCount$, pitchesCount$]).pipe(
            map(([value, total]) => ({ value, total })),
            shareReplay(1)
        );
        this.filterCount$ = this.showingCounts$.pipe(
            map(({ value, total }) => total - value),
            shareReplay(1)
        );
    }

    ngOnInit() {
        this.reviewsService.selectedTab = 'pitches';

        this.route.queryParams
            .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy))
            .subscribe((datum) => (this.reviewsCatchingService.queryParams = datum));

        this.reviewsCatchingService.pitchTypes$.pipe(takeUntil(this.destroy)).subscribe((pitchTypes) => {
            if (this.gamePitchesSignals) {
                this.gamePitchesSignals['pitchTypes'] = pitchTypes;
            }
        });

        this.reviewsCatchingService.filteredIds$.pipe(takeUntil(this.destroy)).subscribe((filteredIds) => {
            if (this.gamePitchesSignals) {
                this.gamePitchesSignals = {
                    ...this.gamePitchesSignals,
                    filteredIds,
                };
            }

            this.pitchLocationsSignals = {
                ...this.pitchLocationsSignals,
                filteredIds,
            };
        });

        this.reviewsCatchingService.filterOptions$.pipe(takeUntil(this.destroy)).subscribe((filters) => {
            this.filters = filters;
        });

        this.brushingService.selectedId$
            .pipe(
                takeUntil(this.destroy),
                filter((selectedId) => !isNil(selectedId)),
                withLatestFrom(this.reviewsCatchingService.visiblePitches$),
                map(([selectedId, visiblePitches]) => find(visiblePitches, ({ id }) => id === selectedId)),
                filter((pitch) => !isNil(pitch) && pitch.videos.length > 0)
            )
            .subscribe((pitch) => {
                this.reviewsCatchingService.openVideoDialog(pitch?.id);
            });
    }

    ngOnDestroy() {
        this.destroy.next();
    }

    onFilterChange(
        options: StlcSelectOption<unknown>[] | StlcSelectOption<unknown> | undefined,
        filterId: string
    ): void {
        let values = [];
        if (isNil(options)) {
            values = [];
        } else if (isArray(options)) {
            values = _map(options, 'value');
        } else {
            values = [options.value];
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: transform(
                this.filters,
                (result, datum) => {
                    if (datum['id'] === filterId) {
                        const value = chain(datum['options'])
                            .filter(
                                (item) =>
                                    (item.selected && some(values, (value) => isEqual(value, item.value))) ||
                                    (!item.selected && some(values, (value) => isEqual(value, item.value)))
                            )
                            .map((option) => option.id || option.value)
                            .valueOf();
                        set(result, datum['id'], !isEmpty(value) ? value : null);
                    }
                },
                {}
            ),
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    resetFilters() {
        this.brushingService.clearSelected();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: transform(
                this.filters,
                (result, datum) => {
                    set(result, datum['id'], null);
                },
                {}
            ),
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    trackById(_index: number, item: any) {
        return item.id;
    }

    openVideoDialog(pitch?: RbdReviewPitchFragment) {
        this.reviewsCatchingService.openVideoDialog(pitch?.id, this.visiblePitches$);
    }

    clearChartSelected(_event?: MouseEvent): void {
        this.brushingService.clearSelected();
        this.chartBrushable = false;
        this.chartSelectable = true;
        this.brushingService.signal('selectable', this.chartSelectable);
        this.brushingService.signal('brushable', this.chartBrushable);
    }

    toggleChartBrushable(event?: MouseEvent): void {
        this.chartBrushable = !this.chartBrushable;
        this.chartSelectable = !this.chartBrushable;
        setTimeout(() => {
            this.brushingService.signal('selectable', this.chartSelectable);
            this.brushingService.signal('brushable', this.chartBrushable);
        }, 250);
        if (event) {
            this.gaService.event('toggle_brushing', {
                value: this.chartBrushable,
            });
        }
    }
}
