import type { Route } from '@angular/router';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewsPitchingComponent } from './components/reviews-pitching/reviews-pitching.component';
import { ReviewsPitchingAtBatsComponent } from './components/reviews-pitching-at-bats/reviews-pitching-at-bats.component';
import { ReviewsPitchingPitchesComponent } from './components/reviews-pitching-pitches/reviews-pitching-pitches.component';
import { ReviewsPitchingSummaryComponent } from './components/reviews-pitching-summary/reviews-pitching-summary.component';
import { resolvePlayer } from './resolves/player.resolver';
import { reviewsGameYearsResolver } from './resolves/review-game-years.resolver';
import { reviewsPitchingLoadDataResolver } from './resolves/reviews-pitching-load-data.resolver';

export const pitchingRoutes: Route[] = [
    {
        path: '',
        component: ReviewsComponent,
        data: {
            i18nextNamespaces: [
                'chart',
                'common',
                'filters',
                'glossary',
                'game',
                'pitchType',
                'player',
                'playerCharts',
                'reviews',
                'stat',
                'video',
            ],
            reviewType: 'pitching',
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
            gameYears: reviewsGameYearsResolver,
        },
        children: [
            {
                path: '',
                component: ReviewsPitchingComponent,
                resolve: {
                    data: reviewsPitchingLoadDataResolver,
                },
                children: [
                    {
                        path: 'at-bats',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsPitchingAtBatsComponent,
                    },
                    {
                        path: 'summary',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsPitchingSummaryComponent,
                    },
                    {
                        path: 'pitches',
                        data: {
                            reuseRoute: true,
                        },
                        resolve: {
                            player: resolvePlayer,
                        },
                        component: ReviewsPitchingPitchesComponent,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'summary',
                    },
                ],
            },
        ],
    },
];
