import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import { distinctUntilChanged, filter, map, Subject, takeUntil } from 'rxjs';

import { StlcI18nModule } from '@stlc/i18n/core';
import { find, head, isEqual, last, map as _map } from '@stlc/lodash';
import { StlcSelectOption } from '@stlc/shared';
import { UiAppService, UiHeaderService } from '@stlc/ui/services';

import { ReviewsService } from '../../services/reviews.service';
import { ReviewsCatchingService } from '../../services/reviews-catching.service';

@Component({
    selector: 'stlc-reviews-catching',
    templateUrl: './reviews-catching.component.html',
    styleUrls: ['./reviews-catching.component.scss'],
    providers: [ReviewsCatchingService],
    standalone: true,
    imports: [CommonModule, MatTabsModule, RouterModule, StlcI18nModule],
})
export class ReviewsCatchingComponent implements OnInit, OnDestroy {
    private readonly appService = inject(UiAppService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    readonly i18next = inject(I18NEXT_SERVICE, { optional: true });
    readonly headerService = inject(UiHeaderService);
    readonly reviewsService = inject(ReviewsService);
    readonly reviewsCatchingService = inject(ReviewsCatchingService);

    readonly tabs: StlcSelectOption[] = [
        {
            text: 'Summary',
            textKey: 'reviews:tab.summary',
            value: 'summary',
        },
        {
            text: 'Framing',
            textKey: 'reviews:tab.framing',
            value: 'framing',
        },
        {
            text: 'At Bats',
            textKey: 'reviews:tab.atBats',
            value: 'at-bats',
        },
        {
            text: 'Pitches',
            textKey: 'reviews:tab.pitches',
            value: 'pitches',
        },
    ];

    private readonly destroy = new Subject<void>();

    constructor() {
        this.route.queryParams
            .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy))
            .subscribe((datum) => (this.reviewsCatchingService.queryParams = datum));

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(
                    ({ urlAfterRedirects }: NavigationEnd) =>
                        new URL(urlAfterRedirects, location as unknown as URL).pathname
                ),
                distinctUntilChanged(),
                takeUntil(this.destroy)
            )
            .subscribe((path) => {
                const value = last(path.split('/'));
                const tab = find(this.tabs, { value }) || head(this.tabs);
                this.reviewsService.selectedTab = tab.value;
            });

        this.appService.subtitles = _map(
            [
                { defaultValue: this.reviewsCatchingService.startDate },
                { key: 'common:catching', defaultValue: 'Catching' },
                { key: 'reviews:title', defaultValue: 'Game Reviews' },
            ],
            ({ key, defaultValue }) => (this.i18next && key ? this.i18next.t(key, { defaultValue }) : defaultValue)
        );
    }

    ngOnInit(): void {
        this.reviewsService.selectedReviewType = 'catching';
    }

    ngOnDestroy(): void {
        this.appService.subtitles = [];
        this.reviewsCatchingService.queryParams = {};
        this.destroy.next();
        this.headerService.resetNavRight();
    }

    onTabChange(value: string): void {
        this.router.navigate([value], { relativeTo: this.route, queryParamsHandling: 'merge' });
    }

    trackByValue(tab: StlcSelectOption) {
        return tab.value;
    }
}
