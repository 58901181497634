<div class="flex flex-col space-y-4">
    <ng-container *ngFor="let inning of reviewsPitchingAtBatsService.innings$ | async; let $first = first">
        <div *ngIf="!$first" class="max-w-screen-lg w-4/5 mx-auto pt-4">
            <mat-divider></mat-divider>
        </div>

        <h2 class="text-xl mt-4 mx-auto">
            {{ inning.isTopInning ? 'Top' : 'Bottom' }} {{ inning.inning | stlcNumberToOrdinal }}
        </h2>
        <div class="scroll-snap-x-container flex flex-wrap justify-center relative gap-2 mt-2 py-1 md:gap-4 md:px-8">
            <ng-container *ngFor="let atBat of inning?.plateAppearances">
                <mat-card class="scroll-snap-item w-40">
                    <mat-card-content class="flex flex-col items-center justify-items-center">
                        <span class="text-sm" *ngFor="let title of atBat.titles">{{ title }}</span>
                        <ui-vega-chart
                            renderer="canvas"
                            [spec]="strikeZoneSpec"
                            [data]="atBat.pitches"
                            [tooltipTemplate]="tooltipTemplate"
                            [signals]="signals"
                            [signalListeners]="signalListeners"
                        ></ui-vega-chart>
                        <button
                            *ngIf="atBat.isVideoAvailable"
                            mat-stroked-button
                            color="primary"
                            class="mb-2"
                            (click)="openVideoDialogForAtBat(atBat)"
                        >
                            {{ 'common:watchVideo' | i18next }}
                        </button>
                        <div class="w-full max-w-xs text-sm">
                            <table class="w-full">
                                <tbody>
                                    <tr *ngFor="let pitch of atBat.pitches">
                                        <td class="text-center">{{ pitch.atbatPitchNumber }}</td>
                                        <td class="text-center">{{ pitch.preBalls }}-{{ pitch.preStrikes }}</td>
                                        <td class="text-center">{{ pitch.pitchType | stlcPitchType: 'abbrev' }}</td>
                                        <td class="text-center">
                                            {{
                                                pitch.releaseSpeed | stlcNumberToFixed: { digits: 0, defaultText: '-' }
                                            }}
                                        </td>
                                        <td class="text-left">
                                            {{ { pitchResult: pitch.pitchResult } | stlcI18nGameEvent }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span
                            class="stlc-text-muted text-center text-sm"
                            [ngClass]="{ 'border-t border-gray-50 mt-2 pt-2': !!atBat.battedBallResult }"
                            [innerHTML]="atBat.battedBallResult"
                        ></span>
                    </mat-card-content>
                </mat-card>
            </ng-container>
        </div>
    </ng-container>
</div>

<div *ngIf="reviewsPitchingAtBatsService.isVideoAvailable$ | async" class="stlc-fab-bottom my-4 sticky">
    <button mat-fab extended color="primary" (click)="openVideoDialog()" type="button">
        <mat-icon>play_arrow</mat-icon>
        {{ 'common:watchVideo' | i18next }}
    </button>
</div>

<ng-template #tooltipTemplate let-item="item">
    <stlc-reviews-pitching-tooltip *ngIf="item" [pitch]="item"></stlc-reviews-pitching-tooltip>
</ng-template>
