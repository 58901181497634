import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes';
import { RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import { forEach } from '@stlc/lodash';

import { isBasicCaughtStealing, isBasicSbAttempt } from '../../services/events';

@Component({
    selector: 'stlc-reviews-catching-summary-metrics',
    templateUrl: './reviews-catching-framing-summary-metrics.component.html',
    styleUrls: ['./reviews-catching-framing-summary-metrics.component.scss'],
    standalone: true,
    imports: [CommonModule, StlcNumberToFixedPipe],
})
export class ReviewsCatchingFramingSummaryMetricsComponent implements OnChanges {
    @Input()
    isVerified = false;

    @Input()
    pitches: RbdReviewPitchFragment[] = [];

    calledStrikes = 0;
    balls = 0;
    strikesAdded = 0;
    caughtStealing = 0;
    stolenBaseAttempts = 0;
    passedBalls = 0;
    wildPitches = 0;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pitches']) {
            this.calledStrikes = 0;
            this.balls = 0;
            this.strikesAdded = 0;
            this.caughtStealing = 0;
            this.stolenBaseAttempts = 0;
            this.passedBalls = 0;
            this.wildPitches = 0;
            forEach(this.pitches, (datum) => {
                this.strikesAdded += datum.strikesAdded;

                if (datum.pitchResult === 'B') {
                    this.balls++;
                }

                if (datum.pitchResult === 'SC') {
                    this.calledStrikes++;
                }

                this.stolenBaseAttempts += isBasicSbAttempt(datum) ? 1 : 0;
                this.caughtStealing += isBasicCaughtStealing(datum) ? 1 : 0;
                this.passedBalls += datum.eventType === 'passed_ball' ? 1 : 0;
                this.wildPitches += datum.eventType === 'wild_pitch' ? 1 : 0;
            });
        }
    }
}
