<div class="relative">
    <div class="flex justify-end sticky top-16 z-100 h-4">
        <div class="absolute">
            <ng-template *ngTemplateOutlet="brushingButtonsTemplate"></ng-template>
        </div>
    </div>
    <div class="pt-4 flex flex-col md:flex-row space-x-0 px-2 space-y-4 md:space-x-2 md:px-4 md:space-y-0">
        <div class="md:flex-1">
            <ui-vega-chart
                renderer="canvas"
                [spec]="gamePitchesSpec"
                [data]="reviewsCatchingService.chartPitches$ | async"
                [signals]="gamePitchesSignals"
                [tooltipTemplate]="tooltipTemplate"
                [enableBrushing]="chartBrushable"
            />
        </div>
        <div class="flex flex-col justify-center items-center">
            <ui-vega-chart
                renderer="canvas"
                [spec]="strikeZoneSpec"
                [data]="reviewsCatchingService.chartPitches$ | async"
                [tooltipTemplate]="tooltipTemplate"
                [signals]="pitchLocationsSignals"
                [enableBrushing]="chartBrushable"
            />
        </div>
    </div>
</div>

<div class="flex flex-col gap-6 items-center">
    <div class="flex flex-row gap-6 flex-wrap justify-center">
        <ng-container *ngFor="let filter of reviewsCatchingService.filterOptions$ | async; let index = index">
            <ui-chip-list
                *ngIf="index < 2"
                [chipStyle]="filter.id === 'pitchType' ? 'checkbox' : 'none'"
                [options]="filter.options"
                (selectedChange)="onFilterChange($event, filter.id)"
                [multiple]="index === 0"
                [showCheck]="true"
            ></ui-chip-list>
        </ng-container>
    </div>
    <div class="flex flex-row gap-6 flex-wrap justify-center">
        <ng-container
            *ngFor="let filter of reviewsCatchingService.filterOptions$ | async; let index = index; trackBy: trackById"
        >
            <ui-chip-select
                *ngIf="
                    index > 1 &&
                    index < 6 &&
                    (index !== 5 || (reviewsCatchingService.runnersInformationAvailable$ | async) === true)
                "
                chipColor="primary"
                [multiple]="true"
                [options]="filter.options"
                (selectedChange)="onFilterChange($event, filter.id)"
                [placeholder]="filter.label | i18next"
            ></ui-chip-select>
        </ng-container>
    </div>
    <div
        class="flex flex-row justify-center"
        *ngIf="(reviewsCatchingService.hasEdgertronic$ | async) || (reviewsCatchingService.hasBallsInDirt$ | async)"
    >
        <ng-container *ngFor="let filter of reviewsCatchingService.filterOptions$ | async; let index = index">
            <ui-chip-list
                *ngIf="index === 6"
                chipStyle="none"
                [options]="filter.options"
                (selectedChange)="onFilterChange($event, filter.id)"
                [multiple]="false"
                [showCheck]="true"
            ></ui-chip-list>
        </ng-container>
    </div>
</div>
<div class="flex justify-center mt-8">
    <button mat-button type="button" [disabled]="(filterCount$ | async) === 0" (click)="resetFilters()">
        {{ 'filters:resetFilters' | i18next }}
    </button>
</div>
<div *ngIf="reviewsCatchingService.isVideoAvailable$ | async" class="stlc-fab-bottom sticky mt-8">
    <button mat-fab extended color="primary" (click)="openVideoDialog()" type="button">
        <mat-icon>play_arrow</mat-icon>
        {{ 'common:watchVideo' | i18next }}
    </button>
</div>
<div class="flex flex-col space-y-4 max-w-screen-xl w-full mt-8 mx-auto">
    <div class="flex-1 text-sm text-gray-400 text-center" *ngIf="showingCounts$ | async as counts">
        {{ 'filters:showing' | i18next : counts }}
    </div>
    <mat-card>
        <mat-card-content>
            <player-stats-pitches-table
                seasonType="catching"
                [pitches]="visiblePitches$ | async"
                (videoSelect)="openVideoDialog($event)"
            ></player-stats-pitches-table>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #tooltipTemplate let-item="item">
    <stlc-reviews-catching-tooltip *ngIf="item" [pitch]="item"></stlc-reviews-catching-tooltip>
</ng-template>

<ng-template #brushingButtonsTemplate>
    <div class="flex space-x-4 pr-4">
        <button
            *ngIf="brushingService.hasSelection$ | async"
            mat-mini-fab
            type="button"
            color="default"
            [disabled]="loading"
            (click)="clearChartSelected($event)"
            title="Clear Selection"
        >
            <mat-icon>clear</mat-icon>
        </button>
        <button
            mat-mini-fab
            color="default"
            [color]="chartBrushable ? 'primary' : 'default'"
            [attr.title]="chartBrushable ? 'Cancel Highlighting' : 'Highlight Pitches'"
            [disabled]="loading"
            (click)="toggleChartBrushable($event)"
        >
            <mat-icon>{{ (appService.isTouchDevice$ | async) === true ? 'touch_app' : 'ads_click' }}</mat-icon>
        </button>
    </div>
</ng-template>
