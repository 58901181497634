import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import dayjs from 'dayjs';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';

import {
    GameReviewsRouteParam,
    ReviewsGetCatchingReviewGQL,
    ReviewsGetCatchingReviewPitchesGQL,
    ReviewsGetCatchingReviewPitchesQuery,
    ReviewsGetCatchingReviewQuery,
    ReviewsGetCatchingSpecsGQL,
    ReviewsGetCatchingSpecsQuery,
} from '@stlc/game/reviews/data-access';
import type { ReviewsPlayer } from '@stlc/game/shared';
import { assign, chain, forEach, isEmpty, orderBy, some, uniqBy } from '@stlc/lodash';
import { getPitchTypeOrder } from '@stlc/lookup/legacy';
import { UiAppService } from '@stlc/ui/services';

type Pitch = ReviewsGetCatchingReviewPitchesQuery['pitches'][0];

interface Stats
    extends Pick<
        Pitch,
        | 'atbats'
        | 'battersFaced'
        | 'fastballPitches'
        | 'fastballStrikes'
        | 'firstPitches'
        | 'firstPitchStrikes'
        | 'homeRuns'
        | 'numberOfPitches'
        | 'oneOnePitches'
        | 'oneOneStrikes'
        | 'outs'
        | 'sacFlies'
        | 'strikeOuts'
        | 'strikes'
    > {
    games: number;
}

const statsProperties: Array<keyof Stats> = [
    'atbats',
    'battersFaced',
    'fastballPitches',
    'fastballStrikes',
    'firstPitches',
    'firstPitchStrikes',
    'homeRuns',
    'numberOfPitches',
    'oneOnePitches',
    'oneOneStrikes',
    'outs',
    'sacFlies',
    'strikeOuts',
    'strikes',
];

export interface ReviewsCatchingPitchTypeSummary
    extends Pick<Pitch, 'pitchType' | 'pitcher' | 'batterBats' | 'numberOfPitches'> {
    isUnverified?: boolean;
    usage?: number;
}

export interface ReviewsCatchingGameSummary extends Stats {
    gameDate: number;
    isUnverified?: boolean;
}

function getGameSummary(pitches: Pitch[]) {
    return chain(pitches)
        .groupBy(({ game }) => game.gameDate)
        .map((pitches, gameDate): ReviewsCatchingGameSummary => {
            const datum: Partial<ReviewsCatchingGameSummary> = { gameDate: dayjs(gameDate).valueOf() };

            forEach(pitches, (pitch) => {
                forEach(statsProperties, (prop) => {
                    datum[prop] = (datum[prop] ?? 0) + (pitch[prop] ?? 0);
                });
            });

            datum.games = chain(pitches).map('game').uniqBy('gameId').size().value();
            datum.isUnverified = some(pitches, ({ isUnverified }) => isUnverified);

            return datum as ReviewsCatchingGameSummary;
        })
        .valueOf();
}

function getPitchTypeSummary(pitches: Pitch[]) {
    const pitcherOrder = uniqBy(pitches, 'pitcher.id').map(({ pitcher }) => pitcher.id);
    const data = chain(pitches)
        .groupBy(({ pitcher, batterBats, pitchType }) => `${pitcher.id}:${pitchType}:${batterBats}`)
        .map((pitches): ReviewsCatchingPitchTypeSummary => {
            const datum = chain(pitches).head().pick('pitcher', 'batterBats', 'pitchType').valueOf();
            const numberOfPitches = chain(pitches).sumBy('numberOfPitches').value();
            return assign(datum, {
                numberOfPitches,
                usage: undefined,
                isUnverified: some(pitches, ({ isUnverified }) => isUnverified),
            });
        })
        .valueOf();

    chain(data)
        .groupBy(({ pitcher, batterBats }) => `${pitcher.id}:${batterBats}`)
        .forEach((pitchTypes) => {
            const totalPitches = chain(pitchTypes).sumBy('numberOfPitches').value();
            forEach(pitchTypes, (pitchType) => {
                pitchType.usage = totalPitches > 0 ? (100 * pitchType.numberOfPitches) / totalPitches : undefined;
            });
        })
        .valueOf();

    return orderBy(
        data,
        [
            ({ pitcher }) => pitcherOrder.indexOf(pitcher.id),
            ({ pitchType }) => getPitchTypeOrder(pitchType),
            ({ batterBats }) => batterBats,
        ],
        ['asc', 'asc', 'desc']
    );
}

export type ReviewsCatchingInfo = ReviewsGetCatchingSpecsQuery &
    ReviewsGetCatchingReviewQuery &
    ReviewsGetCatchingReviewPitchesQuery & {
        playerId: string;
        startDate: string;
        endDate: string;
        gameSummary: ReviewsCatchingGameSummary[];
        pitchTypeSummary: ReviewsCatchingPitchTypeSummary[];
    };

export const reviewsCatchingInfoResolver: ResolveFn<boolean | ReviewsCatchingInfo> = (route) => {
    const appService = inject(UiAppService);
    const router = inject(Router);
    const getCatchingReviewGQL = inject(ReviewsGetCatchingReviewGQL);
    const pitchesGQL = inject(ReviewsGetCatchingReviewPitchesGQL);
    const getCatchingSpecsGQL = inject(ReviewsGetCatchingSpecsGQL);
    const REVIEWS_URL_TREE = router.createUrlTree([
        'game-reviews',
        'player',
        route.queryParams['playerIdUniverse'],
        route.queryParams['playerId'],
    ]);
    const REVIEWS_CATCHING_URL_TREE = router.createUrlTree(
        ['game-reviews', 'player', route.queryParams['playerIdUniverse'], route.queryParams['playerId'], 'games'],
        { queryParams: { seasonType: 'Catching' } }
    );

    let player: ReviewsPlayer | undefined;
    let gameDateOrRange: string | undefined;
    let ref: ActivatedRouteSnapshot | null = route;
    while (ref) {
        if (!player) {
            player = ref.data['player'];
        }

        if (!gameDateOrRange) {
            gameDateOrRange = ref.params[GameReviewsRouteParam.GameDateOrRange];
        }

        if (player && gameDateOrRange) {
            break;
        }
        ref = ref.parent;
    }

    const [startDate, endDate = startDate] = gameDateOrRange.split('_');
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
        appService.error = 'Review game dates not found';
        router.navigateByUrl(REVIEWS_URL_TREE);
        return of(true);
    }

    if (!player || !startDate || !endDate || !gameDateOrRange) {
        appService.error = 'Review info not found';
        router.navigateByUrl(REVIEWS_URL_TREE);
        return of(true);
    }

    const { id: playerId } = player;

    appService.loading = true;

    return combineLatest([
        getCatchingReviewGQL.fetch({ playerId, startDate, endDate }).pipe(
            filter(({ loading }) => !loading),
            map(({ data }) => data)
        ),
        pitchesGQL.fetch({ playerId, startDate, endDate }).pipe(
            filter(({ loading }) => !loading),
            map(({ data }) => {
                if (isEmpty(data.pitches)) {
                    if (player.isCatcher) {
                        router.navigateByUrl(REVIEWS_CATCHING_URL_TREE);
                    } else {
                        router.navigateByUrl(REVIEWS_URL_TREE);
                    }
                    throw new Error('No pitches found for catcher');
                }

                return data.pitches;
            })
        ),
        getCatchingSpecsGQL
            .fetch({
                backgroundColor: 'transparent',
                pitchNumberField: 'atbatPitchNumber',
                interactive: true,
            })
            .pipe(map(({ data }) => data)),
    ]).pipe(
        map(([review, pitches, specs]) => {
            const gameSummary = getGameSummary(pitches);
            const pitchTypeSummary = getPitchTypeSummary(pitches);

            return review && specs
                ? { playerId, startDate, endDate, ...review, ...specs, pitches, gameSummary, pitchTypeSummary }
                : false;
        }),
        catchError(() => of(false)),
        finalize(() => {
            appService.loading = false;
        })
    );
};

function isValidDate(date: string, format = 'YYYY-MM-DD'): boolean {
    return dayjs(date, format).format(format) === date;
}
