import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map } from 'rxjs/operators';

import { ReviewsGetGameYearsGQL } from '@stlc/game/reviews/data-access';

export const reviewsGameYearsResolver: ResolveFn<any[]> = (route) => {
    const gameYearsGQL = inject(ReviewsGetGameYearsGQL);
    let player = null;
    let ref: ActivatedRouteSnapshot | null = route;
    while (ref) {
        if (!player) {
            player = ref.data['player'];
        }

        ref = ref.parent;
    }

    return gameYearsGQL.fetch({ playerId: player.id }).pipe(map(({ data }) => data.results));
};
