import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Pitch } from '@stlc/game/shared';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, find, reduce } from '@stlc/lodash';
import { calculateStat, getStatColumn, getStatFields, summarizeData } from '@stlc/lookup/stat';
import { getPitchTypeOrder } from '@stlc/lookup/statcast';
import { PlayerChartsPitchMovement } from '@stlc/player-stats/shared';
import { StlcStat, UiTableColumn, UiTableDisplayedColumn } from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

@Component({
    selector: 'stlc-reviews-pitching-report-by-pitch-summary',
    templateUrl: './reviews-pitching-report-by-pitch-summary.component.html',
    styleUrls: ['./reviews-pitching-report-by-pitch-summary.component.scss'],
    standalone: true,
    imports: [CommonModule, UiTableModule, StlcI18nModule],
})
export class ReviewsPitchingReportByPitchSummaryComponent implements OnChanges, OnInit {
    @Input()
    pitches: Pitch[] = [];

    @Input()
    gamePitchMovement: PlayerChartsPitchMovement[];

    columns: UiTableColumn[];
    displayedColumns: UiTableDisplayedColumn[];
    readonly stickyStart = [StlcStat.PitchAbbrev];

    dataSource = new UiTableDataSource();

    ngOnInit() {
        this.columns = [
            {
                ...getStatColumn(StlcStat.PitchAbbrev),
                header: { label: 'Type', labelKey: 'stat:pitchAbbrev_header' },
                width: 1,
            },
            {
                ...getStatColumn(StlcStat.NumberOfPitches),
                header: '#',
            },
            getStatColumn(StlcStat.Usage),
            { ...getStatColumn(StlcStat.ReleaseSpeedRange), tooltip: { field: 'releaseSpeedTooltip' } },
            getStatColumn(StlcStat.ReleaseExtension),
            {
                ...getStatColumn(StlcStat.ReleaseSpinRate),
                format: {
                    type: 'number',
                    defaultText: '-',
                    digits: 0,
                    roundToNearest: 5,
                },
            },
            getStatColumn(StlcStat.ReleaseSpinAxis),
            getStatColumn(StlcStat.InducedVerticalBreak),
            getStatColumn(StlcStat.HorizontalBreak),
            getStatColumn(StlcStat.ReleaseHeight, true),
            getStatColumn(StlcStat.ReleaseSide, true),
            getStatColumn(StlcStat.ReleaseVerticalAngle),
            getStatColumn(StlcStat.ReleaseHorizontalAngle),
            getStatColumn(StlcStat.VerticalApproachAngle),
            getStatColumn(StlcStat.HorizontalApproachAngle),
            getStatColumn(StlcStat.InZoneRate),
            getStatColumn(StlcStat.OutOfZoneSwingRate),
            getStatColumn(StlcStat.SwingAndMissRate),
        ];

        this.displayedColumns = [
            StlcStat.PitchAbbrev,
            StlcStat.NumberOfPitches,
            StlcStat.Usage,
            {
                id: 'releaseGroup',
                header: 'Release',
                borderless: true,
                class: 'text-center',
                displayedColumns: [
                    StlcStat.ReleaseSpeedRange,
                    StlcStat.ReleaseExtension,
                    StlcStat.ReleaseSide,
                    StlcStat.ReleaseHeight,
                    StlcStat.ReleaseVerticalAngle,
                    StlcStat.ReleaseHorizontalAngle,
                ],
            },
            {
                id: 'spinGroup',
                header: 'Spin',
                borderless: true,
                class: 'text-center',
                displayedColumns: [StlcStat.ReleaseSpinRate, StlcStat.ReleaseSpinAxis],
            },
            {
                id: 'breakGroup',
                header: 'Break',
                borderless: true,
                class: 'text-center',
                displayedColumns: [StlcStat.InducedVerticalBreak, StlcStat.HorizontalBreak],
            },
            {
                id: 'approachGroup',
                header: 'Approach',
                borderless: true,
                class: 'text-center',
                displayedColumns: [StlcStat.VerticalApproachAngle, StlcStat.HorizontalApproachAngle],
            },
            {
                id: 'results',
                header: 'Results',
                borderless: true,
                class: 'text-center',
                displayedColumns: [StlcStat.InZoneRate, StlcStat.OutOfZoneSwingRate, StlcStat.SwingAndMissRate],
            },
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pitches'] && this.pitches) {
            const calculatedStats = [
                StlcStat.OutOfZoneSwingRate,
                StlcStat.InZoneRate,
                StlcStat.SwingAndMissRate,
                StlcStat.Usage,
            ];

            const properties = chain(calculatedStats)
                .filter((stat) => stat !== StlcStat.Usage)
                .map((stat) => getStatFields(stat))
                .flatten()
                .uniq()
                .valueOf();

            const summarizedData = summarizeData(this.pitches, properties, 'pitchType');

            this.dataSource.data = chain(summarizedData)
                .map((datum) => {
                    const pitchMovementData = find(this.gamePitchMovement, { pitchType: datum['pitchType'] });
                    return reduce(
                        calculatedStats,
                        (result, stat) => {
                            result[stat] = calculateStat(result, stat);
                            return result;
                        },
                        {
                            ...datum,
                            ...pitchMovementData,
                        }
                    );
                })
                .orderBy([({ pitchType }) => getPitchTypeOrder(pitchType)])
                .valueOf();
        }
    }
}
