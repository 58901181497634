import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subject } from 'rxjs';

import { StlcHotColdDirective } from '@stlc/angular/directives/hot-cold';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes';
import { StlcStatPipe } from '@stlc/angular/pipes/stat';
import { ReviewsBattingAggregateSummaryFragment, ReviewsGoalFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { find, get, isNil, isString, memoize, transform } from '@stlc/lodash';
import { HotColdPercentileOptions, StlcStatPipeData } from '@stlc/shared';

@Component({
    selector: 'stlc-reviews-game-summary-metrics',
    templateUrl: './reviews-game-summary-metrics.component.html',
    styleUrls: ['./reviews-game-summary-metrics.component.scss'],
    standalone: true,
    imports: [CommonModule, StlcI18nModule, StlcHotColdDirective, StlcNumberToFixedPipe],
})
export class ReviewsGameSummaryMetricsComponent implements OnChanges, OnDestroy {
    @Input()
    type: 'pitching' | 'batting' | undefined;

    @Input()
    goals: ReviewsGoalFragment[] = [];

    @Input()
    summary: ReviewsBattingAggregateSummaryFragment | undefined;

    statPipe = new StlcStatPipe();
    goalDataMap: { [key: string]: number | string } = {};
    readonly hotColdOptions: HotColdPercentileOptions = { theme: 'green-red' };
    getPercentile = memoize(
        (goal: ReviewsGoalFragment): number => {
            const datum = find(goal?.percentiles, (datum) => {
                let value = this.goalDataMap[goal.stat];
                if (isString(value)) {
                    const split = value.split('/');
                    const numerator = parseInt(split[0], 10);
                    const denominator = numerator + parseInt(split[1], 10);
                    value = !isNaN(numerator / denominator) ? numerator / denominator : 0;
                }

                return (
                    (isNil(datum?.upperBound) && datum.lowerBound <= value) ||
                    (!isNil(datum.upperBound) && datum.lowerBound <= value && datum.upperBound >= value)
                );
            });

            return datum?.percentile ?? 50;
        },
        (goal: ReviewsGoalFragment) => `${goal.type}:${goal.stat}:${this.goalDataMap[goal.stat]}`
    );
    private readonly destroy = new Subject<void>();

    constructor(@Inject(I18NEXT_SERVICE) i18next: ITranslationService) {
        i18next.loadNamespaces(['reviews']);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['type'] || changes['goals']) {
            this.setDataMap();
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }

    private setDataMap(): void {
        this.goalDataMap = transform(
            this.goals,
            (result: { [key: string]: number | string }, goal) => {
                result[goal.stat] = goal.calculate
                    ? this.statPipe.transform(this.summary as Partial<StlcStatPipeData>, goal.stat)
                    : get(this.summary, goal.stat);
            },
            {}
        );
    }
}
