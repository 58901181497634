import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';

import type { ReviewsPlayer } from '@stlc/game/shared';
import { chain } from '@stlc/lodash';

export const resolvePlayer: ResolveFn<any> = (route) => {
    const router = inject(Router);

    const player = chain(route.pathFromRoot)
        .transform((result: ReviewsPlayer[], { data }) => {
            if (data['player']) {
                result.push(data['player'] as ReviewsPlayer);
            }
        }, [])
        .last()
        .valueOf();

    if (!player) {
        console.log('Player not found');
        router.navigate(['/']);
        return undefined;
    }

    return player;
};
