<div class="flex flex-col space-y-4 px-2 mb-8 w-screen md:mb-16 md:w-full lg:mx-auto">
    <div>
        <stlc-reviews-game-summary-metrics
            type="batting"
            [goals]="reviewsBattingService.goals$ | async"
            [summary]="reviewsBattingService.summary$ | async"
        ></stlc-reviews-game-summary-metrics>
    </div>

    <ui-section>
        <div
            section-header
            [innerHTML]="'reviews:swingDecisions_title' | i18next : { defaultValue: 'Swing Decisions' }"
        ></div>
        <mat-card class="w-full">
            <mat-card-content>
                <ui-table
                    containerClass="overflow-auto"
                    [tableClass]="'stlc-dense-compact text-sm'"
                    [columns]="columns"
                    [displayedColumns]="displayedColumns"
                    [dataSource]="swingDecisionDataSource"
                    [sortable]="false"
                    [stickyStart]="stickyColumnsStart"
                    [showFooter]="isMultipleGameSummaries"
                ></ui-table>
            </mat-card-content>
        </mat-card>
    </ui-section>

    <ng-container
        *ngIf="reviewsBattingService.showBlast$ | async"
        [ngSwitch]="reviewsBattingService.hasBatTracking$ | async"
    >
        <ui-section>
            <div
                section-header
                [innerHTML]="'reviews:batTrackingMetrics_title' | i18next : { defaultValue: 'Bat Tracking Metrics' }"
            ></div>
            <div class="w-full" *ngSwitchCase="true">
                <stlc-reviews-game-summary-metrics
                    type="blast"
                    [goals]="reviewsBattingService.blastGoals$ | async"
                    [summary]="reviewsBattingService.summary$ | async"
                ></stlc-reviews-game-summary-metrics>
            </div>
            <div *ngSwitchCase="false" class="w-full flex flex-col items-center">
                <span class="stlc-no-data-found mt-0" [innerHTML]="'common:message.noDataFound' | i18next"></span>
                <span
                    class="text-gray-400"
                    [innerHTML]="
                        'reviews:blastAvailabilityMessage'
                            | i18next
                                : {
                                      defaultValue:
                                          'If a Blast Motion sensor was used in this game, data is typically available within 24 hours'
                                  }
                    "
                ></span>
            </div>
            <div section-actions *ngSwitchCase="true">
                <button
                    class="btn-sm mr-2 self-end"
                    color="gray"
                    type="button"
                    mat-button
                    (click)="showGlossaryDialog()"
                >
                    {{ 'glossary:title' | i18next : { defaultValue: 'Glossary' } }}
                </button>
            </div>
        </ui-section>
    </ng-container>

    <ng-container *ngIf="reviewsBattingService.rebootReports$ | async as rebootReports">
        {{ '' // For now assume there is no more than one report per day }}
        <div *ngIf="rebootReports.length > 0 && rebootReports[0] as rebootReport" class="flex justify-center">
            <a
                [href]="rebootReport.reportUrl"
                target="_blank"
                mat-button
                class="btn-sm"
                color="gray"
                (click)="reviewsBattingService.loadRebootReport(rebootReport.id)"
            >
                <mat-icon>open_in_new</mat-icon>
                {{ 'reviews:rebootBattingReport_label' | i18next : { defaultValue: 'Reboot Batting Report' } }}
            </a>
        </div>
    </ng-container>
</div>
