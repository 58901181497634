import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';

import { StlcI18nModule } from '@stlc/i18n/core';
import { map } from '@stlc/lodash';
import { UiMenuItem } from '@stlc/shared';
import { UiResponsiveTabsComponent } from '@stlc/ui';
import { UiAppService } from '@stlc/ui/services';

import { ReviewsPitchingService } from '../../services/reviews-pitching.service';

@Component({
    selector: 'stlc-reviews-pitching',
    templateUrl: './reviews-pitching.component.html',
    styleUrls: ['./reviews-pitching.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, StlcI18nModule, UiResponsiveTabsComponent],
})
export class ReviewsPitchingComponent implements OnInit {
    private readonly i18next = inject(I18NEXT_SERVICE);
    private readonly appService = inject(UiAppService);
    public readonly reviewsPitchingService = inject(ReviewsPitchingService);

    menuItems: UiMenuItem[] = [
        {
            displayKey: 'reviews:tab.summary',
            displayName: 'Summary',
            routerLink: 'summary',
        },
        {
            displayKey: 'reviews:tab.atBats',
            displayName: 'At Bats',
            routerLink: 'at-bats',
        },
        {
            displayKey: 'reviews:tab.pitches',
            displayName: 'Pitches',
            routerLink: 'pitches',
        },
    ];

    ngOnInit() {
        this.appService.subtitles = map(
            [
                { defaultValue: this.reviewsPitchingService.startDate },
                { key: 'common:pitching', defaultValue: 'Pitching' },
                { key: 'reviews:title', defaultValue: 'Game Reviews' },
            ],
            ({ key, defaultValue }) => (this.i18next && key ? this.i18next.t(key, { defaultValue }) : defaultValue)
        );

        // this.menuItems = map(this.menuItems, (datum) => ({
        //     ...datum,
        //     routerLink: `/reviews/pitching/${this.reviewsPitchingService.startDate}/${datum.routerLink}`,
        // }));
    }
}
