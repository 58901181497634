<div class="flex flex-col space-y-6 items-center">
    <div>
        <stlc-reviews-catching-summary-metrics
            [pitches]="reviewsCatchingService.pitches$ | async"
            [isVerified]="(reviewsCatchingService.isUnverified$ | async) === false"
        ></stlc-reviews-catching-summary-metrics>
    </div>

    <div class="flex flex-row flex-wrap justify-center gap-8 max-w-screen-lg">
        <div *ngFor="let datum of pitcherData" class="max-w-md w-full">
            <div>{{ datum.name }}</div>
            <mat-card class="w-full mt-1">
                <mat-card-content>
                    <ui-table
                        [tableClass]="'stlc-dense-compact text-sm'"
                        [columns]="pitcherTableColumns"
                        [displayedColumns]="pitcherTableDisplayedColumns"
                        [dataSource]="datum.dataSource"
                        [sortable]="false"
                        [stickyStart]="pitcherTableStickyColumns"
                    ></ui-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
