import type { Route } from '@angular/router';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

import { ChirpApplication } from '@stlc/chirp/lookup';
import { authorizedGuard } from '@stlc/user';

import { ReviewsComponent } from './components/reviews/reviews.component';
import { ReviewsCatchingComponent } from './components/reviews-catching/reviews-catching.component';
import { ReviewsCatchingAtBatsComponent } from './components/reviews-catching-at-bats/reviews-catching-at-bats.component';
import { ReviewsCatchingFramingComponent } from './components/reviews-catching-framing/reviews-catching-framing.component';
import { ReviewsCatchingPitchesComponent } from './components/reviews-catching-pitches/reviews-catching-pitches.component';
import { ReviewsCatchingSummaryComponent } from './components/reviews-catching-summary/reviews-catching-summary.component';
import { reviewsGameYearsResolver } from './resolves/review-game-years.resolver';
import { reviewsCatchingInfoResolver } from './resolves/reviews-catching-info.resolver';

export const catchingRoutes: Route[] = [
    {
        path: '',
        component: ReviewsComponent,
        data: {
            i18nextNamespaces: [
                'chart',
                'common',
                'filters',
                'glossary',
                'game',
                'pitchType',
                'player',
                'playerCharts',
                'reviews',
                'stat',
                'video',
            ],
            reviewType: 'catching',
            applications: ChirpApplication.reviews.CATCHING_GAME_REVIEWS,
        },
        canActivate: [authorizedGuard],
        resolve: {
            gameYears: reviewsGameYearsResolver,
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
        children: [
            {
                path: '',
                component: ReviewsCatchingComponent,
                resolve: {
                    catchingInfo: reviewsCatchingInfoResolver,
                },
                children: [
                    {
                        path: 'at-bats',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsCatchingAtBatsComponent,
                    },
                    {
                        path: 'pitches',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsCatchingPitchesComponent,
                    },
                    {
                        path: 'summary',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsCatchingSummaryComponent,
                    },
                    {
                        path: 'framing',
                        data: {
                            reuseRoute: true,
                        },
                        component: ReviewsCatchingFramingComponent,
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'summary',
                    },
                ],
            },
        ],
    },
];
