import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReviewsBattingAggregateSummaryFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { assign, chain, forEach, get, isNil, isString, pull, size, startsWith, template } from '@stlc/lodash';
import { getStatColumn, getStlcGlossary } from '@stlc/lookup/stat';
import { ReviewsGameSummaryMetricsComponent } from '@stlc/reviews/ui';
import { StlcStat, UiTableColumn } from '@stlc/shared';
import { UiGlossaryDialogComponent, UiSectionComponent } from '@stlc/ui';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiGlossaryService } from '@stlc/ui/services';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

import { ReviewsBattingService } from '../../services/reviews-batting.service';

function getDecisionTypeTemplate(property: string): string {
    return `<span class="leading-relaxed">
            <%= datum.${property}Ratio !== null ? datum.${property}Ratio + '<br>' + Math.round(datum.${property}Rate, 0) + '%' : '-' %>
        </span>`;
}

@Component({
    selector: 'stlc-reviews-batting-summary',
    templateUrl: './reviews-batting-summary.component.html',
    styleUrls: ['./reviews-batting-summary.component.scss'],
    providers: [UiGlossaryService],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        ReviewsGameSummaryMetricsComponent,
        UiSectionComponent,
        UiTableModule,
        StlcI18nModule,
    ],
})
export class ReviewsBattingSummaryComponent implements OnInit, OnDestroy {
    public readonly reviewsBattingService = inject(ReviewsBattingService);
    public readonly glossaryService = inject(UiGlossaryService);
    private readonly dialog = inject(UiDialogService);

    columns: UiTableColumn[] = [
        {
            id: 'gameDate',
            header: 'Date',
            class: 'whitespace-nowrap',
            footer: 'Totals',
        },
        getStatColumn(StlcStat.PlateAppearances),
        getStatColumn(StlcStat.OutOfZoneSwingRate),
        getStatColumn(StlcStat.ContactRate),
        getStatColumn(StlcStat.CalledStrikeRate),
        { ...getStatColumn(StlcStat.NumberOfPitches), divider: true },
        getStatColumn(StlcStat.PitchRecognitionOpportunities),
        {
            id: 'goodSwingDecisionsAll',
            header: { label: 'All', labelKey: 'stat:all_header' },
            field: template(getDecisionTypeTemplate('goodSwingDecisionsAll')),
            class: 'text-right',
        },
        {
            id: 'goodSwingDecisionsEarly',
            header: { label: 'Early', labelKey: 'stat:early_header' },
            field: template(getDecisionTypeTemplate('goodSwingDecisionsEarly')),
            class: 'text-right',
        },
        {
            id: 'goodSwingDecisionsAhead',
            header: { label: 'Ahead', labelKey: 'stat:ahead_header' },
            field: template(getDecisionTypeTemplate('goodSwingDecisionsAhead')),
            class: 'text-right',
        },
        {
            id: 'goodSwingDecisionsTwoStrikes',
            header: { label: '2K', labelKey: 'stat:twoStrike_abbr' },
            field: template(getDecisionTypeTemplate('goodSwingDecisionsTwoStrikes')),
            class: 'text-right',
        },
        {
            id: 'goodSwingDecisionsFullCount',
            header: { label: 'Full', labelKey: 'stat:full_header' },
            field: template(getDecisionTypeTemplate('goodSwingDecisionsFullCount')),
            class: 'text-right',
        },
        {
            id: 'goodTakeDecisionsAll',
            header: { label: 'All', labelKey: 'stat:all_header' },
            field: template(getDecisionTypeTemplate('goodTakeDecisionsAll')),
            class: 'text-right',
        },
        {
            id: 'goodTakeDecisionsEarly',
            header: { label: 'Early', labelKey: 'stat:early_header' },
            field: template(getDecisionTypeTemplate('goodTakeDecisionsEarly')),
            class: 'text-right',
        },
        {
            id: 'goodTakeDecisionsAhead',
            header: { label: 'Ahead', labelKey: 'stat:ahead_header' },
            field: template(getDecisionTypeTemplate('goodTakeDecisionsAhead')),
            class: 'text-right',
        },
        {
            id: 'goodTakeDecisionsTwoStrikes',
            header: { label: '2K', labelKey: 'stat:twoStrike_abbr' },
            field: template(getDecisionTypeTemplate('goodTakeDecisionsTwoStrikes')),
            class: 'text-right',
        },
        {
            id: 'goodTakeDecisionsFullCount',
            header: { label: 'Full', labelKey: 'stat:full_header' },
            field: template(getDecisionTypeTemplate('goodTakeDecisionsFullCount')),
            class: 'text-right',
        },
    ];
    displayedColumns = [
        ...chain(this.columns).slice(0, 7).map('id').valueOf(),
        {
            id: 'goodSwingDecisions',
            header: { label: 'Good Swing Decisions', labelKey: 'stat:goodSwingDecisions_header' },
            class: 'text-center',
            displayedColumns: chain(this.columns).slice(7, 12).map('id').valueOf(),
        },
        {
            id: 'goodTakeDecisions',
            header: { label: 'Good Take Decisions', labelKey: 'stat:goodTakeDecisions_header' },
            class: 'text-center',
            displayedColumns: chain(this.columns).slice(12, 17).map('id').valueOf(),
        },
    ];
    stickyColumnsStart = ['gameDate'];
    swingDecisionDataSource = new UiTableDataSource<ReviewsBattingAggregateSummaryFragment>();
    isMultipleGameSummaries = false;

    private readonly destroy = new Subject<void>();

    ngOnInit() {
        this.reviewsBattingService.gameSummary$.pipe(takeUntil(this.destroy)).subscribe((gameSummary) => {
            this.isMultipleGameSummaries = size(gameSummary) > 1;
            if (!this.isMultipleGameSummaries) {
                pull(this.displayedColumns, 'gameDate');
            }
            this.swingDecisionDataSource.data = gameSummary;
        });

        this.reviewsBattingService.summary$.pipe(takeUntil(this.destroy)).subscribe((summary) => {
            forEach(this.columns, (column) => {
                if (column.id !== 'gameDate') {
                    let propertyPath =
                        !isNil(column.field) && isString(column.field) ? (column.field as string) : column.id;
                    if (startsWith(column.id, 'good')) {
                        propertyPath = propertyPath + 'Rate';
                    }

                    assign(column, {
                        footer: {
                            label: get(summary, propertyPath),
                            class: column.class,
                            format: column.format,
                        },
                    });
                }
            });
        });
    }

    showGlossaryDialog() {
        this.dialog.open(UiGlossaryDialogComponent, {
            data: {
                glossaryItems: [
                    this.glossaryService.createGlossaryItem(
                        getStlcGlossary(StlcStat.BatterBatSpeed, 'Bat Speed', 'glossary:batterBatSpeed_tooltip')
                    ),
                    this.glossaryService.createGlossaryItem(
                        getStlcGlossary(
                            StlcStat.BatterAttackAngle,
                            'Attack Angle',
                            'glossary:batterAttackAngle_tooltip'
                        )
                    ),
                    this.glossaryService.createGlossaryItem(
                        getStlcGlossary(
                            StlcStat.BatterPlanarEfficiency,
                            'On Plane',
                            'glossary:batterPlanarEfficiency_tooltip'
                        )
                    ),
                    this.glossaryService.createGlossaryItem(
                        getStlcGlossary(StlcStat.BatterCommitTime, 'Commit Time', 'glossary:batterCommitTime_tooltip')
                    ),
                ],
            },
            panelClass: ['max-w-screen-md', 'p-2'],
        });
    }

    ngOnDestroy() {
        this.destroy.next();
    }
}
