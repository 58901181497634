import type { Spec } from 'vega';

import { map } from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { getPitchTypeAbbrev, getPitchTypeColor, playerStatsPitchTypes } from '@stlc/lookup/statcast';

export const pitchingPitchMovementSpec: Spec = {
    autosize: 'fit',
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    width: 280,
    height: 280,
    usermeta: { resize: false },
    background: 'transparent',
    config: {
        title: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: 16,
            fontWeight: 'normal',
            color: '#333333',
        },
        axis: {
            domainColor: '#aaaaaa',
            domainOpacity: 1,
            gridColor: '#dddddd',
            gridOpacity: 1,
            labelColor: '#333333',
            labelFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            labelFontSize: 11,
            labelFontWeight: 'normal',
            tickColor: '#999999',
            titleFont: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
            titleFontSize: 16,
            titleFontWeight: 'normal',
            titleColor: '#333333',
            titlePadding: 16,
        },
        text: {
            font: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        events: { defaults: { allow: ['wheel', 'touchstart'] } },
    },
    signals: [
        { name: 'width', value: 336 },
        { name: 'height', update: 'width' },
        { name: 'chartScale', update: 'min(max(width / 400, 0.75), 1.25)' },
        { name: 'pitcherThrowsLeft', value: false },
        { name: 'showLeagueContext', value: true },
        { name: 'showLeagueEllipse', value: false },
        { name: 'showAverage', value: true },
        { name: 'pitchType', value: [] },
        { name: 'unit', value: 'inches' },
        { name: 'selectedIds', value: null },
        { name: 'showPitches', value: true },
        { name: 'showContext', value: false },
        { name: 'pitchSize', value: 125 },
        { name: 'insufficientData', value: null },
        {
            name: 'brushed',
            value: false,
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                        },
                    ],
                    update: '!selectable && brushable ? true : false',
                },
                {
                    events: 'window:mouseup, window:touchend',
                    update: 'false',
                },
            ],
        },
        { name: 'hoveredId', value: null },
        {
            name: 'hovered',
            value: null,
            on: [
                { events: '@visibleMarks:mouseover', update: '!brushed && datum ? datum.id : null' },
                { events: '@visibleMarks:mouseout', update: 'null' },
            ],
        },
        { name: 'selectable', value: true },
        { name: 'brushable', value: true },
        {
            name: 'selectedId',
            value: null,
            on: [
                {
                    events: [
                        {
                            merge: [
                                {
                                    type: 'mousedown',
                                    markname: 'visibleMarks',
                                },
                                {
                                    type: 'touchstart',
                                    markname: 'visibleMarks',
                                },
                            ],
                        },
                    ],
                    update: 'selectable && datum && isArray(datum.videos) && length(datum.videos) > 0 ? datum.id : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushStart',
            value: [0, 0],
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                    force: true,
                },
            ],
        },
        {
            name: 'brushEnd',
            value: [0, 0],
            on: [
                {
                    events: 'mousedown, [mousedown, window:mouseup] > window:mousemove, touchstart, [touchstart, window:touchend] > window:touchmove',
                    update: 'brushable ? { x: clamp(x(), 0, width), y: clamp(y(), 0, height) } : null',
                    force: true,
                },
            ],
        },
        // { name: 'isBrushing', value: false },
        {
            name: 'brushX',
            update: "brushStart ? invert('x', [brushStart.x, brushEnd.x]) : []",
        },
        {
            name: 'brushY',
            update: "brushStart ? invert('y', [brushStart.y, brushEnd.y]) : []",
        },
        {
            name: 'brushOperation',
            on: [
                {
                    events: [
                        {
                            type: 'mousedown',
                        },
                        {
                            type: 'touchstart',
                            consume: true,
                        },
                    ],
                    update: "event.shiftKey && !event.altKey ? 'add' : event.altKey && !event.shiftKey ? 'remove' : null",
                },
            ],
        },
        { name: 'brushedIds', value: null },
    ],
    data: [
        {
            name: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'datum.pitchType && isFinite(datum.inducedVerticalBreak) && isFinite(datum.horizontalBreak)',
                },
                {
                    type: 'formula',
                    as: 'inducedVerticalBreak',
                    expr: "datum.inducedVerticalBreak * (unit === 'feet' ? 12 : 1)",
                },
                {
                    type: 'formula',
                    as: 'horizontalBreak',
                    expr: "datum.horizontalBreak * (unit === 'feet' ? 12 : 1)",
                },
            ],
            values: [],
        },
        {
            name: 'avgData',
            values: [],
        },
        {
            name: 'visibleContext',
            source: 'context',
            transform: [
                {
                    type: 'filter',
                    expr: '!selectedIds || (selectedIds && length(selectedIds) > 0 && indexof(selectedIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'contextDensity',
            source: 'visibleContext',
            transform: [
                {
                    type: 'kde2d',
                    groupby: ['pitchType'],
                    size: [{ signal: 'width' }, { signal: 'height' }],
                    x: { expr: "scale('x', datum.horizontalBreak)" },
                    y: { expr: "scale('y', datum.inducedVerticalBreak)" },
                    bandwidth: [8, 8],
                },
            ],
        },
        {
            name: 'contextContours',
            source: 'contextDensity',
            transform: [
                {
                    type: 'isocontour',
                    field: 'grid',
                    levels: 4,
                    resolve: 'independent',
                },
            ],
        },
        {
            name: 'visibleData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: '!selectedIds || (selectedIds && length(selectedIds) > 0 && indexof(selectedIds, datum.id) >= 0)',
                },
            ],
        },
        {
            name: 'hiddenData',
            source: 'data',
            transform: [
                {
                    type: 'filter',
                    expr: 'selectedIds && (length(selectedIds) == 0 || indexof(selectedIds, datum.id) == -1)',
                },
            ],
        },
        {
            name: 'summaryData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: "!showPitches && datum.pitchType !== 'Unknown'",
                },
                {
                    type: 'aggregate',
                    groupby: ['pitchType'],
                    fields: [
                        'inducedVerticalBreak',
                        'inducedVerticalBreak',
                        'inducedVerticalBreak',
                        'horizontalBreak',
                        'horizontalBreak',
                        'horizontalBreak',
                    ],
                    ops: ['min', 'max', 'mean', 'min', 'max', 'mean'],
                    as: [
                        'inducedVerticalBreak0',
                        'inducedVerticalBreak1',
                        'inducedVerticalBreak',
                        'horizontalBreak0',
                        'horizontalBreak1',
                        'horizontalBreak',
                    ],
                },
                // {
                //     type: 'lookup',
                //     from: 'lgData',
                //     key: 'pitchType',
                //     fields: ['pitchType'],
                //     values: ['ellipseData'],
                //     as: ['lgData'],
                //     default: [],
                // },
            ],
        },
        {
            name: 'lgData',
            values: [],
            transform: [
                {
                    type: 'filter',
                    expr: "indata('summaryData', 'pitchType', datum.pitchType)",
                },
            ],
        },
        {
            name: 'brushedData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: 'isDefined(datum.horizontalBreak) && isDefined(datum.inducedVerticalBreak)',
                },
                {
                    type: 'filter',
                    expr: 'inrange(datum.horizontalBreak, brushX) && inrange(datum.inducedVerticalBreak, brushY)',
                },
            ],
        },
        {
            name: 'hoveredData',
            source: 'visibleData',
            transform: [
                {
                    type: 'filter',
                    expr: 'hoveredId == datum.id',
                },
            ],
        },
    ],
    scales: [
        {
            name: 'x',
            type: 'linear',
            domain: [-24, 24],
            range: 'width',
        },
        {
            name: 'y',
            type: 'linear',
            domain: [-24, 24],
            nice: true,
            zero: true,
            range: 'height',
        },
        {
            name: 'pitchColor',
            type: 'ordinal',
            domain: playerStatsPitchTypes,
            range: map(playerStatsPitchTypes, (datum) => getPitchTypeColor(datum)),
        },
        {
            name: 'pitchLabel',
            type: 'ordinal',
            domain: playerStatsPitchTypes,
            range: map(playerStatsPitchTypes, (datum) => getPitchTypeAbbrev(getPitchTypeCode(datum))),
        },
    ],
    marks: [
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    align: { value: 'right' },
                    baseline: { value: 'bottom' },
                    angle: { value: -90 },
                },
                update: {
                    fontSize: { signal: '14 * chartScale' },
                    text: { signal: "stlcTranslate('chart:rise_label')" },
                    x: { value: 0, scale: 'x', offset: { value: -4 } },
                    y: { value: 25, scale: 'y' },
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    align: { value: 'right' },
                    baseline: { value: 'bottom' },
                    angle: { value: 90 },
                },
                update: {
                    fontSize: { signal: '14 * chartScale' },
                    text: { signal: "stlcTranslate('chart:drop_label')" },
                    x: { value: 0, scale: 'x', offset: { value: 4 } },
                    y: { value: -25, scale: 'y' },
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    baseline: { value: 'bottom' },
                },
                update: {
                    x: [
                        { test: 'pitcherThrowsLeft', value: 24, scale: 'x' },
                        { value: -24, scale: 'x' },
                    ],
                    fontSize: { signal: '14 * chartScale' },
                    text: { signal: "stlcTranslate('chart:gloveside_label')" },
                    y: { value: 0, scale: 'y', offset: { value: -4 } },
                    align: [{ test: 'pitcherThrowsLeft', value: 'right' }, { value: 'left' }],
                },
            },
        },
        {
            type: 'text',
            encode: {
                enter: {
                    fillOpacity: { value: 0.8 },
                    baseline: { value: 'top' },
                },
                update: {
                    x: [
                        { test: 'pitcherThrowsLeft', value: -24, scale: 'x' },
                        { value: 24, scale: 'x' },
                    ],
                    text: { signal: "stlcTranslate('chart:armside_label')" },
                    y: { value: 0, scale: 'y', offset: { value: 4 } },
                    fontSize: { signal: '14 * chartScale' },
                    align: [{ test: 'pitcherThrowsLeft', value: 'left' }, { value: 'right' }],
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { field: { group: 'x' } },
                    x2: { field: { group: 'width' } },
                    y: { value: 0, scale: 'y' },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1 },
                },
            },
        },
        {
            type: 'rule',
            encode: {
                enter: {
                    x: { value: 0, scale: 'x' },
                    y: { value: 0 },
                    y2: { field: { group: 'height' } },
                    stroke: { value: '#CCCCCC' },
                    strokeWidth: { value: 1 },
                },
            },
        },
        {
            type: 'group',
            from: {
                facet: {
                    name: 'ellipseData',
                    data: 'lgData',
                    field: 'ellipseData',
                },
            },
            data: [
                {
                    name: 'ellipse',
                    source: 'ellipseData',
                    transform: [
                        {
                            type: 'filter',
                            expr: "showLeagueEllipse && datum.coordinateType == 'ellipse'",
                        },
                    ],
                },
            ],
            marks: [
                {
                    type: 'line',
                    from: { data: 'ellipse' },
                    encode: {
                        enter: {
                            fillOpacity: { value: 0.25 },
                            strokeOpacity: { value: 0.1 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: { scale: 'x', field: 'x' },
                            y: { scale: 'y', field: 'y' },
                            fill: { scale: 'pitchColor', field: 'pitchType' },
                            stroke: { scale: 'pitchColor', field: 'pitchType' },
                        },
                    },
                },
            ],
        },
        {
            type: 'group',
            from: {
                facet: {
                    name: 'ellipseContextData',
                    data: 'summaryData',
                    field: 'lgData',
                },
            },
            data: [
                {
                    name: 'ellipseContext',
                    source: 'ellipseContextData',
                    transform: [
                        {
                            type: 'filter',
                            expr: "showLeagueContext && datum.coordinateType == 'ellipse_context'",
                        },
                    ],
                },
            ],
            marks: [
                {
                    type: 'line',
                    from: { data: 'ellipseContext' },
                    encode: {
                        enter: {
                            x: { scale: 'x', field: 'x' },
                            y: { scale: 'y', field: 'y' },
                            fill: { value: 'transparent' },
                            fillOpacity: { value: 0.25 },
                            stroke: { scale: 'pitchColor', field: 'pitchType' },
                            strokeOpacity: { value: 0.5 },
                            strokeWidth: { value: 2 },
                            strokeDash: { value: [5, 2] },
                        },
                    },
                },
            ],
        },
        {
            type: 'path',
            clip: true,
            from: { data: 'contextContours' },
            interactive: false,
            encode: {
                enter: {
                    stroke: { value: 'white' },
                    strokeOpacity: { value: 0.3 },
                    fillOpacity: { value: 0.2 },
                },
                update: {
                    fill: { scale: 'pitchColor', field: 'pitchType' },
                    opacity: { signal: 'showContext ? 0.8 : 0' },
                },
            },
            transform: [{ type: 'geopath', field: 'datum.contour' }],
        },
        // {
        //     name: 'contextMarks',
        //     type: 'symbol',
        //     from: { data: 'visibleContext' },
        //     interactive: false,
        //     clip: true,
        //     encode: {
        //         update: {
        //             x: { scale: 'x', field: 'horizontalBreak' },
        //             y: { scale: 'y', field: 'inducedVerticalBreak' },
        //             fill: {
        //                 scale: 'pitchColor',
        //                 field: 'pitchType',
        //             },
        //             size: { value: 4 },
        //             opacity: { signal: 'showContext ? 0.25 : 0' },
        //         },
        //     },
        // },
        {
            type: 'group',
            from: { data: 'avgData' },
            clip: true,
            marks: [
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeWidth: { value: 1.5 },
                            strokeOpacity: { value: 0.75 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: -9 },
                            },
                            x2: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: 9 },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeWidth: { value: 1.5 },
                            strokeOpacity: { value: 0.75 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: -9 },
                            },
                            y2: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: 9 },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
            ],
        },
        {
            type: 'group',
            from: { data: 'summaryData' },
            clip: true,
            marks: [
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeWidth: { value: 2 },
                            strokeOpacity: { value: 0.7 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak0' },
                            },
                            x2: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak1' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.7 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak0' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: -5 },
                            },
                            y2: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: 5 },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.7 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak1' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: -5 },
                            },
                            y2: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: 5 },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeWidth: { value: 2 },
                            strokeOpacity: { value: 0.7 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak0' },
                            },
                            y2: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak1' },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.7 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: -5 },
                            },
                            x2: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: 5 },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak0' },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'rule',
                    encode: {
                        enter: {
                            strokeOpacity: { value: 0.7 },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: -5 },
                            },
                            x2: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                                offset: { value: 5 },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak1' },
                            },
                            stroke: {
                                scale: 'pitchColor',
                                field: { parent: 'pitchType' },
                            },
                        },
                    },
                },
                {
                    type: 'symbol',
                    encode: {
                        update: {
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                            },
                            fill: { scale: 'pitchColor', field: { parent: 'pitchType' } },
                            size: { signal: '500 * chartScale' },
                        },
                    },
                },
                {
                    type: 'text',
                    encode: {
                        enter: {
                            fill: { value: '#ffffff' },
                            align: { value: 'center' },
                            baseline: { value: 'middle' },
                        },
                        update: {
                            fontSize: { signal: '14 * chartScale' },
                            text: { field: { parent: 'pitchType' }, scale: 'pitchLabel' },
                            x: {
                                scale: 'x',
                                field: { parent: 'horizontalBreak' },
                            },
                            y: {
                                scale: 'y',
                                field: { parent: 'inducedVerticalBreak' },
                                offset: { value: 1 },
                            },
                        },
                    },
                },
            ],
        },
        {
            name: 'hiddenMarks',
            type: 'symbol',
            from: { data: 'hiddenData' },
            clip: true,
            interactive: false,
            encode: {
                enter: {
                    stroke: { value: '#000000' },
                    strokeWidth: { value: 1 },
                    strokeOpacity: { value: 0.1 },
                    strokeDash: { value: [2, 2] },
                },
                update: {
                    x: { scale: 'x', field: 'horizontalBreak' },
                    y: { scale: 'y', field: 'inducedVerticalBreak' },
                    size: { signal: 'pitchSize * chartScale' },
                    opacity: { signal: 'showPitches ? 1 : 0' },
                },
            },
        },
        {
            name: 'visibleMarks',
            type: 'symbol',
            from: { data: 'visibleData' },
            clip: true,
            encode: {
                enter: { strokeWidth: { value: 1 } },
                update: {
                    x: { scale: 'x', field: 'horizontalBreak' },
                    y: { scale: 'y', field: 'inducedVerticalBreak' },
                    fill: {
                        scale: 'pitchColor',
                        signal: `(brushedIds && indexof(brushedIds, datum.id) !== -1) || (selectedIds && indexof(selectedIds, datum.id) !== -1) || (!selectedIds && !brushedIds) ? datum.pitchType : 'Unknown'`,
                    },
                    fillOpacity: {
                        signal: 'hoveredId == datum.id ? 1 : (brushedIds && indexof(brushedIds, datum.id) !== -1) || (selectedIds && indexof(selectedIds, datum.id) !== -1) || (!selectedIds && !brushedIds) ? 0.5 : 0.2',
                    },
                    // stroke: {
                    //     scale: 'pitchColor',
                    //     signal: "!brushedIds || length(brushedIds) == 0 || indexof(brushedIds, datum.id) != -1 ? datum.pitchType : 'Unknown'",
                    // },
                    // strokeOpacity: {
                    //     signal: '!brushedIds || length(brushedIds) === 0 || indexof(brushedIds, datum.id) == -1 ? 0 : 1',
                    // },
                    size: {
                        signal: 'hoveredId == datum.id ? 3 * pitchSize : pitchSize',
                    },
                    opacity: { signal: 'showPitches ? 1 : 0' },
                    tooltip: { signal: 'showPitches && !brushed ? datum : null' },
                    cursor: {
                        signal: "showPitches && isArray(datum.videos) && length(datum.videos) > 0 ? 'pointer' : 'default'",
                    },
                },
                // hover: {
                //     size: { signal: 'pitchSize * chartScale * 1.5' },
                //     fillOpacity: { value: 1 },
                // },
                // leave: {
                //     size: { signal: 'pitchSize * chartScale' },
                //     fillOpacity: { value: 0.5 },
                // },
            },
        },
        {
            name: 'brush',
            type: 'rect',
            interactive: false,
            encode: {
                enter: {
                    fill: { value: '#0080FF' },
                    fillOpacity: { value: 0.1 },
                    strokeWidth: { value: 1 },
                    stroke: { value: '#0080FF' },
                    strokeOpacity: { value: 0.3 },
                },
                update: {
                    x: { signal: 'brushStart.x' },
                    x2: { signal: 'brushEnd.x' },
                    y: { signal: 'brushStart.y' },
                    y2: { signal: 'brushEnd.y' },
                    fillOpacity: { signal: 'brushed ? 0.1 : 0' },
                    strokeOpacity: { signal: 'brushed ? 0.3 : 0' },
                },
            },
        },
        {
            name: 'hoveredMark',
            from: { data: 'hoveredData' },
            type: 'group',
            marks: [
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            strokeWidth: { value: 6 },
                        },
                        update: {
                            x: { scale: 'x', field: { parent: 'horizontalBreak' } },
                            y: { scale: 'y', field: { parent: 'inducedVerticalBreak' } },
                            stroke: { scale: 'pitchColor', field: { parent: 'pitchType' } },
                            fill: { scale: 'pitchColor', field: { parent: 'pitchType' } },
                            size: {
                                signal: '(hoveredId == parent.id ? 1.5 : 1) * (pitchSize * chartScale) * 1.8',
                            },
                        },
                    },
                },
                {
                    type: 'symbol',
                    interactive: false,
                    encode: {
                        enter: {
                            stroke: { value: 'white' },
                            strokeWidth: { value: 2 },
                        },
                        update: {
                            x: { scale: 'x', field: { parent: 'horizontalBreak' } },
                            y: { scale: 'y', field: { parent: 'inducedVerticalBreak' } },
                            size: {
                                signal: '(hoveredId == parent.id ? 1.5 : 1) * (pitchSize * chartScale) * 1.8',
                            },
                        },
                    },
                },
            ],
        },
    ],
};
