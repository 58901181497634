<div class="flex flex-row flex-wrap justify-center items-center">
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Strikes Added</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">
            {{ strikesAdded | stlcNumberToFixed : { digits: 1, showPosSign: true } }}
        </div>
    </div>
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Called Strikes</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">
            {{ calledStrikes | stlcNumberToFixed : { digits: 0, defaultText: '-' } }}
        </div>
    </div>
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Balls</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">
            {{ balls | stlcNumberToFixed : { digits: 0, defaultText: '-' } }}
        </div>
    </div>
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Caught Stealing</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">
            {{ caughtStealing }} / {{ stolenBaseAttempts }}
        </div>
    </div>
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Passed Balls</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">{{ passedBalls }}</div>
    </div>
    <div class="flex flex-col border border-gray-100 w-32 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50 whitespace-nowrap">Wild Pitches</div>
        <div class="flex justify-center items-center text-4xl h-16 p-2">{{ wildPitches }}</div>
    </div>
</div>
