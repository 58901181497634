import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, combineLatestWith, filter, finalize, map } from 'rxjs/operators';

import {
    PitchingGameLogFragment,
    PitchMovementLeagueFragment,
    PlayerSeasonType,
    ReviewsGetPitchingReviewGQL,
    ReviewsGetPlayerGamesContextGQL,
    ReviewsGetPlayerGamesGQL,
    ReviewsGetRebootReportGQL,
    ReviewsPitchAggregateFragment,
    ReviewsPlayerGameFragment,
    ReviewsPlayerGamePitchFragment,
    ReviewsPlayerPitchContextFragment,
} from '@stlc/game/reviews/data-access';
import type { ReviewsPlayer } from '@stlc/game/shared';
import { chain, filter as _filter, includes, map as _map, some } from '@stlc/lodash';
import { getPitchTypeDescription, getPitchTypeOrder } from '@stlc/lookup/legacy';
import {
    PitchMovementGetDataGQL,
    PlayerChartsPitchMovementFragment,
    StlcTimeFrame,
} from '@stlc/player-charts/data-access';
import { GamePitchingReportSummary, GamePitchingStolenBaseInfo } from '@stlc/reviews/shared';
import { UiAppService } from '@stlc/ui/services';

import { isBasicCaughtStealing, isSb } from './events';

@Injectable({ providedIn: 'root' })
export class ReviewsPitchingService {
    private readonly appService = inject(UiAppService);
    private readonly getPitchingReviewGQL = inject(ReviewsGetPitchingReviewGQL);
    private readonly pitchMovementGQL = inject(PitchMovementGetDataGQL);
    private readonly getGamesGQL = inject(ReviewsGetPlayerGamesGQL);
    private readonly getGamesContextGQL = inject(ReviewsGetPlayerGamesContextGQL);

    private pitchMovementEllipsesSubject = new BehaviorSubject<PitchMovementLeagueFragment[]>([]);
    private pitchMovementDataSubject = new BehaviorSubject<PlayerChartsPitchMovementFragment[]>([]);
    private gamePitchMovementDataSubject = new BehaviorSubject<PlayerChartsPitchMovementFragment[]>([]);
    private readonly reviewsGetRebootReportGQL = inject(ReviewsGetRebootReportGQL);

    private gamesSubject = new BehaviorSubject<ReviewsPlayerGameFragment[]>([]);
    readonly games$ = this.gamesSubject.asObservable();

    private contextPitchesSubject = new BehaviorSubject<ReviewsPlayerPitchContextFragment[]>([]);

    readonly rebootReports$ = this.gamesSubject.pipe(
        map((games) => chain(games).map('rebootReports').flatten().valueOf())
    );

    playerId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    playerThrows: string | undefined;
    player: ReviewsPlayer | undefined;

    pickoffAttemptCount = signal<number | undefined>(undefined);
    pitchAggregate = signal<ReviewsPitchAggregateFragment | undefined>(undefined);
    pitchingGameLog = signal<PitchingGameLogFragment | undefined>(undefined);

    pitchMovementData$ = this.pitchMovementDataSubject.asObservable();
    gamePitchMovementData$ = this.gamePitchMovementDataSubject.asObservable();
    pitchMovementEllipses$ = this.pitchMovementEllipsesSubject.asObservable();
    pitches$ = this.games$.pipe(map((games) => chain(games).map('pitches').compact().flatten().valueOf()));
    putAwayPitches$ = this.pitches$.pipe(map((pitches) => pitches.filter((pitch) => pitch.putAwayPitches > 0)));
    isUnverified$ = this.pitches$.pipe(map((pitches) => some(pitches, (pitch) => pitch.isUnverified)));

    pitches = toSignal(this.pitches$);
    putAwayPitches = toSignal(this.putAwayPitches$);

    stolenBasesPerAttemptRatio = computed((): GamePitchingStolenBaseInfo => {
        const pitches = this.pitches();
        let stolenBases = 0;
        let caughtStealing = 0;
        if (pitches) {
            pitches.forEach((pitch) => {
                if (isSb(pitch)) {
                    stolenBases += 1;
                }
                if (isBasicCaughtStealing(pitch)) {
                    caughtStealing += 1;
                }
            });
        }
        return { stolenBases, caughtStealing };
    });

    pitchAggregateExtended = computed((): GamePitchingReportSummary => {
        const aggregate = this.pitchAggregate();
        const pickoffAttempts = this.pickoffAttemptCount();
        const stolenBases = this.stolenBasesPerAttemptRatio();
        const pitchingGameLog = this.pitchingGameLog();
        return {
            ...aggregate,
            ...stolenBases,
            leadoffPlateAppearances: pitchingGameLog.leadoffPlateAppearances,
            leadoffBaseOnBalls: pitchingGameLog.leadoffBaseOnBalls,
            leadoffBaseOnBallsWithRun: pitchingGameLog.leadoffBaseOnBallsWithRun,
            leadoffOuts: pitchingGameLog.leadoffOuts,
            pickoffAttempts,
        };
    });

    putAwayMetrics = computed(() => {
        const rhbPitches = _filter(this.putAwayPitches(), (pitch) => pitch.batterBats === 'Right');
        const lhbPitches = _filter(this.putAwayPitches(), (pitch) => pitch.batterBats === 'Left');
        if (rhbPitches || lhbPitches) {
            return { rhb: this.calculatePutAwayMetrics(rhbPitches), lhb: this.calculatePutAwayMetrics(lhbPitches) };
        }
        return { rhb: {}, lhb: {} };
    });

    private readonly pitchTypes$ = this.pitches$.pipe(
        map((pitches) =>
            chain(pitches)
                .uniqBy('pitchType')
                .map('pitchType')
                .compact()
                .orderBy((datum) => getPitchTypeOrder(datum))
                .valueOf()
        )
    );

    readonly contextPitches$ = this.contextPitchesSubject.asObservable().pipe(
        combineLatestWith(this.pitchTypes$),
        map(([pitches, pitchTypes]) => _filter(pitches, (datum) => includes(pitchTypes, datum.pitchType)))
    );

    readonly lgPitchMovementEllipses$ = combineLatest([this.pitchMovementEllipses$, this.pitchTypes$]).pipe(
        map(([ellipses, pitchTypes]) => {
            ellipses = chain(ellipses)
                .filter(
                    (datum) =>
                        (datum.armAngle === 'all' || datum.armAngle === this.armAngle) &&
                        includes(pitchTypes, datum.pitchType)
                )
                .valueOf();

            return _map(pitchTypes, (pitchType) => ({
                pitchType: getPitchTypeDescription(pitchType),
                ellipseData: chain(ellipses)
                    .filter((datum) => datum.pitchType === getPitchTypeDescription(pitchType))
                    .map((datum) => ({
                        ...datum,
                        coordinateType: datum.armAngle === 'all' ? 'ellipse' : 'ellipse_context',
                        pitchType: getPitchTypeDescription(datum.pitchType),
                    }))
                    .valueOf(),
            }));
        })
    );

    readonly pitchMovementAvgData$ = combineLatest([this.pitchMovementData$, this.pitchTypes$]).pipe(
        map(([pitchMovementData, pitchTypes]) =>
            _filter(pitchMovementData, (datum) => includes(pitchTypes, datum.pitchType))
        )
    );

    armAngle: string | undefined;
    armAngleKey: string | undefined;

    loadRebootReport(id: number) {
        this.reviewsGetRebootReportGQL
            .fetch({ id })
            .pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => data?.rebootReport)
            )
            .subscribe(({ reportUrl }) => {
                window.open(reportUrl, '_blank');
            });

        return false;
    }

    loadData(options: { player: ReviewsPlayer; startDate: string; endDate: string }): Observable<boolean> {
        this.player = options.player;
        this.gamePitchMovementDataSubject.next([]);
        this.pitchMovementDataSubject.next([]);

        const {
            player: { id: playerId, throws: playerThrows, armAngle },
            startDate,
            endDate,
        } = options;
        this.playerId = playerId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.playerThrows = playerThrows;
        this.armAngle = armAngle;
        if (this.armAngle) {
            this.armAngleKey = this.getArmAngleKey(this.armAngle);
        }
        this.appService.loading = true;
        const where = {
            pitchers: [playerId],
            timeFrame: { type: StlcTimeFrame.DateRange, startDate, endDate },
        };

        return combineLatest([
            this.getPitchingReviewGQL.fetch({ playerId, playerThrows, where }).pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => {
                    if (!data) {
                        return false;
                    }
                    this.pitchAggregate.set(data.pitchAggregate[0]);
                    this.pitchMovementEllipsesSubject.next(data.pitchMovementEllipses);
                    this.pickoffAttemptCount.set(data.pickoffAttempts?.length);

                    return true;
                })
            ),
            this.pitchMovementGQL
                .fetch({
                    where: {
                        playerId,
                        timeFrame: where.timeFrame,
                    },
                })
                .pipe(
                    filter(({ loading }) => !loading),
                    map(({ data }) => {
                        if (!data) {
                            return false;
                        }

                        this.gamePitchMovementDataSubject.next(data.pitchMovementData);

                        return true;
                    })
                ),
            this.getGamesGQL
                .fetch({
                    seasonType: PlayerSeasonType.Pitching,
                    where: {
                        playerId,
                        timeFrame: { date: startDate, type: StlcTimeFrame.Date },
                        affiliated: true,
                        unaffiliated: true,
                        amateur: true,
                        offseason: true,
                        excludeSpringTraining: false,
                        excludeBackfield: false,
                        excludeExhibition: false,
                        excludeRehab: false,
                    },
                })
                .pipe(
                    filter(({ loading }) => !loading),
                    map(({ data }) => {
                        if (!data) {
                            return false;
                        }
                        this.gamesSubject.next(data.games);

                        const pitchingGameLogs = chain(data.games)
                            .map((game) => game.pitchingGameLogs)
                            .reduce(
                                (result: PitchingGameLogFragment, datum) => {
                                    datum.forEach((log) => {
                                        for (const key in log) {
                                            if (Object.prototype.hasOwnProperty.call(result, key)) {
                                                result[key] += log[key];
                                            } else {
                                                result[key] = log[key];
                                            }
                                        }
                                    });

                                    return result;
                                },
                                {
                                    leadoffPlateAppearances: 0,
                                    leadoffBaseOnBalls: 0,
                                    leadoffOuts: 0,
                                    leadoffBaseOnBallsWithRun: 0,
                                }
                            )
                            .valueOf();
                        this.pitchingGameLog.set(pitchingGameLogs);

                        return true;
                    })
                ),
        ]).pipe(
            map(([review, gameMovement, games]) => review && gameMovement && games),
            catchError(() => of(false)),
            finalize(() => {
                this.appService.loading = false;

                this.getGamesContextGQL
                    .fetch({
                        seasonType: PlayerSeasonType.Pitching,
                        where: {
                            playerId,
                            timeFrame: { date: startDate, type: StlcTimeFrame.SeasonBeforeDate },
                            affiliated: true,
                            unaffiliated: true,
                            amateur: true,
                            offseason: true,
                            excludeSpringTraining: false,
                            excludeBackfield: false,
                            excludeExhibition: false,
                            excludeRehab: false,
                        },
                    })
                    .pipe(map(({ data }) => data?.pitches ?? []))
                    .subscribe((data) => {
                        this.contextPitchesSubject.next(data);
                    });

                this.pitchMovementGQL
                    .fetch({
                        where: {
                            playerId,
                            timeFrame: {
                                type: StlcTimeFrame.Year,
                                year: new Date(startDate).getFullYear(),
                            },
                        },
                    })
                    .pipe(map(({ data }) => data?.pitchMovementData ?? []))
                    .subscribe((data) => {
                        this.pitchMovementDataSubject.next(data);
                    });
            })
        );
    }

    private getArmAngleKey(armAngle: string): string {
        switch (armAngle) {
            case '3/4':
                return 'threeQuarters_label';
            case 'High 3/4':
                return 'highThreeQuarters_label';
            case 'Low 3/4':
                return 'lowThreeQuarters_label';
            default:
                return `${armAngle.toLowerCase()}_label`;
        }
    }

    calculatePutAwayMetrics(pitches: ReviewsPlayerGamePitchFragment[]) {
        const count = pitches.length;
        let outOfZonePitches = 0;
        let outOfZoneSwings = 0;
        let inZonePitches = 0;
        let swings = 0;
        let contacts = 0;
        pitches.forEach((pitch) => {
            outOfZonePitches += pitch.outOfZonePitches ?? 0;
            outOfZoneSwings += pitch.outOfZoneSwings ?? 0;
            inZonePitches += pitch.inZonePitches ?? 0;
            swings += pitch.swings ?? 0;
            contacts += pitch.contacts ?? 0;
        });
        return {
            outOfZoneSwingRate: outOfZonePitches ? (outOfZoneSwings / outOfZonePitches) * 100 : undefined,
            inZoneRate: count ? (inZonePitches / count) * 100 : undefined,
            swingAndMissRate: swings ? (1 - contacts / swings) * 100 : undefined,
        };
    }
}
