import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { I18NEXT_SERVICE } from 'angular-i18next';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs';
import type { Spec } from 'vega';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPitchTypePipe } from '@stlc/angular/pipes/pitch-type';
import { ReviewsPlayerGamePitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, head, isEmpty, isNil } from '@stlc/lodash';
import { videoStrikeZoneSpec } from '@stlc/player-charts/ui';
import { ReviewsPitchingTooltipComponent } from '@stlc/reviews/ui';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiVegaBrushingService, UiVegaChartComponent } from '@stlc/ui/vega';
import { UiVideoDialogComponent } from '@stlc/ui/video';

import { pitchingAtBatsStrikeZoneSpec } from '../../vega/pitching-at-bats-strike-zone-spec.vega';

import { ReviewsAtBat, ReviewsPitchingAtBatsService } from './reviews-pitching-at-bats.service';

@Component({
    selector: 'stlc-reviews-pitching-at-bats',
    templateUrl: './reviews-pitching-at-bats.component.html',
    styleUrls: ['./reviews-pitching-at-bats.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        ReviewsPitchingTooltipComponent,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPitchTypePipe,
        StlcI18nModule,
        UiVegaChartComponent,
    ],
    providers: [ReviewsPitchingAtBatsService, UiVegaBrushingService],
})
export class ReviewsPitchingAtBatsComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly brushingService = inject(UiVegaBrushingService);
    readonly reviewsPitchingAtBatsService = inject(ReviewsPitchingAtBatsService);
    private readonly videoStrikeZoneSpec: Spec = videoStrikeZoneSpec;
    private readonly videoDialog = inject(UiDialogService);
    private readonly i18next = inject(I18NEXT_SERVICE);
    readonly strikeZoneSpec: Spec = pitchingAtBatsStrikeZoneSpec;

    signals: {
        perspective: string;
        pitchSize: number;
        pitchNumberField: string;
        showPitchNumbers?: boolean;
        filteredIds?: string[];
    } = {
        perspective: 'pitcher',
        pitchNumberField: 'atbatPitchNumber',
        pitchSize: 300,
        showPitchNumbers: true,
    };

    signalListeners = {
        // clickedPitch: (name: string, { datum }: { datum: ReviewsPlayerGamePitchFragment }) => {
        //     if (datum) {
        //         this.openVideoDialog(datum);
        //     }
        // },
    };

    ngOnInit() {
        this.brushingService.selectedId$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((selectedId) => !isNil(selectedId))
            )
            .subscribe((selectedId) => {
                this.openVideoDialog(selectedId);
            });
    }

    openVideoDialogForAtBat(atBat: ReviewsAtBat) {
        this.openVideoDialog(
            chain(atBat.pitches)
                .find((id) => !id)
                .get('id')
                .valueOf()
        );
    }

    openVideoDialog(pitchId: string) {
        if (this.reviewsPitchingAtBatsService.videoEvents$) {
            this.reviewsPitchingAtBatsService.videoEvents$.pipe(take(1)).subscribe((videoEvents) => {
                if (isEmpty(videoEvents)) {
                    return;
                }
                this.videoDialog.open(UiVideoDialogComponent, {
                    data: {
                        charts: [
                            {
                                name: 'Location',
                                spec: this.videoStrikeZoneSpec,
                            },
                        ],
                        selectedEvent: pitchId,
                        eventGroups: chain(videoEvents)
                            .groupBy('eventGroup')
                            .map((value) => value)
                            .valueOf(),
                        previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                        nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
                        selectedEventType: 'pitches',
                    },
                    panelClass: 'stlc-video-dialog',
                });
            });
        }
    }
}
