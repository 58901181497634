import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import type { Spec } from 'vega';

import { StlcI18nModule } from '@stlc/i18n/core';
import { chain, find, isArray, isEmpty, isEqual, isNil, map as _map, set, transform } from '@stlc/lodash';
import { getStatColumn } from '@stlc/lookup/stat';
import { PlayerStatsPitchesTableComponent } from '@stlc/player-stats/ui';
import { ReviewsCatchingTooltipComponent } from '@stlc/reviews/ui';
import { StlcSelectOption, StlcStat, UiTableColumn, UiTableDisplayedColumn } from '@stlc/shared';
import { UiChipListComponent, UiChipSelectComponent, UiIconDropdownComponent } from '@stlc/ui';
import { UiTableModule, UiTableRowType } from '@stlc/ui/table';
import { UiVegaBrushingService, UiVegaChartComponent } from '@stlc/ui/vega';
import { UserService } from '@stlc/user';

import { ReviewsService } from '../../services/reviews.service';
import { ReviewsCatchingService } from '../../services/reviews-catching.service';
import { ReviewsCatchingFramingService, StrikeZoneChartOption } from '../../services/reviews-catching-framing.service';
import { catchingStrikeZoneFramingSpec } from '../../vega/catching-strike-zone-framing-spec.vega';

@Component({
    selector: 'stlc-reviews-catching-framing',
    templateUrl: './reviews-catching-framing.component.html',
    styleUrls: ['./reviews-catching-framing.component.scss'],
    providers: [UiVegaBrushingService, ReviewsCatchingFramingService],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        UiVegaChartComponent,
        StlcI18nModule,
        PlayerStatsPitchesTableComponent,
        ReviewsCatchingTooltipComponent,
        UiIconDropdownComponent,
        UiChipSelectComponent,
        UiChipListComponent,
        UiTableModule,
    ],
})
export class ReviewsCatchingFramingComponent implements OnInit, OnDestroy {
    readonly reviewsService = inject(ReviewsService);
    readonly service = inject(ReviewsCatchingFramingService);
    private readonly router = inject(Router);
    private readonly route = inject(ActivatedRoute);
    private readonly vegaBrushingService = inject(UiVegaBrushingService);
    private readonly userService = inject(UserService);
    readonly catchingService = inject(ReviewsCatchingService);

    perspective: 'batter' | 'pitcher' = 'batter';
    private readonly perspectivePreferenceKey = 'reviews:catching:perspective';

    // pitches chart
    pitchesSpec: Spec = catchingStrikeZoneFramingSpec;
    pitchesSignals: {
        filteredIds: string[];
        calledStrikeZoneCaption: string;
        brushedIds: string[] | null;
        perspective: 'batter' | 'pitcher';
    } = {
        filteredIds: null,
        brushedIds: null,
        calledStrikeZoneCaption: null,
        perspective: 'batter',
    };
    pitchesSignalListeners = {
        binSelected: (_name, binSelected) => {
            this.vegaBrushingService.selectedIds = binSelected?.values?.map(({ id }) => id);
        },
    };

    chartSelected: StrikeZoneChartOption = 'heatmap';

    // for defining the summary table
    summaryTableColumns: UiTableColumn[] = [];
    summaryTableDisplayedColumns: UiTableDisplayedColumn[] = [];
    summaryTableTotals: { numberOfPitches: number; strikesAdded: number } = { numberOfPitches: 0, strikesAdded: 0 };

    readonly pitchesData$ = combineLatest([this.service.pitches$, this.catchingService.strikeZoneBorderPoints$]).pipe(
        map(([data, strikeZoneBorderPoints]) => ({
            data,
            strikeZoneBorderPoints,
        }))
    );

    private readonly destroy = new Subject<void>();

    // for determining the class of the row in the table of pitches
    readonly rowClassFn = (rowIndex, _rowData, rowType) => {
        const classes = [];
        if (rowIndex % 2 === 0) {
            classes.push('stlc-striped');
        }

        if (rowType === UiTableRowType.Description) {
            classes.push('border-b-2', 'border-gray-100');
        }

        return classes;
    };

    // for determining whether the row is the totals row for the summary table
    readonly summaryRowClassFn = (rowIndex, rowData) => (rowData?.isFooter ? 'font-bold' : '');

    constructor() {
        this.reviewsService.selectedTab = 'framing';

        this.summaryTableColumns = [
            {
                ...getStatColumn(StlcStat.PitchName),
                format: {
                    type: 'i18nPitchType',
                    source: 'legacy',
                    defaultText: '',
                },
                minWidth: 75,
            },
            {
                ...getStatColumn(StlcStat.NumberOfPitches),
                header: '#',
                divider: true,
                minWidth: 50,
            },
            getStatColumn(StlcStat.StrikesAdded),
        ];

        this.summaryTableDisplayedColumns = [StlcStat.PitchName, StlcStat.NumberOfPitches, StlcStat.StrikesAdded];
    }

    ngOnInit() {
        this.route.queryParams
            .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy))
            .subscribe((queryParams) => (this.service.queryParams = queryParams));

        combineLatest([this.service.filteredPitches$])
            .pipe(takeUntil(this.destroy))
            .subscribe(([filteredPitches]) => {
                const filteredIds = _map(filteredPitches, 'id');
                this.pitchesSignals = {
                    ...this.pitchesSignals,
                    calledStrikeZoneCaption: this.catchingService.calledStrikeZoneCaption,
                    filteredIds,
                };
            });

        this.service.visiblePitches$.pipe(takeUntil(this.destroy)).subscribe((visiblePitches) => {
            this.summaryTableTotals = {
                numberOfPitches: chain(visiblePitches).map('numberOfPitches').sum().value(),
                strikesAdded: chain(visiblePitches).map('strikesAdded').sum().value(),
            };
        });

        this.vegaBrushingService.selectedId$
            .pipe(takeUntil(this.destroy), withLatestFrom(this.service.visiblePitches$))
            .subscribe(([selectedId, visiblePitches]) => {
                const pitch = find(visiblePitches, ({ id }) => id === selectedId);
                if (pitch) {
                    this.service.openVideoDialog(pitch);
                }
            });

        this.userService
            .getPreferenceValue$(this.perspectivePreferenceKey)
            .pipe(take(1))
            .subscribe((perspective?: 'pitcher' | 'batter') => {
                if (perspective) {
                    this.perspective = perspective;
                    this.pitchesSignals = {
                        ...this.pitchesSignals,
                        perspective,
                    };
                }
            });
    }

    ngOnDestroy() {
        this.destroy.next();
    }

    handleChartToggle() {
        this.chartSelected = this.chartSelected === 'pitches' ? 'heatmap' : 'pitches';
        this.vegaBrushingService.signal({
            binSelected: null,
            showHeatmapData: this.chartSelected === 'pitches',
        });
    }

    handleFilterChange(
        options: StlcSelectOption<string>[] | StlcSelectOption<string> | undefined,
        filterId: string
    ): void {
        let values = [];
        if (isNil(options)) {
            values = [];
        } else if (isArray(options)) {
            values = _map(options, 'value');
        } else {
            values = [options.value];
        }

        const queryParams = {};
        set(queryParams, filterId, !isEmpty(values) ? values : null);

        this.vegaBrushingService.signal('binSelected', null);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }

    changePerspective(perspective: 'batter' | 'pitcher') {
        this.perspective = perspective;
        this.pitchesSignals = {
            ...this.pitchesSignals,
            perspective,
        };
        this.userService.updatePreference(this.perspectivePreferenceKey, perspective);
    }

    resetFilters() {
        this.vegaBrushingService.signal('binSelected', null);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: transform(
                this.service.filters,
                (result, datum) => {
                    set(result, datum.id, null);
                },
                {}
            ),
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }
}
