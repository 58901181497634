import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { filter, map, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { videoStrikeZoneSpec } from '@stlc/advance-scouting/ui';
import { StlcNumberToFixedPipe, StlcNumberToOrdinalPipe } from '@stlc/angular/pipes';
import { StlcElementResizeService } from '@stlc/angular/services';
import type { ReviewsPlayerGamePitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nGameEventPipe, StlcI18nModule } from '@stlc/i18n/core';
import { chain, find, head, isEmpty, isNil } from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { BatterBatsStrikeZonesComponent } from '@stlc/player-charts/ui';
import {
    ReviewsPitchingGameStatsComponent,
    ReviewsPitchingReportByBatterComponent,
    ReviewsPitchingReportByCountComponent,
    ReviewsPitchingReportByPitchSummaryComponent,
    ReviewsPitchingReportPitchMovementComponent,
    ReviewsPitchingReportSummaryComponent,
} from '@stlc/reviews/ui';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiTableModule } from '@stlc/ui/table';
import { UiVegaBrushingService } from '@stlc/ui/vega';
import { UiVideoDialogComponent } from '@stlc/ui/video';

import { ReviewsPitchingService } from '../../services/reviews-pitching.service';

@Component({
    selector: 'stlc-reviews-pitching-summary',
    templateUrl: './reviews-pitching-summary.component.html',
    styleUrls: ['./reviews-pitching-summary.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        BatterBatsStrikeZonesComponent,
        ReviewsPitchingReportByBatterComponent,
        ReviewsPitchingReportByCountComponent,
        ReviewsPitchingReportByPitchSummaryComponent,
        ReviewsPitchingReportPitchMovementComponent,
        ReviewsPitchingReportSummaryComponent,
        ReviewsPitchingGameStatsComponent,
        StlcNumberToFixedPipe,
        StlcI18nModule,
        UiTableModule,
    ],
    providers: [UiVegaBrushingService, StlcNumberToOrdinalPipe, StlcI18nGameEventPipe],
})
export class ReviewsPitchingSummaryComponent implements OnInit, OnDestroy {
    readonly service = inject(ReviewsPitchingService);
    private readonly dialog = inject(UiDialogService);
    private readonly elementResize = inject(StlcElementResizeService);
    private readonly i18next = inject(I18NEXT_SERVICE);
    private readonly ordinalPipe = inject(StlcNumberToOrdinalPipe);
    private readonly brushingService = inject(UiVegaBrushingService);
    private readonly stlcI18nGameEventPipe = inject(StlcI18nGameEventPipe);
    priv;

    #pitchMovementContainer: ElementRef | undefined;
    @ViewChild('pitchMovementContainer', { static: false })
    set pitchMovementContainer(ref: ElementRef) {
        if (ref !== this.#pitchMovementContainer) {
            if (isNil(ref)) {
                this.removePitchMovementResizeListener();
            } else {
                this.#pitchMovementContainer = ref;
                this.addPitchMovementResizeListener();
            }
        }
    }

    pitchMovementSignals: { [key: string]: any } = {
        showPitches: false,
        pitcherThrowsLeft: this.service.playerThrows === 'L',
    };

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        this.brushingService.selectedId$
            .pipe(
                takeUntil(this.destroy),
                filter((selectedId) => !isNil(selectedId)),
                withLatestFrom(this.service.pitches$),
                map(([selectedId, pitches]) => find(pitches, ({ id }) => id === selectedId)),
                filter((pitch) => pitch && !isEmpty(pitch.videos)),
                withLatestFrom(this.service.pitches$)
            )
            .subscribe(([pitch, pitches]) => {
                this.openVideoDialog({ selectedPitchId: pitch.id, pitches });
            });
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.removePitchMovementResizeListener();
    }

    private addPitchMovementResizeListener() {
        if (this.#pitchMovementContainer) {
            this.elementResize.addResizeEventListener(this.#pitchMovementContainer.nativeElement, (el: HTMLElement) => {
                const newWidth = Math.min(el.clientWidth, 400);
                this.pitchMovementSignals = {
                    ...this.pitchMovementSignals,
                    width: newWidth,
                };
            });
        }
    }

    private removePitchMovementResizeListener() {
        if (this.#pitchMovementContainer) {
            this.elementResize.removeResizeEventListener(this.#pitchMovementContainer.nativeElement);
        }
    }

    openVideoDialog(data: { selectedPitchId: string; pitches: ReviewsPlayerGamePitchFragment[] }): void {
        const videoEvents = chain(data.pitches)
            .map((datum) => ({
                id: datum.id,
                eventGroup: `${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
                title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
                subtitles: [
                    datum.inning
                        ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(datum.inning)}`
                        : '',
                    `${datum.preBalls}-${datum.preStrikes} ${
                        datum?.preOuts ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 }) : ''
                    }`,
                    getPitchTypeCode(datum.pitchType)
                        ? this.i18next.t(`pitchType:${getPitchTypeCode(datum.pitchType)}_label`)
                        : 'Unknown',
                    datum?.pitchOutcome
                        ? this.stlcI18nGameEventPipe.transform({
                              isFinalPlay: datum.isFinalPlay,
                              isNextPlay: datum.isNextPlay,
                              pitchResult: datum?.pitchResult,
                              eventType: datum?.eventType,
                              battedBallType: datum?.battedBallType,
                          })
                        : '',
                ],
                pitch: {
                    ...datum,
                    pitchType: getPitchTypeCode(datum.pitchType),
                    description: this.stlcI18nGameEventPipe.transform(datum),
                },
                date: datum.gameDate ? dayjs(datum.gameDate).format('dddd, MMMM D, YYYY') : undefined,
                description: this.stlcI18nGameEventPipe.transform(datum),
                playByPlay: datum?.playByPlayDescription || '',
                videos: datum?.videos || [],
                types: ['pitches'],
            }))
            .flatten()
            .valueOf();

        this.dialog.open(UiVideoDialogComponent, {
            data: {
                charts: [
                    {
                        name: 'Location',
                        spec: videoStrikeZoneSpec,
                    },
                ],
                selectedEvent: data.selectedPitchId
                    ? videoEvents.filter((video) => video.id === data.selectedPitchId)[0].id
                    : head(videoEvents),
                eventGroups: chain(videoEvents)
                    .groupBy('eventGroup')
                    .map((value) => value)
                    .valueOf(),
                selectedEventType: 'pitches',
                showRepeatButton: true,
                showPlaybackRate: true,
                previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
            },
            panelClass: 'stlc-video-dialog',
        });
    }
}
