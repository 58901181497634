<ng-container *ngIf="!!goals && goals.length > 0">
    <div *ngFor="let goal of goals" class="flex flex-col w-28 border border-gray-100 m-1 rounded bg-white">
        <div class="text-center text-base p-2 font-bold border-b border-gray-50">
            {{ goal.headingKey ? (goal.headingKey | i18next : { defaultValue: goal.heading }) : goal.heading }}
        </div>
        <div
            class="flex justify-center items-center text-4xl h-16 p-2"
            [ngSwitch]="goal.formatter"
            [stlcHotCold]="getPercentile(goal)"
            [stlcHotColdOptions]="hotColdOptions"
            [preference]="goal.preference"
        >
            <span *ngSwitchCase="'number'">
                {{ goalDataMap[goal.stat] | stlcNumberToFixed : goal.formatterOptions }}
            </span>
            <span *ngSwitchDefault>
                {{ goalDataMap[goal.stat] }}
            </span>
        </div>
        <div
            *ngIf="goal.goal || goal.leagueAverage"
            class="flex flex-col justify-center items-center text-center text-xs text-gray-400 border-t border-gray-50 p-2"
        >
            <ng-container *ngIf="goal.goal" [ngSwitch]="goal.formatter">
                <span
                    *ngSwitchCase="'number'"
                    [innerHTML]="
                        'reviews:gameSummaryMetrics.goal'
                            | i18next
                                : {
                                      value: (goal.goal | stlcNumberToFixed : goal.formatterOptions),
                                      direction: goal.preference === 'high' ? 'more' : 'less'
                                  }
                    "
                >
                </span>
                <span
                    *ngSwitchDefault
                    [innerHTML]="
                        'reviews:gameSummaryMetrics.goal'
                            | i18next
                                : {
                                      value: goal.goal,
                                      direction: goal.preference === 'high' ? 'more' : 'less'
                                  }
                    "
                >
                </span>
            </ng-container>
            <span
                *ngIf="goal.leagueAverage"
                class="tracking-tight"
                [innerHTML]="'reviews:gameSummaryMetrics.leagueAverage' | i18next : { value: goal.leagueAverage }"
            ></span>
        </div>
    </div>
</ng-container>
