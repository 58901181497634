import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';

@Component({
    selector: 'stlc-reviews-catching-tooltip',
    templateUrl: './reviews-catching-tooltip.component.html',
    styleUrls: ['./reviews-catching-tooltip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        StlcFeetAndInchesPipe,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPlayerNamePipe,
    ],
})
export class ReviewsCatchingTooltipComponent {
    @Input()
    pitch: RbdReviewPitchFragment;

    @Input()
    showPitcher = true;
}
