import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import dayjs from 'dayjs';
import { catchError, combineLatest, of, switchMap } from 'rxjs';

import type { ReviewsPlayer } from '@stlc/game/shared';
import { some } from '@stlc/lodash';
import { UiAppService } from '@stlc/ui/services';

import { ReviewsService } from '../services/reviews.service';
import { ReviewsPitchingService } from '../services/reviews-pitching.service';

export const reviewsPitchingLoadDataResolver: ResolveFn<boolean> = (route) => {
    const appService = inject(UiAppService);
    const router = inject(Router);
    const reviewsPitchingService = inject(ReviewsPitchingService);
    const reviewsService = inject(ReviewsService);
    const REVIEWS_URL_TREE = router.createUrlTree(['game-reviews']);
    let player: ReviewsPlayer | undefined;
    let gameDateOrRange: string | undefined;
    let ref: ActivatedRouteSnapshot | null = route;
    while (ref) {
        if (!player) {
            player = ref.data['player'];
        }

        if (!gameDateOrRange) {
            gameDateOrRange = ref.params['gameDateOrRange'];
        }

        if (player && gameDateOrRange) {
            break;
        }
        ref = ref.parent;
    }

    const [startDate, endDate = startDate] = gameDateOrRange.split('_');
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
        appService.error = 'Review game dates not found';
        router.navigateByUrl(REVIEWS_URL_TREE);
        return of(true);
    }

    if (!player || !startDate || !endDate || !gameDateOrRange) {
        appService.error = 'Review info not foun1';
        router.navigateByUrl(REVIEWS_URL_TREE);
        return of(true);
    }

    return combineLatest([
        reviewsPitchingService.loadData({ player, startDate, endDate }),
        reviewsService.loadReviewTypes({ player, startDate, endDate }),
    ]).pipe(
        switchMap(([isLoaded, reviewTypes]) => {
            if (!isLoaded) {
                appService.error = `Could not load date for review`;
                router.navigateByUrl(REVIEWS_URL_TREE);
            } else if (!some(reviewTypes, ({ type }) => type === 'pitching')) {
                router.navigateByUrl(REVIEWS_URL_TREE);
            }
            return of(true);
        }),
        catchError(() => {
            appService.error = 'An unexpected error occurred. Contact support for assistance.';
            router.navigateByUrl(REVIEWS_URL_TREE);
            return of(true);
        })
    );
};

function isValidDate(date: string, format = 'YYYY-MM-DD'): boolean {
    return dayjs(date, format).format(format) === date;
}
