import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';

import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import {
    PlayerSeasonType,
    ReviewBattingGameLogFragment,
    ReviewsBattingAggregateSummaryFragment,
    ReviewsGetBattingReviewGoalsGQL,
    ReviewsGetBattingReviewGQL,
    ReviewsGetBattingReviewSpecsGQL,
    ReviewsGetPlayerGamesGQL,
    ReviewsGetRebootReportGQL,
    ReviewsGoalFragment,
    ReviewsPlayerGameFragment,
    ReviewsPlayerGamePitchFragment,
    StlcTimeFrame,
    SwingDecisionEllipseFragment,
} from '@stlc/game/reviews/data-access';
import type { ReviewsPlayer } from '@stlc/game/shared';
import { chain, compact, filter as _filter, isNil, some } from '@stlc/lodash';
import { getPitchTypeOrder } from '@stlc/lookup/statcast';
import { UiAppService } from '@stlc/ui/services';

@Injectable({ providedIn: 'root' })
export class ReviewsBattingService {
    private readonly appService = inject(UiAppService);
    private readonly getBattingReviewGQL = inject(ReviewsGetBattingReviewGQL);
    private readonly getBattingReviewSpecsGQL = inject(ReviewsGetBattingReviewSpecsGQL);
    private readonly getBattingReviewGoalsGQL = inject(ReviewsGetBattingReviewGoalsGQL);
    private readonly reviewsGetBattingGames = inject(ReviewsGetPlayerGamesGQL);
    private readonly reviewsGetRebootReportGQL = inject(ReviewsGetRebootReportGQL);

    games$: Observable<ReviewsPlayerGameFragment[]>;
    gamesSubject = new BehaviorSubject<ReviewsPlayerGameFragment[]>([]);
    set games(val: ReviewsPlayerGameFragment[]) {
        this.gamesSubject.next(val);
    }
    readonly pitches$: Observable<(ReviewsPlayerGamePitchFragment & { tooltip?: string })[]>;
    readonly rebootReports$ = this.gamesSubject.pipe(
        map((games) => chain(games).map('rebootReports').flatten().valueOf())
    );
    swingDecisionEllipses: SwingDecisionEllipseFragment[] = [];
    toFixedPipe = new StlcNumberToFixedPipe();

    playerId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;

    readonly gameLogs$: Observable<ReviewBattingGameLogFragment[]>;
    readonly summary$: Observable<ReviewsBattingAggregateSummaryFragment>;
    readonly goals$: Observable<ReviewsGoalFragment[]>;
    readonly blastGoals$: Observable<ReviewsGoalFragment[]>;
    readonly gameSummary$: Observable<ReviewsBattingAggregateSummaryFragment[]>;

    readonly pitchTypes$: Observable<string[]>;
    readonly hasEdgertronic$: Observable<boolean>;
    readonly isUnverified$: Observable<boolean | undefined | null>;
    readonly hasBatTracking$: Observable<boolean>;
    readonly showBlast$: Observable<boolean>;

    private gameLogsSubject = new BehaviorSubject<ReviewBattingGameLogFragment[]>([]);
    private summarySubject = new BehaviorSubject<ReviewsBattingAggregateSummaryFragment>(
        {} as ReviewsBattingAggregateSummaryFragment
    );
    private goalsSubject = new BehaviorSubject<ReviewsGoalFragment[]>([]);
    private blastGoalsSubject = new BehaviorSubject<ReviewsGoalFragment[]>([]);
    private gameSummarySubject = new BehaviorSubject<ReviewsBattingAggregateSummaryFragment[]>([]);

    constructor() {
        this.games$ = this.gamesSubject.asObservable();

        this.gameLogs$ = this.gameLogsSubject.asObservable();
        this.summary$ = this.summarySubject.asObservable();
        this.goals$ = this.goalsSubject.asObservable();
        this.blastGoals$ = this.blastGoalsSubject.asObservable();
        this.gameSummary$ = this.gameSummarySubject.asObservable();

        this.showBlast$ = this.gameLogs$.pipe(map((gameLogs) => some(gameLogs, 'showBlast')));

        this.pitches$ = combineLatest([this.games$, this.showBlast$]).pipe(
            map(([games, showBlast]) =>
                chain(games)
                    .map('pitches')
                    .compact()
                    .flatten()
                    .map((pitch) => ({
                        ...pitch,
                        tooltip: showBlast
                            ? compact([
                                  pitch?.batterBatSpeed
                                      ? this.toFixedPipe.transform(pitch.batterBatSpeed, {
                                            digits: 1,
                                            defaultText: '-',
                                            appendText: ' MPH',
                                        })
                                      : undefined,
                                  pitch?.batterCommitTime
                                      ? this.toFixedPipe.transform(pitch.batterCommitTime, {
                                            digits: 0,
                                            appendText: 'ms CT',
                                            operand: 1000,
                                            method: 'multiply',
                                            defaultText: '-',
                                        })
                                      : undefined,
                                  pitch?.batterPlanarEfficiency
                                      ? this.toFixedPipe.transform(pitch.batterPlanarEfficiency, {
                                            digits: 0,
                                            appendText: '% OP',
                                            operand: 100,
                                            method: 'multiply',
                                            defaultText: '-',
                                        })
                                      : undefined,
                                  pitch?.batterAttackAngle
                                      ? this.toFixedPipe.transform(pitch.batterAttackAngle, {
                                            digits: 1,
                                            appendText: '˚ AA',
                                            defaultText: '-',
                                        })
                                      : undefined,
                              ]).join(', ')
                            : undefined,
                    }))
                    .valueOf()
            )
        );

        this.isUnverified$ = this.summarySubject.pipe(map((datum) => datum?.isUnverified));

        this.pitchTypes$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .uniqBy('pitchType')
                    .map('pitchType')
                    .compact()
                    .orderBy((datum) => getPitchTypeOrder(datum))
                    .valueOf()
            )
        );

        this.hasEdgertronic$ = this.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .map('videos')
                    .flatten()
                    .some((video) => video.videoAngle?.startsWith('Edger'))
                    .valueOf()
            )
        );

        this.hasBatTracking$ = this.summary$.pipe(
            map(
                ({ batterBatSpeed, batterAttackAngle, batterPlanarEfficiency, batterCommitTime }) =>
                    !isNil(batterBatSpeed) ||
                    !isNil(batterAttackAngle) ||
                    !isNil(batterPlanarEfficiency) ||
                    !isNil(batterCommitTime)
            )
        );
    }

    set gameLogs(value: ReviewBattingGameLogFragment[]) {
        this.gameLogsSubject.next(value);
    }

    set summary(value: ReviewsBattingAggregateSummaryFragment) {
        this.summarySubject.next(value);
    }

    set goals(value: ReviewsGoalFragment[]) {
        this.goalsSubject.next(value);
    }

    set blastGoals(value: ReviewsGoalFragment[]) {
        this.blastGoalsSubject.next(value);
    }

    set gameSummary(value: ReviewsBattingAggregateSummaryFragment[]) {
        this.gameSummarySubject.next(value);
    }

    loadRebootReport(id: number) {
        this.reviewsGetRebootReportGQL
            .fetch({ id })
            .pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => data?.rebootReport)
            )
            .subscribe(({ reportUrl }) => {
                window.open(reportUrl, '_blank');
            });

        return false;
    }

    loadData(options: { player: ReviewsPlayer; startDate: string; endDate: string }): Observable<boolean> {
        const {
            player: { id: playerId },
            startDate,
            endDate,
        } = options;

        this.playerId = playerId;
        this.startDate = startDate;
        this.endDate = endDate;

        this.appService.loading = true;
        return combineLatest([
            this.getBattingReviewGQL.fetch({ playerId, startDate, endDate }).pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => {
                    if (!data) {
                        return false;
                    }
                    this.gameLogs = data.gameLogs;
                    this.summary = data.summary;
                    this.gameSummary = data.gameSummary;
                    return true;
                })
            ),
            this.getBattingReviewSpecsGQL.fetch().pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => {
                    if (!data) {
                        return false;
                    }

                    this.swingDecisionEllipses = data.swingDecisionEllipses;

                    return true;
                })
            ),
            this.getBattingReviewGoalsGQL.fetch().pipe(
                filter(({ loading }) => !loading),
                map(({ data }) => {
                    if (!data) {
                        return false;
                    }

                    this.goals = _filter(data.goals, { type: 'batting' });
                    this.blastGoals = _filter(data.goals, { type: 'blast' });

                    return true;
                })
            ),
            this.reviewsGetBattingGames
                .fetch({
                    seasonType: PlayerSeasonType.Batting,
                    where: {
                        playerId,
                        timeFrame: { date: startDate, type: StlcTimeFrame.Date },
                        affiliated: true,
                        unaffiliated: true,
                        amateur: true,
                        offseason: true,
                        excludeSpringTraining: false,
                        excludeBackfield: false,
                        excludeExhibition: false,
                        excludeRehab: false,
                    },
                })
                .pipe(
                    filter(({ loading }) => !loading),
                    map(({ data }) => {
                        if (!data) {
                            return false;
                        }

                        this.games = data.games;

                        return true;
                    })
                ),
        ]).pipe(
            map(([review, specs, goals, games]) => review && specs && goals && games),
            catchError(() => of(false)),
            finalize(() => {
                this.appService.loading = false;
            })
        );
    }
}
