import { Component, inject, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { combineLatest, filter, Subject, takeUntil } from 'rxjs';

import type { ReviewsPlayer } from '@stlc/game/shared';
import { isNil } from '@stlc/lodash';
import { UiAppService, UiHeaderService } from '@stlc/ui/services';

import { ReviewsService } from '../../services/reviews.service';

@Component({
    selector: 'stlc-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
    standalone: true,
    imports: [RouterModule],
})
export class ReviewsComponent implements OnDestroy {
    private readonly headerService = inject(UiHeaderService);
    private readonly i18next = inject(I18NEXT_SERVICE, { optional: true });
    readonly reviewsService = inject(ReviewsService);
    readonly appService = inject(UiAppService);
    readonly router = inject(Router);
    readonly route = inject(ActivatedRoute);

    private readonly destroy = new Subject<void>();

    constructor() {
        let player: ReviewsPlayer | undefined;
        let ref = this.route.snapshot;
        let reviewType: string;
        while (ref) {
            if (!player) {
                player = ref.data['player'];
            }

            if (!reviewType) {
                reviewType = ref.data['reviewType'];
            }

            ref = ref.parent;
        }

        if (!player) {
            this.appService.error = 'Review info not found';
            this.router.navigateByUrl(this.router.createUrlTree(['/']));
            return;
        }

        if (!reviewType) {
            if (player.isPitcher) {
                this.router.navigateByUrl(this.router.createUrlTree(['game-reviews', 'player']), { replaceUrl: true });
            } else if (player.isPositionPlayer) {
                this.router.navigateByUrl(this.router.createUrlTree(['game-reviews', 'player']), { replaceUrl: true });
            } else if (player.isCatcher) {
                this.router.navigateByUrl(this.router.createUrlTree(['game-reviews', 'player']), { replaceUrl: true });
            } else {
                this.appService.error = 'Game Reviews unavailable for player';
                this.router.navigateByUrl(this.router.createUrlTree(['/']));
            }
            return;
        }

        combineLatest([this.reviewsService.selectedReviewType$, this.reviewsService.reviewTypeOptionsCount$])
            .pipe(
                filter(([selectedType, _]) => !isNil(selectedType)),
                takeUntil(this.destroy)
            )
            .subscribe(([selectedType, reviewTypeOptionsCount]) => {
                const minDate = dayjs(selectedType?.gameDateMin).format('L');
                const maxDate = dayjs(selectedType?.gameDateMax).format('L');
                const date = `${minDate} ${dayjs(minDate).isBefore(maxDate) ? '-' : ''} ${
                    dayjs(minDate).isBefore(maxDate) ? maxDate : ''
                }`;

                let title = `${selectedType?.player?.lastName} - ${date}`;
                if (reviewTypeOptionsCount <= 1) {
                    title += ` - ${this.i18next.t(`common:${selectedType.type}`)}`;
                }
                this.headerService.title = title;
            });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.headerService.title = undefined;
    }
}
