import { inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { map, Observable } from 'rxjs';

import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { ReviewsPlayerGamePitchFragment, SwingDecisionEllipseFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nBasesOccupiedPipe, StlcI18nGameEventPipe } from '@stlc/i18n/core';
import {
    chain,
    compact,
    filter as _filter,
    forEach,
    head,
    isEmpty,
    isNil,
    last,
    orderBy,
    some,
    transform,
} from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { UiVideoDialogEvent } from '@stlc/shared';

import { ReviewsBattingService } from '../../services/reviews-batting.service';

export interface ReviewsAtBat {
    titles: string[];
    ellipses: SwingDecisionEllipseFragment[];
    pitches: ReviewsPlayerGamePitchFragment[];
    isVideoAvailable: boolean;
    battedBallResult: string;
}

@Injectable()
export class ReviewsBattingAtBatsService {
    reviewsBattingService = inject(ReviewsBattingService);
    i18next = inject(I18NEXT_SERVICE);

    atBats$: Observable<ReviewsAtBat[]>;
    isVideoAvailable$: Observable<boolean | undefined | null>;
    videoEvents$: Observable<UiVideoDialogEvent[]>;

    stlcI18nBasesOccupiedPipe = new StlcI18nBasesOccupiedPipe(this.i18next);
    ordinalPipe = new StlcNumberToOrdinalPipe();
    stlcI18nGameEventPipe = new StlcI18nGameEventPipe(this.i18next);

    constructor() {
        this.atBats$ = this.reviewsBattingService.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .groupBy('atBatNumber')
                    .transform((result: any[], paPitches) => {
                        const firstPitch = head(paPitches);
                        const lastPitch = last(paPitches);
                        const pitches = orderBy(paPitches, 'atbatPitchNumber');

                        let batterBats = firstPitch?.batter?.bats;
                        const pitcherThrows = firstPitch?.pitcher?.throws;
                        if (batterBats === 'B') {
                            batterBats = pitcherThrows === 'L' ? 'R' : 'L';
                        }

                        result.push({
                            titles: transform(
                                [firstPitch],
                                (result, datum) => {
                                    const paInningInfo = [];
                                    if (!isNil(firstPitch?.atBatNumber)) {
                                        paInningInfo.push(`PA #${datum.atBatNumber}`);
                                    }
                                    if (!isNil(firstPitch?.isTopOfInning) && !isNil(firstPitch?.inning)) {
                                        paInningInfo.push(
                                            `${firstPitch?.isTopOfInning ? 'Top' : 'Bot'} ${
                                                firstPitch?.inning
                                                    ? this.ordinalPipe.transform(firstPitch?.inning)
                                                    : firstPitch?.inning
                                            }`
                                        );
                                    }
                                    if (!isNil(firstPitch?.preOuts)) {
                                        paInningInfo.push(
                                            `${this.i18next.t('game:out', { count: firstPitch?.preOuts ?? 0 })}`
                                        );
                                    }

                                    if (paInningInfo.length > 0) {
                                        result.push(paInningInfo.join(', '));
                                    }

                                    if (!isNil(firstPitch?.pitcher) && !isEmpty(firstPitch?.pitcher)) {
                                        result.push(
                                            `${firstPitch?.pitcher?.firstName} ${firstPitch?.pitcher?.lastName} ${firstPitch?.pitcher?.throws}HP`
                                        );
                                    }
                                    if (!isNil(firstPitch?.basesOccupied)) {
                                        result.push(
                                            this.stlcI18nBasesOccupiedPipe.transform(firstPitch?.basesOccupied)
                                        );
                                    }
                                },
                                []
                            ),
                            ellipses: _filter(this.reviewsBattingService.swingDecisionEllipses, {
                                batterBats,
                                pitcherThrows,
                            }),
                            pitches,
                            isVideoAvailable: some(paPitches, (datum) => datum?.videos && !isEmpty(datum?.videos)),
                            battedBallResult: compact([
                                lastPitch ? this.stlcI18nGameEventPipe.transform(lastPitch) : undefined,
                                lastPitch?.launchInitialSpeed
                                    ? `<span class="whitespace-nowrap">${this.i18next.t(
                                          'reviews:launchInitialSpeed_units',
                                          {
                                              replace: { value: lastPitch?.launchInitialSpeed },
                                          }
                                      )}</span>`
                                    : undefined,
                                lastPitch?.launchVerticalAngle
                                    ? `<span class="whitespace-nowrap">${this.i18next.t(
                                          'reviews:launchVerticalAngle_units_html',
                                          {
                                              replace: { value: lastPitch?.launchVerticalAngle },
                                          }
                                      )}</span>`
                                    : undefined,
                            ]).join(', '),
                        });
                    }, [])
                    .valueOf()
            )
        );

        this.videoEvents$ = this.reviewsBattingService.games$.pipe(
            map((games) =>
                transform(games, (result: UiVideoDialogEvent[], game) =>
                    forEach(game.pitches, (datum) => {
                        const pitchType = datum?.pitchType ? getPitchTypeCode(datum.pitchType) : undefined;
                        result.push({
                            id: datum.id,
                            eventGroup: `${game?.id}:${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
                            title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
                            subtitles: [
                                datum.inning
                                    ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(
                                          datum.inning
                                      )}`
                                    : '',
                                `${datum.preBalls}-${datum.preStrikes} ${
                                    !isNil(datum?.preOuts)
                                        ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 })
                                        : ''
                                }`,
                                pitchType ? this.i18next.t(`pitchType:${pitchType}_label`) : 'Unknown',
                                datum?.pitchOutcome
                                    ? this.stlcI18nGameEventPipe.transform({
                                          isFinalPlay: datum.isFinalPlay,
                                          isNextPlay: datum.isNextPlay,
                                          pitchResult: datum?.pitchResult,
                                          eventType: datum?.eventType,
                                          battedBallType: datum?.battedBallType,
                                      })
                                    : '',
                            ],
                            pitch: {
                                ...datum,
                                pitchType,
                                description: this.stlcI18nGameEventPipe.transform(datum),
                            },
                            date: game.gameDate ? dayjs(game.gameDate).format('dddd, MMMM D, YYYY') : undefined,
                            description: this.stlcI18nGameEventPipe.transform(datum),
                            playByPlay: datum?.playByPlayDescription || '',
                            videos: datum?.videos || [],
                            types: ['pitches'],
                        });
                    })
                )
            )
        );

        this.isVideoAvailable$ = this.videoEvents$.pipe(
            map((videoEvents) => !chain(videoEvents).map('videos').reject(isEmpty).isEmpty().valueOf())
        );
    }
}
