<div
    *ngIf="reviewsBattingService.isUnverified$ | async"
    class="flex flex-col bg-warning-200 text-warning-700 md:mx-auto"
>
    <span class="text-center p-2">{{ 'common:message.unverifiedData' | i18next }}</span>
</div>
<ng-container *ngIf="menuItems.length > 1" [ngSwitch]="(appService.isHandsetPortrait$ | async) && menuItems.length > 4">
    <nav class="m-2" *ngSwitchCase="true">
        <ng-container [ngTemplateOutlet]="responsiveTabs" [ngTemplateOutletContext]="{ isMobile: true }"></ng-container>
    </nav>
    <ng-container
        *ngSwitchCase="false"
        [ngTemplateOutlet]="responsiveTabs"
        [ngTemplateOutletContext]="{ isMobile: false }"
    ></ng-container>
</ng-container>
<div class="mx-auto mt-2 max-w-screen-xl">
    <router-outlet></router-outlet>
</div>

<ng-template #responsiveTabs let-isMobile="isMobile">
    <ui-responsive-tabs
        [menuItems]="menuItems"
        [align]="'center'"
        [isMobile]="isMobile"
        [fullWidth]="true"
    ></ui-responsive-tabs>
</ng-template>
