import { RbdReviewPitchFragment, ReviewsPlayerGamePitchFragment } from '@stlc/game/reviews/data-access';
import { includes, isNil, startsWith } from '@stlc/lodash';

export const isSbAttempt = (pitch: RbdReviewPitchFragment | ReviewsPlayerGamePitchFragment) => {
    if (
        includes(
            [
                'pickoff_1b',
                'pickoff_2b',
                'pickoff_3b',
                'pickoff_caught_stealing_2b',
                'pickoff_caught_stealing_3b',
                'pickoff_caught_stealing_3b_double_play',
                'pickoff_caught_stealing_home',
                'pickoff_caught_stealing_home_double_play',
                'pickoff_error_1b',
                'pickoff_error_2b',
                'pickoff_error_3b',
                'strikeout_plus_pickoff_1b',
                'strikeout_plus_pickoff_2b',
                'strikeout_plus_pickoff_3b',
                'strikeout_plus_pickoff_caught_stealing_2b',
                'strikeout_plus_pickoff_caught_stealing_3b',
                'strikeout_plus_pickoff_caught_stealing_home',
                'strikeout_plus_pickoff_error_1b',
                'strikeout_plus_pickoff_error_2b',
                'strikeout_plus_pickoff_error_3b',
                'walk_plus_pickoff_2b',
                'walk_plus_pickoff_3b',
                'walk_plus_pickoff_caught_stealing_home',
                'walk_plus_pickoff_error_2b',
                'walk_plus_pickoff_error_3b',
            ],
            pitch.eventType
        )
    ) {
        return isNil(pitch.fielderSequence) || startsWith(pitch.fielderSequence, '2');
    }

    return includes(
        [
            'caught_stealing_2b',
            'caught_stealing_2b_double_play',
            'caught_stealing_3b',
            'caught_stealing_3b_double_play',
            'caught_stealing_home',
            'caught_stealing_home_double_play',
            'defensive_indiff',
            'double_steal',
            'stolen_base_2b',
            'stolen_base_3b',
            'stolen_base_home',
            'strikeout_plus_caught_stealing_2b',
            'strikeout_plus_caught_stealing_3b',
            'strikeout_plus_caught_stealing_home',
            'strikeout_plus_double_steal',
            'strikeout_plus_stolen_base_2b',
            'strikeout_plus_stolen_base_3b',
            'strikeout_plus_stolen_base_home',
            'walk_plus_caught_stealing_3b',
            'walk_plus_caught_stealing_home',
            'walk_plus_stolen_base_3b',
            'walk_plus_stolen_base_home',
        ],
        pitch.eventType
    );
};

export const isBasicSbAttempt = (pitch: RbdReviewPitchFragment) =>
    includes(
        [
            'caught_stealing_2b',
            'caught_stealing_2b_double_play',
            'caught_stealing_3b',
            'caught_stealing_3b_double_play',
            'caught_stealing_home',
            'caught_stealing_home_double_play',
            'defensive_indiff',
            'double_steal',
            'stolen_base_2b',
            'stolen_base_3b',
            'stolen_base_home',
            'strikeout_plus_caught_stealing_2b',
            'strikeout_plus_caught_stealing_3b',
            'strikeout_plus_caught_stealing_home',
            'strikeout_plus_double_steal',
            'strikeout_plus_stolen_base_2b',
            'strikeout_plus_stolen_base_3b',
            'strikeout_plus_stolen_base_home',
            'walk_plus_caught_stealing_3b',
            'walk_plus_caught_stealing_home',
            'walk_plus_stolen_base_3b',
            'walk_plus_stolen_base_home',
        ],
        pitch.eventType
    );

export const isBasicCaughtStealing = (pitch: RbdReviewPitchFragment | ReviewsPlayerGamePitchFragment) =>
    includes(
        [
            'caught_stealing_2b',
            'caught_stealing_2b_double_play',
            'caught_stealing_3b',
            'caught_stealing_3b_double_play',
            'caught_stealing_home',
            'caught_stealing_home_double_play',
            'strikeout_plus_caught_stealing_2b',
            'strikeout_plus_caught_stealing_3b',
            'strikeout_plus_caught_stealing_home',
            'walk_plus_caught_stealing_3b',
            'walk_plus_caught_stealing_home',
        ],
        pitch.eventType
    );

export const isBallInDirt = (pitch: RbdReviewPitchFragment) =>
    !includes(['IP', 'F', 'FT', 'HP'], pitch.pitchResult) &&
    !isNil(pitch.plateHeight) &&
    pitch.plateHeight < 0.75 &&
    !isNil(pitch.plateSide) &&
    Math.abs(pitch.plateSide) < 1.5 &&
    !isNil(pitch.basesOccupied);

export const playWithCatcher = (pitch: RbdReviewPitchFragment) =>
    includes(
        [
            'catcher_interf',
            'double',
            'double_play',
            'field_error',
            'field_out',
            'fielders_choice',
            'fielders_choice_out',
            'force_out',
            'grounded_into_double_play',
            'other_advance',
            'out_advance',
            'runner_double_play',
            'sac_bunt',
            'sac_bunt_double_play',
            'sac_fly',
            'sac_fly_double_play',
            'single',
            'strikeout_double_play',
            'strikeout_triple_play',
            'triple',
            'triple_play',
        ],
        pitch.eventType
    ) && includes(pitch.fielderSequence, '2');

export const isSb = (pitch: RbdReviewPitchFragment | ReviewsPlayerGamePitchFragment) => {
    return includes(
        [
            'defensive_indiff',
            'double_steal',
            'stolen_base_2b',
            'stolen_base_3b',
            'stolen_base_home',
            'strikeout_plus_double_steal',
            'strikeout_plus_stolen_base_2b',
            'strikeout_plus_stolen_base_3b',
            'strikeout_plus_stolen_base_home',
            'walk_plus_stolen_base_3b',
            'walk_plus_stolen_base_home',
        ],
        pitch.eventType
    );
};
