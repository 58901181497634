import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Pitch } from '@stlc/game/shared';
import { chain, find, forEach, includes, isNil, map } from '@stlc/lodash';
import { getPitchingPitchCountCategory, getStatColumn } from '@stlc/lookup/stat';
import { getPitchTypeAbbrev, getPitchTypeOrder } from '@stlc/lookup/statcast';
import { StlcStat, UiTableColumn } from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

interface PitchingReportByBatter {
    label: string;
    batterId?: string;
    batterBats?: string;
}

function columnClasses(defaultClasses?: string) {
    return (item: PitchingReportByBatter) => {
        const classes = defaultClasses ? [defaultClasses] : [];
        if (item) {
            if (item.label) {
                if (!item.batterId) {
                    if (includes(['Total'], item.label)) {
                        classes.push('bg-white font-bold');
                    } else {
                        classes.push('bg-gray-50');
                    }
                }
            }
        }
        return classes.join(' ');
    };
}

function incrementValue(datum: PitchingReportByBatter, property: string) {
    if (isNil(datum[property])) {
        datum[property] = 0;
    }
    datum[property] += 1;
}

@Component({
    selector: 'stlc-reviews-pitching-report-by-batter',
    templateUrl: './reviews-pitching-report-by-batter.component.html',
    styleUrls: ['./reviews-pitching-report-by-batter.component.scss'],
    standalone: true,
    imports: [UiTableModule],
})
export class ReviewsPitchingReportByBatterComponent implements OnChanges {
    @Input()
    pitches: Pitch[] = [];

    columns: UiTableColumn[] = [];
    displayedColumns: string[];
    readonly stickyColumnsStart = ['batter'];
    dataSource = new UiTableDataSource();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pitches'] && this.pitches) {
            const overallDatum: PitchingReportByBatter = { label: 'Total' };

            this.dataSource.data = chain(this.pitches)
                .transform((result, pitch, index) => {
                    const { pitchType, atBatNumber, preBalls, preStrikes, batter } = pitch;
                    let datum: PitchingReportByBatter = find(result, { batterId: batter.id });
                    if (isNil(datum)) {
                        datum = {
                            label: batter?.lastName ?? '?',
                            batterId: batter?.id,
                        };
                        result.push(datum);
                    }

                    const countProperty = getPitchingPitchCountCategory({ preBalls, preStrikes });

                    forEach([overallDatum, datum], (datum2) => {
                        incrementValue(datum2, StlcStat.NumberOfPitches);
                        incrementValue(datum2, pitchType);
                        if (this.pitches[index - 1]?.atBatNumber !== atBatNumber) {
                            incrementValue(datum2, 'pa');
                        }
                        if (!isNil(countProperty)) {
                            incrementValue(datum2, countProperty);
                        }
                    });
                }, [])
                .concat([overallDatum])
                .valueOf();

            this.columns = [
                {
                    id: 'batter',
                    header: 'Batter',
                    class: columnClasses('whitespace-nowrap'),
                    field: 'label',
                },
                {
                    id: 'pa',
                    header: 'PA',
                    class: columnClasses('text-center border-l'),
                },
                {
                    ...getStatColumn(StlcStat.NumberOfPitches),
                    class: columnClasses('text-center border-r'),
                },
                ...chain(this.pitches)
                    .uniqBy('pitchType')
                    // automatic ball was missing pitch type and causing an error
                    .filter((pitch) => pitch.pitchType !== null)
                    .map((datum) => ({
                        id: datum.pitchType,
                        header: getPitchTypeAbbrev(datum.pitchType),
                        class: columnClasses('text-center'),
                        format: {
                            type: 'number',
                            digits: 0,
                            defaultText: '-',
                        },
                    }))
                    .orderBy((datum) => getPitchTypeOrder(datum.id), 'asc')
                    .valueOf(),
                {
                    ...getStatColumn(StlcStat.PutAwayPitches),
                    header: 'Put Away',
                    width: 85,
                    class: columnClasses('text-center border-l'),
                },
                {
                    ...getStatColumn(StlcStat.LimitDamagePitches),
                    header: 'Limit Damage',
                    width: 85,
                    class: columnClasses('text-center border-l'),
                },
            ];

            this.displayedColumns = map(this.columns, 'id');
        }
    }
}
