import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { PlayerChartsGamesQueryParams } from '@stlc/player-charts/data-access';
import { PlayerChartsUiGamesBattingContainerComponent } from '@stlc/player-charts/ui';
import { UiAppService } from '@stlc/ui/services';
import { UserService } from '@stlc/user';

import { ReviewsBattingService } from '../../services/reviews-batting.service';

@Component({
    selector: 'stlc-reviews-batting-pitches',
    templateUrl: './reviews-batting-pitches.component.html',
    styleUrls: ['./reviews-batting-pitches.component.scss'],
    standalone: true,
    imports: [CommonModule, PlayerChartsUiGamesBattingContainerComponent],
})
export class ReviewsBattingPitchesComponent implements OnInit, OnDestroy {
    readonly reviewsBattingService = inject(ReviewsBattingService);
    readonly appService = inject(UiAppService);
    readonly route = inject(ActivatedRoute);
    readonly userService = inject(UserService);

    private readonly destroy = new Subject<void>();

    ngOnInit(): void {
        const queryParamKey = this.route.snapshot.data['userPreferenceQueryParamKey'];
        if (queryParamKey) {
            this.route.queryParams
                .pipe(
                    map((queryParams) => queryParams[PlayerChartsGamesQueryParams.View]),
                    distinctUntilChanged(),
                    takeUntil(this.destroy)
                )
                .subscribe((view) => {
                    this.userService.updatePreference(queryParamKey, { view });
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }
}
