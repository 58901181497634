import { Pipe, PipeTransform } from '@angular/core';

import { isNumber, isPlainObject } from '@stlc/lodash';
import { calculateStat } from '@stlc/lookup/stat';
import { StlcStat, StlcStatPipeData } from '@stlc/shared';

@Pipe({
    name: 'stlcStat',
    standalone: true,
})
export class StlcStatPipe implements PipeTransform {
    transform(value: number | Partial<StlcStatPipeData>, stat: StlcStat | string) {
        if (isNumber(value)) {
            return value;
        }

        if (isPlainObject(value)) {
            return calculateStat(value, stat as StlcStat);
        }

        return undefined;
    }
}
