import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { finalize, map } from 'rxjs/operators';

import { ReviewsGetPitchingReviewNewsFeedGQL, StlcTimeFrame } from '@stlc/game/reviews/data-access';
import { ReviewsPitchingGameStatsComponent } from '@stlc/reviews/ui';

@Component({
    selector: 'stlc-reviews-news-feed-pitching-stats',
    templateUrl: './news-feed-pitching-stats.component.html',
    styleUrls: ['./news-feed-pitching-stats.component.scss'],
    standalone: true,
    imports: [CommonModule, ReviewsPitchingGameStatsComponent],
})
export class ReviewsNewsFeedPitchingStatsComponent implements OnChanges {
    private pitchGameReviewGQL = inject(ReviewsGetPitchingReviewNewsFeedGQL);
    loading = false;

    summaryStats = signal<any[]>([]);

    @Input()
    playerId: string;

    @Input()
    date: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['playerId'] || changes['date']) {
            if (this.playerId && this.date) {
                this.loading = true;
                this.summaryStats.set([{}]);
                const where = {
                    pitchers: [this.playerId],
                    timeFrame: {
                        // couldn't figure out which import to get this to work without as clause, tried them all
                        type: StlcTimeFrame.DateRange,
                        startDate: this.date,
                        endDate: this.date,
                    },
                };
                this.pitchGameReviewGQL
                    .fetch({ where })
                    .pipe(
                        map(({ data }) => data.pitchAggregate),
                        finalize(() => (this.loading = false))
                    )
                    .subscribe((data) => this.summaryStats.set(data));
            }
        }
    }
}
