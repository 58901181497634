import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { PlayerChartsUiGamesPitchingContainerComponent } from '@stlc/player-charts/ui';
import { UiAppService } from '@stlc/ui/services';

import { ReviewsPitchingService } from '../../services/reviews-pitching.service';

@Component({
    selector: 'stlc-reviews-pitching-pitches',
    templateUrl: './reviews-pitching-pitches.component.html',
    styleUrls: ['./reviews-pitching-pitches.component.scss'],
    standalone: true,
    imports: [CommonModule, PlayerChartsUiGamesPitchingContainerComponent],
})
export class ReviewsPitchingPitchesComponent {
    readonly reviewsPitchingService = inject(ReviewsPitchingService);
    readonly appService = inject(UiAppService);
}
