import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { StlcDegreeToClockFacePipe } from '@stlc/angular/pipes/degree-to-clock-face';
import { StlcFeetAndInchesPipe } from '@stlc/angular/pipes/feet-and-inches';
import { StlcNumberToFixedPipe } from '@stlc/angular/pipes/number-to-fixed';
import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { StlcPlayerNamePipe } from '@stlc/angular/pipes/player-name';
import { RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';

@Component({
    selector: 'stlc-reviews-pitching-tooltip',
    templateUrl: './reviews-pitching-tooltip.component.html',
    styleUrls: ['./reviews-pitching-tooltip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        StlcDegreeToClockFacePipe,
        StlcFeetAndInchesPipe,
        StlcI18nModule,
        StlcNumberToFixedPipe,
        StlcNumberToOrdinalPipe,
        StlcPlayerNamePipe,
    ],
})
export class ReviewsPitchingTooltipComponent {
    @Input()
    pitch: RbdReviewPitchFragment;

    @Input()
    showPitcher = false;
}
