import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Pitch } from '@stlc/game/shared';
import { chain, find, isNil, map } from '@stlc/lodash';
import { getPitchCountsForPitching, getPitchCountStat, getStatColumn } from '@stlc/lookup/stat';
import { getPitchTypeOrder } from '@stlc/lookup/statcast';
import { StlcStat, UiTableColumn, UiTableDisplayedColumn } from '@stlc/shared';
import { UiTableDataSource, UiTableModule } from '@stlc/ui/table';

function incrementValue(datum: any, property: string) {
    if (isNil(datum[property])) {
        datum[property] = 0;
    }
    datum[property] += 1;
}

const getAheadColumns = getPitchCountsForPitching(StlcStat.GetAheadPitches);
const putAwayColumns = getPitchCountsForPitching(StlcStat.PutAwayPitches);
const limitDamageColumns = getPitchCountsForPitching(StlcStat.LimitDamagePitches);

@Component({
    selector: 'stlc-reviews-pitching-report-by-count',
    templateUrl: './reviews-pitching-report-by-count.component.html',
    styleUrls: ['./reviews-pitching-report-by-count.component.scss'],
    standalone: true,
    imports: [UiTableModule],
})
export class ReviewsPitchingReportByCountComponent implements OnChanges {
    @Input()
    pitches: Pitch[] = [];

    readonly columns: UiTableColumn[];
    readonly displayedColumns: UiTableDisplayedColumn[];
    readonly stickyColumnsStart = [StlcStat.PitchAbbrev];
    dataSource = new UiTableDataSource();

    constructor() {
        this.columns = [
            getStatColumn(StlcStat.PitchAbbrev),
            {
                ...getStatColumn(StlcStat.NumberOfPitches),
                class: 'text-center',
                header: 'All',
            },
            ...map(getAheadColumns, (datum) => ({
                ...getStatColumn(datum),
                class: 'text-center whitespace-nowrap',
            })),
            ...map(putAwayColumns, (datum) => ({
                ...getStatColumn(datum),
                class: 'text-center whitespace-nowrap',
            })),
            ...map(limitDamageColumns, (datum) => ({
                ...getStatColumn(datum),
                class: 'text-center whitespace-nowrap',
            })),
            {
                ...getStatColumn(StlcStat.ThreeTwo),
                class: 'text-center whitespace-nowrap',
            },
        ];

        this.displayedColumns = [
            StlcStat.PitchAbbrev,
            StlcStat.NumberOfPitches,
            {
                id: 'getAheadGroup',
                header: 'Get Ahead',
                borderless: true,
                class: 'text-center whitespace-nowrap',
                displayedColumns: getAheadColumns,
            },
            {
                id: 'putAwayGroup',
                header: 'Put Away',
                borderless: true,
                class: 'text-center whitespace-nowrap',
                displayedColumns: putAwayColumns,
            },
            {
                id: 'limitDamageGroup',
                header: 'Limit Damage',
                borderless: true,
                class: 'text-center whitespace-nowrap',
                displayedColumns: limitDamageColumns,
            },
            StlcStat.ThreeTwo,
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pitches'] && this.pitches) {
            this.dataSource.data = chain(this.pitches)
                .transform((result, pitch) => {
                    if (!isNil(pitch.pitchType)) {
                        const { pitchType, preBalls, preStrikes } = pitch;
                        let datum = find(result, { pitchType });
                        if (isNil(datum)) {
                            datum = {
                                pitchType,
                                order: getPitchTypeOrder(pitchType),
                            };
                            result.push(datum);
                        }
                        incrementValue(datum, 'numberOfPitches');

                        const countProperty = getPitchCountStat({ preBalls, preStrikes });
                        if (!isNil(countProperty)) {
                            incrementValue(datum, countProperty);
                        }
                    }
                }, [])
                .orderBy('order', 'asc')
                .valueOf();
        }
    }
}
