import { inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { map, Observable } from 'rxjs';

import { StlcNumberToOrdinalPipe } from '@stlc/angular/pipes/number-to-ordinal';
import { RbdReviewPitchFragment, ReviewsPlayerGamePitchFragment } from '@stlc/game/reviews/data-access';
import { StlcI18nBasesOccupiedPipe, StlcI18nGameEventPipe } from '@stlc/i18n/core';
import {
    chain,
    compact,
    forEach,
    groupBy,
    head,
    isEmpty,
    isNil,
    last,
    map as _map,
    some,
    transform,
} from '@stlc/lodash';
import { getPitchTypeCode } from '@stlc/lookup/legacy';
import { UiVideoDialogEvent } from '@stlc/shared';
import { UiTableDataSource } from '@stlc/ui/table';

import { ReviewsPitchingService } from '../../services/reviews-pitching.service';

export interface ReviewsAtBat {
    titles: string[];
    pitches: ReviewsPlayerGamePitchFragment[];
    isVideoAvailable: boolean;
    battedBallResult: string;
}

export interface ReviewsInning {
    inning: number;
    plateAppearances: ReviewsAtBat[];
}

const pitchProperties: Array<keyof RbdReviewPitchFragment> = [
    'baseSituation',
    'battedBallOutcome',
    'battedBallType',
    'batter',
    'battingTeamId',
    'description',
    'eventType',
    'game',
    'inning',
    'inningPa',
    'isFinalPlay',
    'isNextPlay',
    'isTopOfInning',
    'launchVerticalAngle',
    'launchInitialSpeed',
    'pitcher',
    'pitcherId',
    'pitchingTeamId',
    'pitchResult',
    'playByPlayDescription',
    'postAwayTeamScore',
    'postHomeTeamScore',
    'postOuts',
    'preAwayTeamScore',
    'preBalls',
    'preHomeTeamScore',
    'preOuts',
    'preOuts',
    'preStrikes',
];

@Injectable()
export class ReviewsPitchingAtBatsService {
    private readonly reviewsPitchingService = inject(ReviewsPitchingService);
    private readonly i18next = inject(I18NEXT_SERVICE);

    readonly videoEvents$: Observable<UiVideoDialogEvent[]>;
    readonly isVideoAvailable$: Observable<boolean>;
    readonly innings$: Observable<ReviewsInning[]>;

    readonly stlcI18nBasesOccupiedPipe = new StlcI18nBasesOccupiedPipe(this.i18next);
    readonly ordinalPipe = new StlcNumberToOrdinalPipe();
    readonly stlcI18nGameEventPipe = new StlcI18nGameEventPipe(this.i18next);

    constructor() {
        this.innings$ = this.reviewsPitchingService.pitches$.pipe(
            map((pitches) =>
                chain(pitches)
                    .groupBy('inning')
                    .transform((result: ReviewsInning[], values, inning) => {
                        const inningPas = groupBy(values, 'inningPa');
                        result.push({
                            inning: +inning,
                            plateAppearances: _map(inningPas, (values): ReviewsAtBat => {
                                const dataSource = new UiTableDataSource<ReviewsPlayerGamePitchFragment>();
                                dataSource.data = values;
                                const lastPitch = last(values);
                                const firstPitch = head(values);
                                const pitches = chain(values).orderBy('pitchOfPa').valueOf();
                                return {
                                    ...chain(values).last().pick(pitchProperties).valueOf(),
                                    pitches,
                                    battedBallResult: compact([
                                        lastPitch ? this.stlcI18nGameEventPipe.transform(lastPitch) : undefined,
                                        lastPitch?.launchInitialSpeed
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchInitialSpeed_units',
                                                  {
                                                      replace: { value: lastPitch?.launchInitialSpeed },
                                                  }
                                              )}</span>`
                                            : undefined,
                                        lastPitch?.launchVerticalAngle
                                            ? `<span class="whitespace-nowrap">${this.i18next.t(
                                                  'reviews:launchVerticalAngle_units_html',
                                                  {
                                                      replace: { value: lastPitch?.launchVerticalAngle },
                                                  }
                                              )}</span>`
                                            : undefined,
                                    ]).join(', '),
                                    isVideoAvailable: some(
                                        pitches,
                                        (datum) => datum?.videos && !isEmpty(datum?.videos)
                                    ),
                                    titles: transform(
                                        [firstPitch],
                                        (result, datum: ReviewsPlayerGamePitchFragment) => {
                                            const paInningInfo = [];
                                            if (!isNil(firstPitch?.atBatNumber)) {
                                                paInningInfo.push(`PA #${datum.atBatNumber}`);
                                            }
                                            if (!isNil(firstPitch?.preOuts)) {
                                                paInningInfo.push(
                                                    `${this.i18next.t('game:out', {
                                                        count: firstPitch?.preOuts ?? 0,
                                                    })}`
                                                );
                                            }

                                            if (paInningInfo.length > 0) {
                                                result.push(paInningInfo.join(', '));
                                            }

                                            if (!isNil(firstPitch?.batter) && !isEmpty(firstPitch?.batter)) {
                                                result.push(
                                                    `${firstPitch?.batter?.firstName} ${firstPitch?.batter?.lastName} ${firstPitch?.batter?.bats}HB`
                                                );
                                            }
                                            if (!isNil(firstPitch?.basesOccupied)) {
                                                result.push(
                                                    this.stlcI18nBasesOccupiedPipe.transform(firstPitch?.basesOccupied)
                                                );
                                            }
                                        },
                                        []
                                    ),
                                };
                            }) as any[],
                        });
                    }, [])
                    .orderBy('inning')
                    .valueOf()
            )
        );

        this.videoEvents$ = this.reviewsPitchingService.games$.pipe(
            map((games) =>
                transform(games, (result: UiVideoDialogEvent[], game) =>
                    forEach(game.pitches, (datum) => {
                        const pitchType = datum?.pitchType ? getPitchTypeCode(datum.pitchType) : undefined;
                        result.push({
                            id: datum.id,
                            eventGroup: `${game?.id}:${datum.inning}:${datum.isTopOfInning}:${datum.inningPa}`,
                            title: `${datum.batter?.firstName} ${datum.batter?.lastName} vs ${datum.pitcher?.firstName} ${datum.pitcher?.lastName}`,
                            subtitles: [
                                datum.inning
                                    ? `${datum.isTopOfInning ? 'Top' : 'Bot'} ${this.ordinalPipe.transform(
                                          datum.inning
                                      )}`
                                    : '',
                                `${datum.preBalls}-${datum.preStrikes} ${
                                    !isNil(datum?.preOuts)
                                        ? this.i18next.t('game:out', { count: datum?.preOuts ?? 0 })
                                        : ''
                                }`,
                                pitchType ? this.i18next.t(`pitchType:${pitchType}_label`) : 'Unknown',
                                datum?.pitchOutcome
                                    ? this.stlcI18nGameEventPipe.transform({
                                          isFinalPlay: datum.isFinalPlay,
                                          isNextPlay: datum.isNextPlay,
                                          pitchResult: datum?.pitchResult,
                                          eventType: datum?.eventType,
                                          battedBallType: datum?.battedBallType,
                                      })
                                    : '',
                            ],
                            pitch: {
                                ...datum,
                                pitchType,
                                description: this.stlcI18nGameEventPipe.transform(datum),
                            },
                            date: game.gameDate ? dayjs(game.gameDate).format('dddd, MMMM D, YYYY') : undefined,
                            description: this.stlcI18nGameEventPipe.transform(datum),
                            playByPlay: datum?.playByPlayDescription || '',
                            videos: datum?.videos || [],
                            types: ['pitches'],
                        });
                    })
                )
            )
        );

        this.isVideoAvailable$ = this.videoEvents$.pipe(
            map((videoEvents) => !chain(videoEvents).map('videos').reject(isEmpty).isEmpty().valueOf())
        );
    }
}
