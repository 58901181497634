<div class="flex flex-col space-y-6 px-2 mb-8 w-screen md:mb-16 md:w-full lg:mx-auto lg:max-w-screen-xl items-center">
    <div class="w-full">
        <mat-card>
            <mat-card-content>
                <stlc-reviews-pitching-game-stats
                    [summaryStats]="service.pitchAggregateExtended()"
                ></stlc-reviews-pitching-game-stats>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="flex flex-row gap-4 overflow-x-auto w-full py-1 snap-x">
        <mat-card class="ml-auto">
            <mat-card-content>
                <player-charts-ui-batter-bats-strike-zones header="All Counts" [pitches]="service.pitches()" />
            </mat-card-content>
        </mat-card>
        <mat-card class="mr-auto">
            <mat-card-content>
                <player-charts-ui-batter-bats-strike-zones
                    header="Put Away (0-2, 1-2, 2-2)"
                    [pitches]="service.putAwayPitches()"
                    [rhbCaption]="rhbCaption"
                    [lhbCaption]="lhbCaption"
                />
            </mat-card-content>
        </mat-card>
    </div>

    <mat-card class="w-full">
        <mat-card-content>
            <stlc-reviews-pitching-report-summary [boxscore]="service.pitchAggregateExtended()" />
            <div class="flex space-x-0 space-y-4 flex-row flex-wrap gap-2 justify-center">
                <div class="w-full max-w-lg">
                    <stlc-reviews-pitching-report-by-count [pitches]="service.pitches()" />
                </div>
                <div class="w-full max-w-lg">
                    <stlc-reviews-pitching-report-by-batter [pitches]="service.pitches()" />
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="w-full">
        <mat-card-content>
            <stlc-reviews-pitching-report-by-pitch-summary
                [pitches]="service.pitches()"
                [gamePitchMovement]="service.gamePitchMovementData$ | async"
            />
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <stlc-reviews-pitching-report-pitch-movement
                [pitches]="service.pitches()"
                [avgData]="service.pitchMovementAvgData$ | async"
                [lgData]="service.lgPitchMovementEllipses$ | async"
                [contextPitches]="service.contextPitches$ | async"
                [armAngleKey]="service.armAngleKey"
                [pitcherThrows]="service.playerThrows"
            />
        </mat-card-content>
    </mat-card>

    <ng-container *ngIf="service.rebootReports$ | async as rebootReports">
        {{ '' // For now assume there is no more than one report per day }}
        <div *ngIf="rebootReports.length > 0 && rebootReports[0] as rebootReport" class="flex justify-center">
            <a
                [href]="rebootReport.reportUrl"
                target="_blank"
                mat-button
                class="btn-sm"
                color="gray"
                (click)="service.loadRebootReport(rebootReport.id)"
            >
                <mat-icon>open_in_new</mat-icon>
                {{ 'reviews:rebootPitchingReport_label' | i18next : { defaultValue: 'Reboot Pitching Report' } }}
            </a>
        </div>
    </ng-container>
</div>

<ng-template #rhbCaption>
    <div *ngIf="service.putAwayMetrics()?.rhb as datum" class="text-gray-400 text-center">
        {{ datum.inZoneRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} IZ,
        {{ datum.outOfZoneSwingRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} OOZ-Sw,
        {{ datum.swingAndMissRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} Sw-M
    </div>
</ng-template>

<ng-template #lhbCaption>
    <div *ngIf="service.putAwayMetrics()?.lhb as datum" class="text-gray-400 text-center">
        {{ datum.inZoneRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} IZ,
        {{ datum.outOfZoneSwingRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} OOZ-Sw,
        {{ datum.swingAndMissRate | stlcNumberToFixed : { digits: 0, appendText: '%', defaultText: '-' } }} Sw-M
    </div>
</ng-template>
