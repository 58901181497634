import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { Params } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { PlayerStatsHandedness, RbdReviewPitchFragment } from '@stlc/game/reviews/data-access';
import {
    chain,
    every,
    filter as _filter,
    filter,
    find,
    get,
    head,
    includes,
    isArray,
    isEmpty,
    isNil,
    map as _map,
    some,
    sum,
    transform,
} from '@stlc/lodash';
import { getPitchTypeAbbrev, getPitchTypeDescription, getPitchTypeOrder } from '@stlc/lookup/legacy';
import { StlcSelectOption, StlcSelectOptionGroup } from '@stlc/shared';
import { UiDialogService } from '@stlc/ui/dialog';
import { UiTableDataSource } from '@stlc/ui/table';
import { UiVegaBrushingService } from '@stlc/ui/vega';
import { UiVideoDialogComponent } from '@stlc/ui/video';

import { ReviewsService } from './reviews.service';
import { ReviewsCatchingService } from './reviews-catching.service';

export type StrikeZoneChartOption = 'pitches' | 'heatmap';

export interface ReviewsCatchingFilter<T, C extends StlcSelectOption<T> = StlcSelectOption<T>>
    extends StlcSelectOptionGroup<T, C> {
    key: string;
    type: 'list' | 'select';
    chipStyle?: 'none' | 'checkbox' | 'stroked';
    chipColor?: string;
}

interface PitchSummary {
    strikesAdded: number;
    pitchType?: string;
    numberOfPitches: number;
    isFooter?: boolean;
}

@Injectable()
export class ReviewsCatchingFramingService {
    // will hold all called strikes and balls thrown from the game
    readonly pitches$: Observable<RbdReviewPitchFragment[]>;

    // will hold all pitches from pitches$ that pass the filters created below
    // #filteredPitches: RbdReviewPitchFragment[] = [];
    readonly filteredPitches$: Observable<RbdReviewPitchFragment[]>;

    // holds all of the pitches that will be shown in the table and are visible in the chart
    // #visiblePitches: RbdReviewPitchFragment[] = [];
    readonly visiblePitches$: Observable<RbdReviewPitchFragment[]>;

    // will hold the filters for all of the pitches in this section
    private filtersSubject = new BehaviorSubject<ReviewsCatchingFilter<string>[]>([]);
    readonly filters$: Observable<ReviewsCatchingFilter<string>[]>;
    set filters(next: ReviewsCatchingFilter<string>[]) {
        this.filtersSubject.next(next);
    }
    get filters() {
        return this.filtersSubject.getValue();
    }

    // will hold the query params from the route
    private queryParamsSubject = new BehaviorSubject<Params>({});
    readonly queryParams$: Observable<Params>;
    set queryParams(next: Params) {
        this.queryParamsSubject.next(next);
    }

    // holds the object which contains the number of pitches showing and the total available
    readonly showingCounts$: Observable<{ value: number; total: number }>;

    // holds how many pitches are filtered out
    readonly filterCount$: Observable<number>;

    // holds a boolean to indicate whether there is video available for the pitches
    readonly isVideoAvailable$: Observable<boolean>;

    // holds the data source for the summary table showing strikes added for specific pitch types
    private summaryTableDataSource = new UiTableDataSource<PitchSummary>();
    readonly summaryTableDataSource$: Observable<UiTableDataSource<PitchSummary>>;

    private reviewsService = inject(ReviewsService);
    private reviewsCatchingService = inject(ReviewsCatchingService);
    private videoDialog = inject(UiDialogService);
    private vegaBrushingService = inject(UiVegaBrushingService);
    private i18next = inject(I18NEXT_SERVICE);
    private locale = inject(LOCALE_ID);

    constructor() {
        this.filters$ = this.filtersSubject.asObservable();
        this.queryParams$ = this.queryParamsSubject.asObservable();

        // filter all of the pitches from the game to only include called strieks and balls
        this.pitches$ = this.reviewsCatchingService.pitches$.pipe(
            map((pitches) =>
                _filter(
                    pitches,
                    ({ pitchResult, pitchType, calledStrikeProbability }) =>
                        includes(['SC', 'B'], pitchResult) && !isNil(pitchType) && !isNil(calledStrikeProbability)
                )
            ),
            shareReplay(1)
        );

        // listen for changes to the pitches to generate options for the filters
        combineLatest([this.pitches$, this.queryParams$]).subscribe(([pitches, queryParams]) => {
            this.filters = [
                {
                    id: 'pitchType',
                    key: 'pitchType',
                    header: this.i18next.t('filters:pitchType_header', 'Pitch Type'),
                    options: chain(pitches)
                        .uniqBy('pitchType')
                        .map('pitchType')
                        .compact()
                        .orderBy((datum) => getPitchTypeOrder(datum))
                        .map((datum) => ({
                            value: datum,
                            text: getPitchTypeDescription(datum) ?? 'Unknown',
                            textKey: getPitchTypeAbbrev(datum)
                                ? `pitchType:${getPitchTypeAbbrev(datum)}_label`
                                : undefined,
                            abbreviation: getPitchTypeAbbrev(datum),
                            selected:
                                queryParams['pitchType'] &&
                                includes(
                                    isArray(queryParams['pitchType'])
                                        ? queryParams['pitchType']
                                        : [queryParams['pitchType']],
                                    datum
                                ),
                        }))
                        .valueOf(),
                    multiple: true,
                    type: 'list',
                    chipStyle: 'none',
                },
                {
                    id: 'batterHandedness',
                    key: 'batterBats',
                    header: this.i18next.t('filters:batterHandedness_header', 'Batter Handedness'),
                    options: chain(pitches)
                        .uniqBy('batterBats')
                        .map('batterBats')
                        .filter((datum) => includes([PlayerStatsHandedness.Right, PlayerStatsHandedness.Left], datum))
                        .compact()
                        .orderBy((datum) => (datum === PlayerStatsHandedness.Right ? 1 : 0))
                        .map((datum) => ({
                            value: datum,
                            textKey:
                                datum === PlayerStatsHandedness.Right
                                    ? 'filters:batterHandednessRight_label'
                                    : 'filters:batterHandednessLeft_label',
                            text: datum === PlayerStatsHandedness.Right ? 'vs. RHB' : 'vs. LHB',
                            selected:
                                queryParams['batterHandedness'] &&
                                includes(
                                    isArray(queryParams['batterHandedness'])
                                        ? queryParams['batterHandedness']
                                        : [queryParams['batterHandedness']],
                                    datum
                                ),
                        }))
                        .valueOf(),
                    multiple: false,
                    type: 'list',
                    chipStyle: 'none',
                },
                {
                    id: 'pitchResult',
                    key: 'pitchResult',
                    header: this.i18next.t('filters:pitchResult_header', 'Pitch Result'),
                    options: chain(pitches)
                        .uniqBy('pitchResult')
                        .map('pitchResult')
                        .filter((datum) => includes(['B', 'SC'], datum))
                        .compact()
                        .orderBy((datum) => (datum === 'B' ? 1 : 0))
                        .map((datum) => ({
                            value: datum,
                            textKey: datum === 'B' ? 'filters:ball_label' : 'filters:strike_label',
                            text: datum === 'B' ? 'Ball' : 'Strike',
                            selected:
                                queryParams['pitchResult'] &&
                                includes(
                                    isArray(queryParams['pitchResult'])
                                        ? queryParams['pitchResult']
                                        : [queryParams['pitchResult']],
                                    datum
                                ),
                        }))
                        .valueOf(),
                    multiple: false,
                    type: 'list',
                    chipStyle: 'none',
                },
                {
                    id: 'pitcher',
                    key: 'pitcher.id',
                    header: this.i18next.t('filters:pitcher_header', 'Pitcher'),
                    options: chain(pitches)
                        .orderBy((datum) => datum.pitchNumber)
                        .uniqBy('pitcher.id')
                        .map('pitcher')
                        .compact()
                        .map((datum) => ({
                            value: `${datum.id}`,
                            text: `${datum.firstName} ${datum.lastName}` + (datum.throws === 'L' ? ' (L)' : ''),
                            selected:
                                queryParams?.['pitcher'] &&
                                includes(
                                    isArray(queryParams['pitcher']) ? queryParams['pitcher'] : [queryParams['pitcher']],
                                    `${datum.id}`
                                ),
                        }))
                        .valueOf(),
                    multiple: true,
                    type: 'select',
                    chipStyle: 'none',
                    chipColor: 'primary',
                },
            ];
        });

        this.filteredPitches$ = combineLatest([this.pitches$, this.filters$]).pipe(
            map(([pitches, filters]) => {
                const filterValues = transform(
                    filters,
                    (result, { key, options }) => {
                        const values = chain(options)
                            .filter(({ selected }) => selected)
                            .map('value')
                            .valueOf();
                        if (!isEmpty(values)) {
                            result.push({ key, values });
                        }
                    },
                    []
                );

                return _filter(pitches, (datum) =>
                    every(filterValues, ({ key, values }) => includes(values, get(datum, key)))
                );
            }),
            shareReplay(1)
        );

        this.visiblePitches$ = combineLatest([this.filteredPitches$, this.vegaBrushingService.selectedIds$]).pipe(
            map(([pitches, ids]) => (isEmpty(ids) ? pitches : filter(pitches, ({ id }) => ids.includes(id)))),
            shareReplay(1)
        );

        this.summaryTableDataSource$ = this.visiblePitches$.pipe(
            map((visiblePitches) => {
                const data = chain(visiblePitches)
                    .groupBy('pitchType')
                    .map(
                        (datum): PitchSummary => ({
                            strikesAdded: sum(_map(datum, 'strikesAdded')),
                            pitchType: head(datum).pitchType,
                            numberOfPitches: datum.length,
                            isFooter: false,
                        })
                    )
                    .orderBy((datum) => getPitchTypeOrder(datum.pitchType))
                    // .map((datum): PitchSummary => ({ ...datum, pitchType: getPitchTypeDescription(datum.pitchType) }))
                    .valueOf();

                data.push({
                    numberOfPitches: chain(visiblePitches).map('numberOfPitches').sum().value(),
                    strikesAdded: chain(visiblePitches).map('strikesAdded').sum().value(),
                    isFooter: true,
                });

                this.summaryTableDataSource.data = data;
                return this.summaryTableDataSource;
            })
        );

        this.showingCounts$ = combineLatest([this.visiblePitches$, this.pitches$]).pipe(
            map(([visiblePitches, pitches]) => ({ value: visiblePitches.length, total: pitches.length }))
        );

        this.filterCount$ = this.showingCounts$.pipe(map(({ value, total }) => total - value));

        this.isVideoAvailable$ = this.pitches$.pipe(
            map((pitches) => some(pitches, (pitch) => pitch.videos.length > 0))
        );
    }

    openVideoDialog(pitch?: RbdReviewPitchFragment) {
        this.visiblePitches$.pipe(take(1)).subscribe((pitches) => {
            const videoEvents = this.reviewsService.getVideoEvents(pitches);
            pitch = (pitch ? find(pitches, (datum) => datum.id === pitch.id) : undefined) ?? head(pitches);
            // this.openVideoDialog(videoEvents, pitch);

            this.videoDialog.open(UiVideoDialogComponent, {
                data: {
                    charts: [
                        {
                            name: 'Location',
                            spec: this.reviewsCatchingService.videoPitchLocationSpec,
                        },
                    ],
                    date: dayjs(pitch?.game?.gameDate).locale(this.locale).format('LL'),
                    selectedEvent: pitch?.id,
                    eventGroups: chain(videoEvents)
                        .groupBy('eventGroup')
                        .map((value) => value)
                        .valueOf(),
                    previousEventGroupLabel: `${this.i18next.t('common:previous')} PA`,
                    nextEventGroupLabel: `${this.i18next.t('common:next')} PA`,
                    eventTypeOptions: [{ label: 'All', value: 'pitches' }],
                    selectedEventType: 'pitches',
                },
                panelClass: 'stlc-video-dialog',
            });
        });
    }
}
